@import 'app/assets/scss/_variables.scss';
@import 'app/assets/scss/_mixins.scss';

.scene-sidebar-content {
  width: 22.5rem;
  height: 100%;
  background: #ffffff;
  backdrop-filter: blur(0.375rem);

  @include extraLd {
    width: 45rem;
  }

  .sidebar {
    color: $brandMidBlue;
    padding-top: 8.938rem; // $mainNavHeight;
    overflow: hidden;
    z-index: 1;
  
    @include extraLd {
      padding-top: 8.938rem * 2; // $mainNavHeight * 2;
    }
    $block: &;
  
    &__info {
      padding: 0 1.75rem;
  
      @include extraLd {
        padding: 0 3.5rem;
      }
      .title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 1.25rem 0;
  
        @include extraLd {
          margin: 2.5rem 0;
        }
        h2 {
          font-size: 1.75rem;
          font-weight: 900;
          line-height: 1;
          text-transform: uppercase;
          margin: 0;
  
          @include extraLd {
            font-size: 3.5rem;
          }
        }
  
        #{$block}__navigate {
          display: flex;
          gap: 1.125rem;
  
          @include extraLd {
            gap: 2.25rem;
          }
  
          svg {
            cursor: pointer;
            pointer-events: all !important;
  
            @include extraLd {
              width: 4rem;
              height: 2rem;
            }
          }
        }
      }
  
      .description {
        font-size: 0.9375rem;
        line-height: 1.5;
        border-bottom: 0.0625rem solid #c4c4c4;
        padding-bottom: 1.25rem;
  
        @include extraLd {
          font-size: 1.875rem;
          border-bottom: 0.125rem solid #c4c4c4;
          padding-bottom: 2.5rem;
        }
      }
  
      .bottom-button {
        margin-top: 1.25rem;
        padding: 1.25rem 0.825rem;
  
        @include extraLd {
          margin-top: 2.5rem;
        }
        .c-button {
          background-color: $brandMidBlue;
          width: 100%;
        }
  
        .c-button__corner {
          border-color: $brandMidBlue;
        }
  
        .c-button__text {
          font-size: 0.75rem;
          line-height: 1.5;
          font-weight: 700;
          color: white;
          padding: 1rem;
  
          @include extraLd {
            font-size: 1.5rem;
            padding: 2rem;
          }
        }
      }
    }
  }
}
