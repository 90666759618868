@import 'app/assets/scss/_variables.scss';
@import 'app/assets/scss/_mixins.scss';

.c-power-card {

    position: fixed;
    left: 0;
    top: 7.1875rem;
    width: calc(var(--base-sidebar-width) + var(--base-menu-width));
    min-width: calc(var(--base-sidebar-width) + var(--base-menu-min-width));
    max-width: calc(var(--base-sidebar-width) + var(--base-menu-max-width));
    transition: all 0.2s;

    @include md {
        top: 7.75rem;
    }

    @include ld {
        top: 9.9375rem;
    }

    @include extraLd {
        top: 20rem;
        left: 0;
        // width: calc(var(--base-sidebar-width) + var(--base-menu-width));
        // min-width: calc(var(--base-sidebar-width) + var(--base-menu-min-width));
        // max-width: calc(var(--base-sidebar-width) + var(--base-menu-max-width));
        // width: 724px;
        // max-width: 724px;
    }


    &.is-expanded {
        left: 0;
        width: min-content;

        @include extraLd {
            left: 0;
        }

    }


    &__title {
        --power-card-cut-size: 1.75rem;
        font-size: 1.0625rem;
        line-height: 1;
        color: $newBrandBlue;
        font-weight: 700;
        text-transform: uppercase;
        display: flex;
        // padding: 1.375rem;
        padding: 1.0494rem 1.0744rem;
        justify-content: space-between;
        align-items: center;
        clip-path: polygon(0 0, 0 0, 0 0, 100% 0, 100% calc(100% - var(--power-card-cut-size)), calc(100% - var(--power-card-cut-size)) 100%, 0 100%, 0 0);
        background: rgba($brandWhite, 0.82);
        // transition: all 0.2s;
        // transition-delay: 0.2s;

        @include md {
            font-size: 1.25rem;
            line-height: 1;
            padding: 1.1119rem 1.5938rem 1.1119rem 1.2188rem;
        }
    
        @include ld {
            --power-card-cut-size: 3.125rem;
            font-size: 1.75rem;
            line-height: 1;
            padding: 1.9244rem 2.9063rem 1.9244rem 2.0313rem;
        }

        @include extraLd {
            --power-card-cut-size: 6.25rem;
            font-size: 3.5rem;
            padding: 4.0494rem 4.2188rem 4.0494rem 3.8438rem;
        }


        &.is-expanded {
            --power-card-cut-size: 0;
            // clip-path: initial;
        }

    }

    &__expand-toggle {
        display: block;
        background: none;
        padding: 0;
        color: inherit;
        text-align: inherit;
        text-shadow: none;
        border: none;
        outline: none;
        cursor: pointer;
        z-index: 1;
        width: 1.1875rem;
        height: 1.1875rem;

        @include md {
            width: 1.5rem;
            height: 1.5rem;
        }
    
        @include ld {
            width: 2.3125rem;
            height: 2.3125rem;
        }
        

        @include extraLd {
            width: 4.375rem;
            height: 4.375rem;
        }

        svg {
            width: 100%;
            height: 100%;

        }

    }

    &__content {
        clip-path: polygon(0 0, 0 0, 0 0, 100% 0, 100% calc(100% - 2rem), calc(100% - 2rem) 100%, 0 100%, 0 0);
        height: 0;
        margin-left: 3rem;
        margin-left: calc(var(--base-sidebar-width) + 2rem);
        position: relative;
        background: rgba($brandWhite, 0.82);
        transition: all 0.2s;
        padding: 0;
        font-size: 0.875rem;
        line-height: 1.5;
        color: $newBrandBlue;

        @include md {
            font-size: 1rem;
            // margin-left: 3.625rem;
            margin-left: calc(var(--base-sidebar-width) + 1rem);
        }

        @include ld {
            font-size: 1rem;
        }

        @include extraLd {
            // margin-left: 6.25rem;
            margin-left: calc(var(--base-sidebar-width) + 2rem);
            font-size: 1.875rem;
            margin-top: -0.0625rem;
        }

        @at-root {
            .workflows-overview & {
                margin-left: 0;

            }
        }



        &.first-visit {
            margin-left: 0;
        }

        &.is-expanded {
            height: auto;
            padding: 0 1.5rem 2.5rem 1.5rem;

            @include extraLd {
                padding: 0 3rem 5rem 3rem;
                width: auto;
            }

            @at-root {
                @include extraLd {
                    .workflows-overview & {
                        width: 40.25rem;

                    }
                }

            }

            &.first-visit {
                @include extraLd {
                    width: 40.25rem;
                }

            }

        }

        .c-button {
            margin: 0 auto;
            margin-top: 2rem;
            background: $brandMidBlue;



            @include extraLd {
                margin-top: 4rem;
                width: 100%;

            }


            &__text {
                @include extraLd {
                    padding: 2.25rem 0 2rem 0;
                }
            }
        }

    }

    .c-button__corner {
        border-color: $brandMidBlue;
    }


}