@import 'app/assets/scss/_variables.scss';
@import 'app/assets/scss/_mixins.scss';


.journey-manager-page {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: calc(100vh - ($mainNavHeight + $brandStrokeWidth));
  height: calc(100svh - ($mainNavHeight + $brandStrokeWidth));
  overflow: auto;
  padding: 1.313rem 2.938rem;
  color: #fff;
  font-family: Hexagon, Helvetica, sans-serif;

  @include extraLd {
    height: calc(100vh - ($mainNavHeight * 2 + $brandStrokeWidth));
    height: calc(100svh - ($mainNavHeight * 2 + $brandStrokeWidth));
  }
}

.journey-manager-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  vertical-align: middle;
  margin-bottom: 1.25rem;

  @include extraLd {
    font-size: 3rem;
  }

  &__navigation {
    text-transform: uppercase;
    display: flex;
    align-items: center;
    flex-direction: row;
    font-style: normal;
    font-weight: 400;
    font-size: 0.688rem;
    line-height: 0.688rem;
    cursor: pointer;
    color: #fff;
    background: none;
    border: none;

    @include extraLd {
      font-size: 1.375rem;
    }

    svg {
      margin: 0.375rem 0.313rem 0.438rem 0.5rem;
      path {
        fill: #fff;
      }

      @include extraLd {
        height: 1.25rem;
        width: 1.25rem;
        margin-right: 1rem;
      }
    }
  }

  &__title {
    font-style: normal;
    font-weight: 700;
    font-size: 1.375rem;
    line-height: 150%;
    text-transform: uppercase;

    @include extraLd {
      font-size: 2.75rem;
    }
  }

  .c-button {
    position: relative;
    background-color: $brandMidBlue;
    box-sizing: border-box;

    .c-button__text {
      padding: 0.625rem 1.25rem;

      font-style: normal;
      font-weight: 700;
      font-size: 0.75rem;
      line-height: 150%;
      text-transform: uppercase;

      @include extraLd {
        padding: 1.25rem 2.5rem;
        font-size: 1.5rem !important;
      }
    }

  }
  
  .c-button--size-sm .c-button__text {
    padding: 0;
  }
}

.journey-manager-content {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  overflow: auto;

  // for future
  &__filter {
    margin-right: 1.875rem;
    background: rgba(5, 30, 47, 0.82);
    width: 18.125rem;
  }

  &__list {
    flex-grow: 1;
    overflow: auto;
    display: flex;
    flex-direction: column;
  }
}


