@import 'app/assets/scss/_variables.scss';
@import 'app/assets/scss/_mixins.scss';

.auth {
  margin-top: $mainNavHeight;
  background-color: $brandDarkBlue;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: flex-end;
  color: $brandWhite;
  line-height: 1.5;

  @include extraLd {
    margin-top: calc($mainNavHeight * 2);
  }


  &__sidebar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
  }

  &__sidebar {
    width: 22.5rem;
    height: 100%;
    padding: 2rem 1.75rem 1.25rem 1.75rem;
    background: $brandShadeBlueNotTransparent;
    position: relative;

    @include extraLd {
      width: 45rem;
      padding: 3.2rem 3.5rem 5.5rem 3.5rem;
    }


  }

  &__sidebar-close {
    position: absolute;
    // top: 1.375rem;
    right: 1.375rem;
    z-index: 10;

    &:hover {
      cursor: pointer;
    }


    @include extraLd {
      top: 2.9rem;
      right: 2.75rem;
    }

    svg {
      @include extraLd {
        width: 3rem;
        height: 3rem;
      }
    }
  }

  &-form {
    // margin-top: 0.75rem;

    @include extraLd {
      // margin-top: 2.5rem;
    }
  }

  &::-webkit-scrollbar {
    width: 0.5rem;
    -webkit-appearance: none;

    @include extraLd {
      width: 1rem;
    }
  }




}