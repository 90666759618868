@import './src/app/assets/scss/_variables.scss';
@import '/src/app/assets/scss/_mixins.scss';

$uiCardPadding: 1.5rem;
$uiCardMarginTop: -$uiCardPadding;

.c-site-map-modal {
  $block: &;

  $padding: $uiCardPadding * 1.5;

  display: flex;
  pointer-events: none;
  position: fixed;
  z-index: 30;
  inset: 0;
  align-items: center;
  justify-content: center;
  top: $mainNavHeight;

  @include ld {
    margin-top: 6rem;
    align-items: start;
  }

  @include extraLd {
    margin-top: 12rem;
    margin-bottom: 24rem;

  }

  &.is-open {
    pointer-events: auto;

    &::before {
      opacity: 1;
    }
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    z-index: -1;
    inset: 0;
    opacity: 0;
    transition: opacity 0.5s;
  }

  .close-button {
    position: absolute;
    z-index: 28;
    top: 1.6875rem;
    right: 1.6875rem;

    @include extraLd {
      top: 3.75rem;
      right: 3.75rem;
    }
  }

  @at-root {
    &__container {
      position: relative;
      width: auto;
      @include brandBGNotSoBlur();
      padding: 2rem 2rem 0 2rem;

      max-width: 100vw;

      @include md {
        width: 100vw;
        padding-right: 0;
        max-width: 100vw;
        height: 100%;
      }

      @include sm {
        width: 100vw;
        padding-right: 0;
        max-width: 100vw;
        height: 100%;
      }

      @include ld {
        padding-bottom: 2.125rem;
        width: 90%;

      }

      @include extraLd {
        padding: 6.5rem 4rem 0 4rem;
        padding-bottom: 6.25rem;
      }

      opacity: 0;

      transform: scale(0.95) translate(0, 2rem);
      transition: opacity 0.5s,
      transform 0.5s;

      #{$block}.is-open & {
        opacity: 1;
        transform: scale(1) translate(0, 0);
        z-index: 26;

        @include ld {
          display: flex;
          flex-direction: column;
        }


      }
    }

    &__close-button {
      $offset: $padding * 0.75;

      position: absolute;
      z-index: 1;
      top: $offset;
      right: $offset;

      &-svg {
        width: 1.25rem;
      }
    }

    &__content {
      position: relative;
      padding: 0;

      @include sm {
        padding-right: 0;
        height: 100%;
      }

      @include md {
        padding-right: 0;
        height: 100%;
      }

      &-header {
        font-style: normal;
        font-weight: bold;
        font-size: 1.5rem;
        line-height: 1.5;
        text-transform: uppercase;

        margin-left: 1.9rem;
        margin-right: 1.9rem;

        @include sm {
          margin-right: 0;
        }

        @include md {
          margin-right: 0;
        }

        padding-left: 0.6rem;
        padding-bottom: 1.5rem;
        border-bottom: 0.13rem solid rgba(255, 255, 255, 0.5);
      }

      &-wrapper {
        position: relative;
        padding-top: 1.1rem;

        @include md {
          height: 100%;

          &::after {
            content: '';
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            width: 8.375rem;
            height: calc(100%);
            right: 0;
            position: absolute;
            z-index: 27;
            margin-left: auto;
            top: 0;
            background: linear-gradient(269.83deg,
                rgba(14, 36, 53, 0.95) 0.1%,
                rgba(14, 36, 53, 0) 99.8%);
          }
        }

        @include sm {
          height: 100%;

          &::after {
            content: '';
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            width: 8.375rem;
            height: calc(100%);
            right: 0;
            position: absolute;
            z-index: 9;
            margin-left: auto;
            top: 0;
            background: linear-gradient(269.83deg,
                rgba(14, 36, 53, 0.95) 0.1%,
                rgba(14, 36, 53, 0) 99.8%);
          }
        }
      }

      &-body {
        position: relative;
        overflow: hidden;
        height: 100%;
        padding-bottom: 1.9rem;
        // z-index: 28;

        @include md {
          padding-bottom: 1.18rem;
        }

        @include sm {
          padding-bottom: 1.18rem;
        }

        &-child {
          height: calc(100% - 6.5rem);
          // padding-bottom: 3.125rem;
          /* maximum width of scrollbar */
          overflow-y: auto;
          overflow-x: auto;
          position: relative;

          @include ld {
            height: calc(100% - 8rem);
            // padding-bottom: 8rem;
            overflow: hidden;
          }

          @include extraLd {
            height: calc(100% - 24rem);
            // padding-bottom: 24rem;
            overflow: auto;
          }

          &::-webkit-scrollbar {
            -webkit-appearance: none;
          }

          &::-webkit-scrollbar:vertical {
            width: 0.5rem;
          }

          &::-webkit-scrollbar:horizontal {
            height: 0.5rem;
          }

          &::-webkit-scrollbar-thumb {
            border-radius: 0.375rem;
            border: 0.125rem solid #c1c1c1;
            background-color: #c1c1c1;
          }
        }
      }
    }
  }

  .c-button {
    position: absolute;
    background-color: transparent;

    :hover {
      transform: none;
    }
  }

  .c-button__text::before {
    background-color: transparent;
  }

  .c-button--size-sm .c-button__text {
    padding: 0;
  }
}

.c-site-map-modal__content-footer {
  position: absolute;
  bottom: 3rem;
  left: 4rem;
  padding-left: 0.6rem;
  z-index: 28;

  @include ld {
    position: relative;
    bottom: unset;
    left: unset;
  }


  &_margin-top {
    margin-top: 1.5rem;
  }



  .c-button {
    position: relative;
    border: 0.0625rem solid rgba(255, 255, 255, 0.5);
    box-sizing: border-box;

    @include extraLd {
      border: 0.125rem solid rgba(255, 255, 255, 0.5);
    }

    .c-button__text {
      padding: 0.6rem 1.3rem;
      font-style: normal;
      font-weight: bold;
      font-size: 0.8rem;
      line-height: 1.5;
      text-transform: uppercase;

      @include extraLd {
        padding: 1.25rem 2.5rem;
        font-size: 1.5rem;
        line-height: 2.25rem;
      }
    }
  }
}

.nowrap {
  white-space: nowrap;
}

.container-paths {
  display: flex;
  flex-direction: row;
  position: relative;
  justify-content: space-between;

  &_margin-top {
    margin-top: 2.5rem;

    @include extraLd {
      margin-top: 3.75rem;
    }
  }

  &_margin-left {
    margin-left: 2.5rem;

    @include extraLd {
      margin-left: 3.5rem;
    }
  }

  &_margin-right {

    margin-right: 2.5rem;

    @include ld {
      margin-right: 3.3rem;
    }

    @include extraLd {
      // margin-right: 3.5rem;
    }
  }

  &__item {
    text-transform: uppercase;
    font-style: normal;
    font-weight: 900;
    font-size: 1rem;
    line-height: 1;
    color: #ffffff;
    text-decoration: none;
    text-align: left;

    @include extraLd {
      font-size: 2rem;
    }

    &.disabled-link {
      cursor: not-allowed;
      pointer-events: none;
      color: $brandWhiteMiddleTransparent;
    }
  }

  &__arrow_right {
    border: solid rgba(255, 255, 255, 0.5);
    border-width: 0.125rem 0.125rem 0 0;
    display: inline-block;
    padding: 0.25rem;
    transform: rotate(45deg);
    margin-left: 0.3rem;

    @include extraLd {
      border-width: 0.25rem 0.25rem 0 0;
      padding: 0.6rem;
      margin-left: 0.75rem;
      margin-right: 0.75rem;
    }

    &_selected {
      border-color: rgba(137, 192, 209, 0.5);
    }
  }

  &__column {
    &_margin-small-left {
      margin-left: 2.5rem;

      @include ld {
        margin-left: 1.75rem;
      }

      @include extraLd {
        margin-left: 3.5rem;
      }
    }

    &_margin-left {
      margin-left: 3.7rem;

      @include ld {
        margin-left: 2rem;
      }

      @include extraLd {
        margin-left: 7.25rem;
      }
    }
  }

  @include sm {
    &__column_last {
      padding-right: 4.4rem;
    }
  }

  @include md {
    &__column_last {
      padding-right: 4.4rem;
    }
  }
}

@include sm {
  .c-site-map-modal__content-body.container-paths_margin-right {
    margin-right: 0;
  }
}

@include md {
  .c-site-map-modal__content-body.container-paths_margin-right {
    margin-right: 0;
  }
}

.container-paths__item-title-top {
  font-style: normal;
  font-weight: normal;
  font-size: 0.7rem;
  line-height: 0.7rem;
  text-transform: uppercase;
  margin-bottom: 0.3rem;

  @include extraLd {
    font-size: 1.375rem;
    line-height: 1;
    margin-bottom: 0.5rem;
  }
}

.container-paths__item_margin-bottom {
  margin-bottom: 0.3rem;

  @include extraLd {
    margin-bottom: 0.5rem;
  }
}

.container-paths__item_selected {
  color: #89c0d1;
  cursor: default !important;
}

.container-paths__group-item {
  position: relative;
  font-style: normal;
  font-weight: normal;
  font-size: 0.9rem;
  line-height: 1.5;
  cursor: pointer;

  @include extraLd {
    font-size: 1.5rem;
  }

  &:not(.container-paths__item_selected):hover {
    text-decoration: underline;
    color: #89c0d1;
  }

  .container-paths__item_selected::before {
    content: 'content';
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    padding-top: 100%;
  }

  .icon-left {
    position: absolute;
    top: 0;
    left: -1.4rem;
    width: 0.8rem;
    height: 1.1rem;
    display: flex;
    align-items: center;
    justify-content: center;

    @include extraLd {
      left: -2.8rem;
      width: 1.75rem;
      height: 3rem;

      svg {
        width: 1.75rem;
        height: 3rem;
      }
    }
  }
}

.container-paths__group-item_margin-top {
  margin-top: 1.5rem;

  @include extraLd {
    margin-top: 2.25rem;
  }
}