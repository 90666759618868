@import 'app/assets/scss/_variables.scss';
@import 'app/assets/scss/_mixins.scss';

.c-hashtags {
  position: relative;
  bottom: -0.375rem;
  padding-left: 1.125rem;

  svg {
    position: absolute;
    top: 0.1875em;
    left: 0;

    path {
      fill: rgba($brandWhite, 0.7)
    }

  }

  @include extraLd {
    margin-top: 1rem;
    padding-left: 2rem;

    svg {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  @include sm {
    padding-left: 1rem;

    svg {}
  }


  ul {
    margin: 0;
    display: block;
  }

  .c-box__content &__list {
    display: flex;
    flex-wrap: wrap;
    margin: 0.0625rem 0 0 0;
    padding: 0;
    font-size: 0.875rem;
    color: rgba($brandWhite, 0.7);

    @include sm {
      font-size: 0.75rem;
    }

    @include extraLd {
      font-size: 1.75rem;
      padding-left: 0.875rem;
    }

    li {
      line-height: 1.4;
      display: inline;
      margin-right: 0.3rem;
    }
  }
}