@import 'app/assets/scss/_variables.scss';
@import 'app/assets/scss/_mixins.scss';

.tabs-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 0.75rem;

  // position: absolute;
  // bottom: 0;
  // top: 0;
  width: 100%;

  @include extraLd {
    row-gap: 1.5rem;
  }
}

.tab-nav {
  display: flex;
  width: 90%;
  flex: 0 0 1.875rem;

  &-item {
    flex: 0 0 50%;
    text-align: center;
    color: rgba($brandWhite, 0.5);
    border-bottom: 1px solid rgba($brandWhite, 0.2);
    transition: all 0.2s;

    &:hover {
      cursor: pointer;
    }

    .tab-title {
      font-size: 1rem;
      line-height: 1;
      padding-bottom: 0.5rem;
      font-weight: 900;
      color: rgba($brandWhite, 0.5);

      @include extraLd {
        font-size: 2rem;
      }
    }

    &.is-active {
      border-bottom: 2px solid rgba($brandWhite, 1);

      .tab-title {
        color: $brandWhite;
      }
    }
  }
}

.tab-content {
  // flex-grow: 2;
  position: relative;
  max-height: 100%;
  height: auto;
  max-height: calc(100% - (1.875rem + 0.75rem));
  flex: 0 0 calc(100% - 1.875rem);
}