@import 'app/assets/scss/_variables.scss';
@import 'app/assets/scss/_mixins.scss';

.scene-badge {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;

  font-family: Hexagon, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 0.875rem;
  z-index: 11;

  @include extraLd {
    font-size: 1.75rem;
    line-height: 1.75rem;
  }

  /* identical to box height */

  text-transform: uppercase;
  color: #ffffff;
  cursor: pointer;
  transition: letter-spacing 0.2s;

  &.zoom-in-one {
    font-size: 0.75rem;
    line-height: 0.75rem;

    @include extraLd {
      font-size: 1.5rem;
      line-height: 1.5rem;
    }

    .pointer-title {
      margin-left: 0.3125rem;
    }

    &:hover {
      letter-spacing: 0.03125rem;
    }
  }

  .pointer-title {
    margin-left: 0.875rem;
  }

  &:hover {
    z-index: 12;
    color: #c0fff7;
    letter-spacing: 0.0625rem;
  }

  &_margin {
    margin-left: 12px;
  }
}

//Pointers

.c-pointer {
  transform: scale(0);
  transform-origin: center;
  z-index: 2;

  $block: &;

  @-webkit-keyframes ccircle {
    from {
      -webkit-transform: rotateZ(360deg);
    }

    to {
      -webkit-transform: rotateZ(0deg);
    }
  }

  @keyframes pointerPulseBig {
    0% {
      fill-opacity: 0.7;
    }

    100% {
      fill-opacity: 0.42;
    }
  }

  @keyframes pointerPulseSmall {
    0% {
      r: 1.125rem;
      fill-opacity: 0.7;
    }

    87% {
      r: 1.687rem;
      fill-opacity: 0;
    }

    100% {
      r: 1.687rem;
      fill-opacity: 0;
    }
  }

  @keyframes pointerPulseSmallSecondary {
    0% {
      r: 0.787rem;
      fill-opacity: 0;
    }

    87% {
      r: 0.787rem;
      fill-opacity: 0;
    }

    100% {
      r: 1.125rem;
      fill-opacity: 0.7;
    }
  }

  &__circle-svg {
    $circle: &;
    transition: 0.2s;

    #{$block}__circle-big {
      fill-opacity: 0.7;
    }

    #{$block}__circle-small {
      r: 1.125rem;
      fill-opacity: 0.7;
    }

    #{$block}__circle-small-secondary {
      r: 1.687rem;
      fill-opacity: 0;
    }

    @at-root {
      .scene-badge:hover & {
        #{$block}__circle-big {
          animation: pointerPulseBig 1.11s linear infinite;
        }

        #{$block}__circle-small {
          animation: pointerPulseSmall 1.11s linear infinite;
        }

        #{$block}__circle-small-seconday {
          animation: pointerPulseSmallSecondary 1.11s linear infinite;
        }
      }
    }
  }

  $block: &;

  @at-root {
    &__circle-secondary {
      width: 100%;
      height: 100%;
      transform-origin: center center;
      -webkit-animation: ccircle 10s infinite linear;
    }
  }
}