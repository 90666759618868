@import './src/app/assets/scss/_variables.scss';
@import '/src/app/assets/scss/_mixins.scss';

$uiCardPadding: 1.5rem;
$uiCardMarginTop: -$uiCardPadding;

.c-privacy-policy {
  $block: &;

  padding: 1.625rem 1.5rem;

  display: flex;
  position: fixed;
  z-index: 30;
  align-items: center;
  justify-content: center;

  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(0.375rem);

  max-width: 49.25rem;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  bottom: -13.75rem;

  opacity: 0;

  @include extraLd {
    max-width: 98.5rem;
    padding: 3.25rem 3rem;
    bottom: -27.5rem;

  }

  &.is-open {
    pointer-events: auto;
    opacity: 0;

    animation-name: privacyPolicyIn;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 1.5s;
    animation-delay: 50ms;
    animation-fill-mode: forwards;
  }

  &.close {
    pointer-events: auto;
    opacity: 1;
    bottom: 1.25rem;

    animation-name: privacyPolicyOut;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 1.5s;
    animation-delay: 50ms;
    animation-fill-mode: forwards;

    @include extraLd {
      bottom: 2.5rem;
    }
  }

  @keyframes privacyPolicyIn {
    0% {
      opacity: 0;
      bottom: -13.75rem;

      @include extraLd {
        bottom: -27.5rem;
      }
    }

    100% {
      opacity: 1;
      bottom: 1.25rem;

      @include extraLd {
        bottom: 2.5rem;
      }
    }
  }

  @keyframes privacyPolicyOut {
    0% {
      opacity: 1;
      bottom: 1.25rem;

      @include extraLd {
        bottom: 2.5rem;
      }
    }

    100% {
      opacity: 0;
      bottom: -13.75rem;

      @include extraLd {
        bottom: -27.5rem;
      }
    }
  }

  @at-root {
    &__container {
      position: relative;

      display: flex;
      justify-content: space-between;

      // transform: scale(0.95) translate(0, 2rem);

      #{$block}.is-open & {
        opacity: 1;
        transform: scale(1) translate(0, 0);
      }

      &-accept {
        display: flex;
        flex-direction: column;
        align-self: center;

        &_margin {
          margin-left: 1.25rem;

          @include extraLd {
            margin-left: 2.5rem;
          }
        }
      }

      &-text {
        display: flex;
        flex-direction: column;
        align-self: center;

        color: #014969;
        font-style: normal;
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 1.5;

        @include extraLd {
          font-size: 1.75rem;
        }

        a {
          text-transform: none;
        }

        &_margin {
          margin-top: 0.25rem;

          @include extraLd {
            margin-top: 0.5rem;
          }
        }
      }
    }
  }
}