@import 'app/assets/scss/_variables.scss';
@import 'app/assets/scss/_mixins.scss';

.journey-manager-edit-page {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: calc(100vh - ($mainNavHeight + $brandStrokeWidth));
  height: calc(100svh - ($mainNavHeight + $brandStrokeWidth));
  overflow: auto;
  padding: 1.313rem 2.938rem;
  color: #fff;
  font-family: Hexagon, Helvetica, sans-serif;

  @include extraLd {
    height: calc(100vh - ($mainNavHeight * 2 + $brandStrokeWidth));
    height: calc(100svh - ($mainNavHeight * 2 + $brandStrokeWidth));
  }
}

.journey-manager-edit-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  vertical-align: middle;
  margin-bottom: 1.25rem;

  &__navigation {
    text-transform: uppercase;
    display: flex;
    align-items: center;
    flex-direction: row;
    font-style: normal;
    font-weight: 400;
    font-size: 0.688rem;
    line-height: 0.688rem;
    cursor: pointer;
    color: #fff;
    background: none;
    border: none;

    @include extraLd {
      font-size: 1.375rem;
    }

    svg {
      margin: 0.375rem 0.313rem 0.438rem 0.5rem;
      path {
        fill: #fff;
      }

      @include extraLd {
        height: 1.25rem;
        width: 1.25rem;
        margin-right: 1rem;
      }
    }
  }

  &__title {
    font-style: normal;
    font-weight: 700;
    font-size: 1.375rem;
    line-height: 150%;
    text-transform: uppercase;

    @include extraLd {
      font-size: 2.75rem;
    }
  }

  &__actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2.5rem;
  }
  &__action-cancel.c-button {
    .c-button__text {

      @include extraLd {
        font-size: 1.75rem !important;
      }

    }
  }
  &__action-create {
    &.c-button {
      position: relative;
      background-color: $brandBlueGreen;
      box-sizing: border-box;
  
      .c-button__text {
        padding: 0.625rem 1.25rem;
        color: #002C40;
        font-style: normal;
        font-weight: 700;
        font-size: 0.75rem;
        line-height: 150%;
        text-transform: uppercase;
  
        @include extraLd {
          padding: 1.25rem 2.5rem;
          font-size: 1.5rem;
        }
  
      }
  
    }
  }
  
  .c-button--size-sm .c-button__text {
    padding: 0;
  }
}

.journey-manager-edit-content {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  overflow: auto;

  // for future
  &__filter {
    margin-right: 1.875rem;
    background: rgba(5, 30, 47, 0.82);
    width: 18.125rem;
  }

  &__list {
    flex-grow: 1;
    overflow: auto;
    display: flex;
    flex-direction: column;
  }

  &__action-delete {
    &.c-button {
      display: flex;
      position: relative;
      // background-color: $brandBlueGreen;
      box-sizing: border-box;
      border: none;
  
      .c-button__text {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0.3125rem;

        padding: 0.625rem 1.25rem;
        color: #FF6161;
        font-style: normal;
        font-weight: 700;
        font-size: 0.75rem;
        line-height: 150%;
        text-transform: uppercase;

        & > span {
          margin-top: 1%;
        }

        svg path {
          stroke: none;
          stroke-width: initial;
          fill: #FF6161;
          vector-effect: none;
        }
  
        @include extraLd {
          padding: 1.25rem 2.5rem;
          font-size: 1.5rem;
          gap: 0.625rem;

          svg {
            height: 2.5rem;
            width: 2.5rem;
          }
        }
  
      }
  
    }
  }
}

.delete-journey-modal {

  .MuiPaper-root {
    width: 33.75rem;
    max-width: 33.75rem;

    @include extraLd {
      width: 67.5rem;
      max-width: 67.5rem;
    }
  }

  &_content-text {
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 150%;
    /* or 21px */
    color: #FFFFFF;
  
    display: flex;
    flex-direction: column;
  
    @include extraLd {
      font-size: 1.75rem;
    }
  }

  &_action-cancel {
    .c-button__text {
      padding: 0.5625rem 1.25rem !important;
      font-size: 0.875rem !important;
      font-weight: 400 !important;
      line-height: 150% !important;

      @include extraLd {
        padding: 1.125rem 2.5rem !important;
        font-size: 1.75rem !important;
      }
    }
  }

  &_action-delete {
    .c-button__text {
      padding: 0.625rem 1.25rem !important;
      font-size: 0.75rem !important;
      font-weight: 700 !important;
      line-height: 150% !important;

      @include extraLd {
        padding: 1.25rem 2.5rem !important;
        font-size: 1.5rem !important;
      }
    }
  }
}