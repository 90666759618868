@import 'app/assets/scss/_variables.scss';
@import 'app/assets/scss/_mixins.scss';

.workflows-check-boxes {
  &__element {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;

    &-label {
      font-size: 13px !important;
    }
  }
}