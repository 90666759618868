@import './src/app/assets/scss/_variables.scss';
@import '/src/app/assets/scss/_mixins.scss';

$uiCardPadding: 1.5rem;
$uiCardMarginTop: -$uiCardPadding;

.c-sitemap {
  .c-button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.625rem;
    position: relative;
    background-color: transparent;
    border: 0.0625rem solid rgba($brandWhite, 0.5);
    box-sizing: border-box;
    height: 2.375rem;
    padding: 0.565rem 1.296rem;

    @include md {
      padding: 0.565rem 1.028rem;
    }
    @include ld {
      padding: 0.565rem 1.102rem;
    }
    @include extraLd {
      padding: 0.5rem 2.585rem;
      border: 0.125rem solid $brandWhite;
      height: 4.75rem;
    }

    &:hover,
    &:active {
      background-color: $brandWhiteHardTransparent;
      transform: none;
    }

    .c-button__text {
      padding: 0;
      font-family: Hexagon, serif;
      font-style: normal;
      font-weight: bold;
      font-size: 0.75rem;
      line-height: 1.5;
      text-transform: uppercase;
      display: flex;
      flex-direction: row;
      gap: 0.4375rem;
      align-items: center;

      @include md {
        font-size: 0.75rem;
        line-height: 1;
      }
      @include ld {
        font-size: 0.875rem;
        line-height: 1;
      }

      @include extraLd {
        // padding: 1.25rem 2.5rem;
        font-size: 1.75rem;
        line-height: 1;
        gap: 0.8125rem;
      }

      &::before {
        background-color: transparent;
      }
    }
  }

  .c-button--size-sm .c-button__text {
    padding: 0;
  }

  .c-button path {
    fill: currentColor;
    stroke: currentColor;
    stroke-width: 0;
  }

  .c-button__icon svg {
    @include extraLd {
      width: 1.375rem;
      height: 2.75rem;
    }
  }
}
