@import 'app/assets/scss/variables.scss';
@import 'app/assets/scss/mixins.scss';

.linear-progress {
  display: flex;
  justify-content: center;
  width: 100vw;
}

.MuiTypography-root {
  font-family: Hexagon, Helvetica, sans-serif !important;
  font-size: 1.125rem !important;
  color: white !important;
  line-height: 1.5 !important;
  font-weight: 900 !important;
  margin-bottom: 0.5rem !important;

  @include md {
    font-size: 0.875rem !important;
  }
  @include sm {
    font-size: 0.875rem !important;
  }
  @include extraLd {
    font-size: 2.25rem !important;
    margin-bottom: 1rem !important;
  }
}
.MuiLinearProgress-root {
  width: 100%;
  height: 0.5625rem !important;
  background-color: rgba(255, 255, 255, 0.1) !important;
  margin-bottom: 2rem;

  @include md {
    margin-bottom: 1.5rem;
    width: 80%;
  }
  @include sm {
    margin-bottom: 1.5rem;
    width: 80%;
  }
  @include extraLd {
    margin-bottom: 4rem !important;
    width: 51.875rem;
    height: 1.125rem !important;
  }
}
.MuiLinearProgress-bar {
  background: linear-gradient(to right, #0197ba, #a5d867);
}
