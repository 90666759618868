@import 'app/assets/scss/_variables.scss';
@import 'app/assets/scss/_mixins.scss';

.contact-us-form {
  width: 100%;

  .form-body {
    display: flex;
    flex-direction: column;
    gap: 3.75rem;

    @include extraLd {
      gap: 7.5rem;
    }
  }

  .main-data {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.875rem;
    height: 100%;

    // @include ld {
    //   grid-template-columns: 3fr 3fr 4fr 1fr;
    //   gap: 2.5rem;
    // }

    // @include extraLd {
    //   grid-template-columns: 3fr 3fr 4fr 1fr;
    //   gap: 5rem;
    // }


    &_action {
      display: flex;
      flex-direction: column;
    }
  }

  .field {
    padding: 0;

    &--100 {
      grid-column: 1 / span 2;
    }

    @media only screen and (max-width: 768px) { // from old iframe functionality
      grid-column: 1 / span 2;
    }
  }

  .input-label-wrapper {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }

  .input-label {
    line-height: 150%;
    display: flex !important;
    align-items: flex-end;
    justify-content: space-between;
    font-size: 13px !important;

    .required {
      &::after {
        content: " *";
        // color: red!important;
      }
    }

    &__text--with-error {
      font-style: normal;
      font-weight: 700;
      // font-size: 0.625rem;
      color: #FF6161;
      opacity: 0.8;
    }

    &__info {
      &:hover {
        svg path {
          fill: #FFFEF6;
        }
      }

      @include extraLd {
        svg {
          height: 2.5rem;
          width: 2.5rem;
        }
      }
    }
  }

  .text-input-wrapper {
    width: 100%;
    position: relative;

    &--with-error {
      &::before {
        content: '';
        display: block;
        position: absolute;
        right: 0.313rem;
        top: 0.688rem;
        height: 1.25rem;
        width: 1.25rem;
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM11 15H9V13H11V15ZM11 11H9V5H11V11Z' fill='%23FF6161'/%3e%3c/svg%3e");
      
        @include extraLd {
          right: 0.626rem;
          top: 1.376rem;
          height: 2.5rem;
          width: 2.5rem;
        }
      }
    }

    &--select {
      &.text-input-wrapper--with-error {
        &::before {
          right: 1.313rem;

          @include extraLd {
            right: 1.626rem;
            top: 1.376rem;
            height: 2.5rem;
            width: 2.5rem;
          }
        }
      }
    }
  }

  .text-input {
    width: 100% !important;
    height: 2.75rem;
    font-size: 14px !important;

    @include extraLd {
      height: 5.5rem;
      font-size: 1.75rem;
    }

    &--area {
      height: 85px;
      min-height: 85px !important;
      max-height: 120px !important;
    }

  }

  .error-field {
    color: #FF6161 !important;
    opacity: 0.8;
    font-size: 10px !important;
  }

  .locations {
    min-height: 27.375rem;

    @include md {
      min-height: 34.5rem;
    }
    @include ld {
      min-height: 45.625rem;
    }

    @include extraLd {
      min-height: 91.25rem;
    }
  }

  .c-button {
    position: relative;
    background-color: transparent;
    border: 0.0625rem solid rgba(255, 255, 255, 0.5);
    box-sizing: border-box;
    height: 2.375rem;
    max-width: 250px;
    padding: 10px 10px !important;
    font: 15px/18px arial !important;
    line-height: 100% !important;
    height: auto;
  }

  .font-10 {
    font-size: 10px;
  }

  .link {
    text-transform: none;
  }
}

.privacy-check-boxes {
  &__element {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;

    &-label {
      font-size: 13px !important;
    }
  }
}