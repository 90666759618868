@import 'app/assets/scss/_variables.scss';
@import 'app/assets/scss/_mixins.scss';

.sidebar-left-content {
  width: 25.6625rem !important;
  height: 100%;
  backdrop-filter: blur(0.375rem);
  background-color: rgba(5, 30, 47, 0.82);
  padding: 4.1331rem 3.8556rem 0 3.0669rem !important;
  color: #ffffff;
  // border-bottom: 0.125rem solid #ffffff;
  z-index: 2;

  @include md {
    width: 31.5rem !important;
    padding: 4.8438rem 4.9375rem 0 3.5938rem !important;
  }

  @include ld {
    width: 49.4375rem !important;
    padding: 7.75rem 6.25rem 0 5.75rem !important;
  }

  @include extraLd {
    width: 98.875rem !important;
    padding: 15.5rem 16.8125rem 0 11.5rem !important;
  }

  &__title {
    .header {
      &__logo-container {
        max-width: 5.125rem;
        width: 33%;

        @include md {
          max-width: 7.25rem;
        }
      
        @include ld {
          max-width: 11.1875rem;
        }
      
        @include extraLd {
          max-width: 22.375rem;
        }
      }
      &__title {
        font-size: 0.775rem;

        @include md {
          font-size: 0.915rem;
        }
      
        @include ld {
          font-size: 1.5rem;
        }
      
        @include extraLd {
          font-size: 2.925rem;
        }
      }
    }
  }

  &__description {
    margin-top: 2.5625rem;
    display: flex;
    flex-direction: column;
    gap: 1.125rem;

    @include md {
      margin-top: 2.9375rem !important;
      gap: 1.3125rem;
    }

    @include ld {
      margin-top: 4.875rem !important;
      gap: 2.25rem;
    }

    @include extraLd {
      margin-top: 9.75rem !important;
      gap: 4.375rem;
    }

    &-title {
      font-family: Hexagon, Helvetica, sans-serif;
      font-size: 1.375rem;
      font-weight: 700;
      line-height: 1.65rem;
      text-align: left;

      color: #FFFFFF;
  
      @include md {
        font-size: 1.5625rem;
        line-height: 1.875rem;
      }
  
      @include ld {
        font-size: 2.5rem;
        line-height: 3rem;
      }
  
      @include extraLd {
        font-size: 5rem;
        line-height: 6rem;
      }
    }

    &-content {
      font-family: Hexagon, Helvetica, sans-serif;
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.1375rem;
      text-align: left;

      display: flex;
      flex-direction: column;
      gap: 1rem;

      // font-style: normal;
      // font-weight: 700;
      // font-size: 1.875rem;
      // line-height: 150%;
      color: #FFFFFF;
      // margin-top: 2.5rem;

      @include md {
        font-size: 1.125rem;
        line-height: 1.4625rem;
      }
  
      @include ld {
        gap: 1.8125rem;
        font-size: 1.75rem;
        line-height: 2.275rem;

      }
  
      @include extraLd {
        gap: 3.6875rem;
        font-size: 3.375rem;
        line-height: 4.3875rem;
      }
    }
    
  }

  &__button {
    position: relative;

    // min-width: 20rem;
    // max-width: 30rem;
    width: 100% !important;

    margin: 46px 0 0 0.85rem !important;

    @include md {
      margin-top: 3.125rem !important;
    }

    @include ld {
      margin-top: 5.1875rem !important;
    }

    @include extraLd {
      margin-top: 6.3125rem !important;
    }
    // @include extraLd {
    //   min-width: 40rem;
    //   max-width: 60rem;
    // }

    .c-button {
      background-color: #89c0d1;
      color: #002c40;
      font-weight: 700;


    }

    .c-button__text {
      font-size: 0.7rem;
      padding: 0.6875rem 4.5rem !important;

      @include md {
        font-size: 0.8rem;
        font-weight: 900;
        padding: 0.8125rem 5.25rem !important;
      }
  
      @include ld {
        font-size: 1.3rem;
        font-weight: 900;
        padding: 1.375rem 8.375rem !important;

      }

      @include extraLd {
        font-size: 2.7rem !important;
        padding: 2.8125rem 16.75rem !important;
      }

    }
  }
}

.sidebar {
  color: $brandMidBlue;
  padding-top: $mainNavHeight;
  overflow: hidden;
  z-index: 1;

  @include extraLd {
    padding-top: $mainNavHeight * 2;
  }

  $block: &;

  &__info {
    padding: 0 1.75rem;

    @include extraLd {
      padding: 0 3.5rem;
    }

    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 1.25rem 0;

      @include extraLd {
        margin: 2.5rem 0;
      }

      h2 {
        font-size: 1.75rem;
        font-weight: 900;
        line-height: 1;
        text-transform: uppercase;
        margin: 0;

        @include extraLd {
          font-size: 3.5rem;
        }
      }

      #{$block}__navigate {
        display: flex;
        gap: 1.125rem;

        @include extraLd {
          gap: 2.25rem;
        }

        svg {
          cursor: pointer;
          pointer-events: all !important;

          @include extraLd {
            width: 4rem;
            height: 2rem;
          }
        }
      }
    }

    .description {
      font-size: 0.9375rem;
      line-height: 1.5;
      border-bottom: 0.0625rem solid #c4c4c4;
      padding-bottom: 1.25rem;

      @include extraLd {
        font-size: 1.875rem;
        border-bottom: 0.125rem solid #c4c4c4;
        padding-bottom: 2.5rem;
      }
    }

    .bottom-button {
      margin-top: 1.25rem;
      padding: 1.25rem 0.825rem;

      @include extraLd {
        margin-top: 2.5rem;
      }

      .c-button {
        background-color: $brandMidBlue;
        width: 100%;
      }

      .c-button__corner {
        border-color: $brandMidBlue;
      }

      .c-button__text {
        font-size: 0.75rem;
        line-height: 1.5;
        font-weight: 700;
        color: white;
        padding: 1rem;

        @include extraLd {
          font-size: 1.5rem;
          padding: 2rem;
        }
      }
    }
  }
}