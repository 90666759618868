@import 'app/assets/scss/_variables.scss';
@import 'app/assets/scss/_mixins.scss';

.profile {
  position: relative;
  margin-left: 0.8125rem;
  display: flex;
  height: $mainNavHeight;
  align-items: center;

  @include extraLd {
    height: 8.25rem;
    margin-left: 1.5625rem;
  }
}
.avatar {
  font-size: 1rem;
  line-height: 1;
  padding-top: 0.125rem;
  color: $brandMidBlue;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.25rem;
  height: 2.25rem;
  border-radius: 50%;
  border: 0.0625rem solid $brandWhite;
  background: $brandWhiteNotTransparent;
  cursor: pointer;

  @include extraLd {
    width: 5rem;
    height: 5rem;
    font-size: 2.5rem;
  }
}

.dropdown-section {
  background: $brandShadeBlueNotTransparent;
  padding: 1.25rem 0;
  color: $brandWhite;
  position: absolute;
  top: calc(#{$mainNavHeight} - 0.0625rem);
  right: 0;
  z-index: 2;
  width: 13.5rem;
  font-size: 0.75rem;
  line-height: 1.5;

  pointer-events: none;
  opacity: 0;
  transform: translateX(100%);
  transition: opacity 0.4s ease-out, transform 0.2s ease-in;

  @include extraLd {
    top: calc(#{$mainNavHeight * 2} - 0.125rem);
    width: 27rem;
    font-size: 1.5rem;
    padding: 2.5rem 0;
  }

  &.is-open {
    opacity: 1;
    transform: translateX(0);
    pointer-events: auto;
  }

  &::before {
    content: '';
    position: absolute;
    border-style: solid;
    border-width: 0.375rem 0.5rem;
    border-color: transparent transparent $brandShadeBlueNotTransparent transparent;
    right: 0.5rem;
    /* TOP IS DOUBLE THE HEIGHT OF THE BORDER */
    top: -0.75rem;
  }

  .item {
  }

  .item-button {
    background-color: transparent;
    transition: background-color 0.2s;
    padding: 0.625rem 1.25rem;

    @include extraLd {
      padding: 1.25rem 2.5rem;
    }
    &:hover {
      background-color: $brandWhiteHardTransparent;
    }
  }

  .tags {
    .description {
      margin-bottom: 0.75rem;
      padding: 0 1.25rem;
      @include extraLd {
        padding: 0 2.5rem;
      }
    }

    .list {
      display: flex;
      font-size: 0.6875rem;

      gap: 0.375rem;
      align-items: flex-start;
      justify-content: flex-start;
      cursor: pointer;

      @include extraLd {
        font-size: 1.375rem;
        line-height: 1.5;
        gap: 0.75rem;
      }

      .icon {
        width: 0.75rem;
        height: 0.75rem;

        @include extraLd {
          width: 1.5rem;
          height: 1.5rem;
          svg {
            width: 1.5rem;
            height: 1.5rem;
          }
        }
      }

      &-links {
        cursor: pointer;
        a {
          text-transform: none;
          margin-right: 0.25rem;
          display: inline-block;
        }
      }

      .arrow {
        border: solid $brandWhiteMiddleTransparent;
        border-width: 0 0.1875rem 0.1875rem 0;
        display: inline-block;
        padding: 0.1875rem;
        align-self: center;
        margin-left: auto;
        @include extraLd {
          padding: 0.3125rem;
        }
      }

      .right {
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
      }
    }
  }

  .menu-button {
    line-height: 1.5;
    cursor: pointer;
  }
}
