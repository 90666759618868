@import './src/app/assets/scss/_variables.scss';
@import '/src/app/assets/scss/_mixins.scss';

$uiCardPadding: 1.5rem;
$uiCardMarginTop: -$uiCardPadding;

.c-info {
  $block: &;

  padding: 1.625rem 1.5rem;

  display: flex;
  position: absolute;
  z-index: 30;
  align-items: center;
  justify-content: center;

  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(0.375rem);

  max-width: 49.25rem;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  top: -14.875rem;

  opacity: 0;

  &.is-open {
    pointer-events: auto;
    opacity: 0;
    top: -14.875rem;

    animation-name: infoIn;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 1.5s;
    animation-delay: 50ms;
    animation-fill-mode: forwards;
  }

  &.close {
    pointer-events: auto;
    opacity: 1;
    top: 1.25rem;

    animation-name: infoOut;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 1.5s;
    animation-delay: 50ms;
    animation-fill-mode: forwards;
  }

  @keyframes infoIn {
    0% {
      opacity: 0;
      top: -14.875rem;
    }
    100% {
      opacity: 1;
      top: 4.125rem;
    }
  }

  @keyframes infoOut {
    0% {
      opacity: 1;
      top: 4.125rem;
    }
    100% {
      opacity: 0;
      top: -14.875rem;
    }
  }

  @at-root {
    &__container {
      position: relative;

      display: flex;
      justify-content: space-between;

      // transform: scale(0.95) translate(0, 2rem);

      #{$block}.is-open & {
        opacity: 1;
        transform: scale(1) translate(0, 0);
      }

      &-accept {
        display: flex;
        flex-direction: column;
        align-self: center;

        &_margin {
          margin-left: 1.25rem;
        }
      }

      &-text {
        display: flex;
        flex-direction: column;
        align-self: center;

        color: #014969;
        font-style: normal;
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 1.5;

        a {
          text-transform: none;
        }

        &_margin {
          margin-top: 0.25rem;
        }
      }
    }
  }

  &__close {
    display: flex;
    justify-items: center;

    margin-left: 1.25rem;

    .c-button {
      // position: absolute;
      background-color: transparent;
      color: rgba(27, 73, 102, 1);

      :hover {
        transform: none;
      }
    }

    .c-button__text::before {
      background-color: transparent;
    }
  
    .c-button--size-sm .c-button__text {
      padding: 0;
    }
  }
}
