@import 'app/assets/scss/_variables.scss';
@import 'app/assets/scss/_mixins.scss';

// variable `--base-sidebar-width` located in to `src/app/assets/scss/styles.scss`
// variable `--base-sidebar-position-top` located in to `src/app/assets/scss/styles.scss`
.base-sidebar {
  width: var(--base-sidebar-width); // 48px
  height: 52%;
  min-height: 24.375rem;
  position: absolute;
  // top: calc(50% - 10.51rem);
  top: var(--base-sidebar-position-top);
  display: flex;
  flex-direction: column;

  --base-sidebar-tab-padding-top-and-bottom: 0.6875rem;
  --base-sidebar-tab-border-width: 0.125rem;
  --base-sidebar-tab-corner-h: 2.25rem;
  --base-sidebar-tab-width-to-corner: 0.9375rem;
  --base-sidebar-tab-min-height: 12.0625rem;

  --base-sidebar-tab-text-size: 0.8125rem;
    --base-sidebar-tab-text-line-height: 0.975rem;
  // background: #ffffff;
  // backdrop-filter: blur(0.375rem);
  // clip-path: polygon(0 0, 0 100%, calc(100% - 2px) 100%, calc(100% - 2px) calc(100% - 2px), 2px calc(100% - 2px), 2px 2px, calc(100% - 32px) 2px, calc(100% - 2px) 32px, calc(100% - 2px) 100%, 100% 100%, 100% 32px, calc(100% - 32px) 0)
  @include sm {
    // width: 3rem * 2;
    --base-sidebar-tab-padding-top-and-bottom: 0.6875rem;
    --base-sidebar-tab-border-width: 0.125rem;
    --base-sidebar-tab-corner-h: 2.25rem;
    --base-sidebar-tab-width-to-corner: 0.9375rem;
    
    --base-sidebar-tab-text-size: 0.6875rem;
    --base-sidebar-tab-text-line-height: 0.825rem;
  }

  @include md {
    // width: 3rem * 2;
    --base-sidebar-tab-padding-top-and-bottom: 0.7769rem;
    --base-sidebar-tab-border-width: 0.125rem;
    --base-sidebar-tab-corner-h: 2.25rem;
    --base-sidebar-tab-width-to-corner: 0.9375rem;
    --base-sidebar-tab-min-height: 13.125rem;

    --base-sidebar-tab-text-size: 0.8125rem;
    --base-sidebar-tab-text-line-height: 0.975rem;
  }

  @include ld {
    // width: 3rem * 2;
    --base-sidebar-tab-padding-top-and-bottom: 1.25rem;
    --base-sidebar-tab-border-width: 0.1875rem;
    --base-sidebar-tab-corner-h: 3.5rem;
    --base-sidebar-tab-width-to-corner: 1.5625rem;
    --base-sidebar-tab-min-height: 21rem;

    --base-sidebar-tab-text-size: 1.25rem;
    --base-sidebar-tab-text-line-height: 1.5rem;
  }

  @include extraLd {
    // width: 3rem * 2;
    --base-sidebar-tab-padding-top-and-bottom: 2.5625rem;
    --base-sidebar-tab-border-width: 0.25rem;
    --base-sidebar-tab-corner-h: 7.125rem;
    --base-sidebar-tab-width-to-corner: 2.875rem;
    --base-sidebar-tab-min-height: 43.3125rem;

    --base-sidebar-tab-text-size: 2.5rem;
    --base-sidebar-tab-text-line-height: 3rem;
  }

  &__tabs {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }

  &__tab {
    // background: #0C2332;
    z-index: 10;
    position: relative;
    padding: calc(var(--base-sidebar-tab-padding-top-and-bottom) + var(--base-sidebar-tab-corner-h)) 0;
    outline: none !important;
    border: none !important;
    background: rgba(12, 35, 50, 0.92);
    // flex-grow: 1;
    min-height: var(--base-sidebar-tab-min-height);
    // backdrop-filter: blur(0.375rem);
    // x (left to right)--->  y (top to down)
    clip-path: polygon(0 var(--base-sidebar-tab-corner-h), 0 100%, var(--base-sidebar-tab-width-to-corner) 100%, 100% calc(100% - var(--base-sidebar-tab-corner-h)), 100% 0, var(--base-sidebar-tab-width-to-corner) var(--base-sidebar-tab-corner-h));
    margin-top: calc(0px - (var(--base-sidebar-tab-corner-h) + var(--base-sidebar-tab-border-width)));
    transition: background 0.3s;
    cursor: pointer;

    $this: &;

    &_active {
      background: rgba(2, 73, 105, 0.92);
      &::before {
        clip-path: polygon(0 var(--base-sidebar-tab-corner-h), 0 100%, var(--base-sidebar-tab-width-to-corner) 100%, 100% calc(100% - var(--base-sidebar-tab-corner-h)), 100% 0, var(--base-sidebar-tab-width-to-corner) var(--base-sidebar-tab-corner-h),
        var(--base-sidebar-tab-width-to-corner) calc(var(--base-sidebar-tab-corner-h) + var(--base-sidebar-tab-border-width)), calc(100% - var(--base-sidebar-tab-border-width)) calc(var(--base-sidebar-tab-border-width) + var(--base-sidebar-tab-border-width)), 100% var(--base-sidebar-tab-border-width), 100% calc(100% - (var(--base-sidebar-tab-corner-h) + var(--base-sidebar-tab-border-width))), var(--base-sidebar-tab-width-to-corner) calc(100% - var(--base-sidebar-tab-border-width)), var(--base-sidebar-tab-border-width) calc(100% - var(--base-sidebar-tab-border-width)), var(--base-sidebar-tab-border-width) calc(var(--base-sidebar-tab-corner-h) + var(--base-sidebar-tab-border-width)), var(--base-sidebar-tab-width-to-corner) calc(var(--base-sidebar-tab-corner-h) + var(--base-sidebar-tab-border-width)), var(--base-sidebar-tab-width-to-corner) var(--base-sidebar-tab-corner-h)) !important;
      }
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background: white;
      clip-path: polygon(0 var(--base-sidebar-tab-corner-h), 0 100%, var(--base-sidebar-tab-width-to-corner) 100%, 100% calc(100% - var(--base-sidebar-tab-corner-h)), 100% 0, var(--base-sidebar-tab-width-to-corner) var(--base-sidebar-tab-corner-h),
      var(--base-sidebar-tab-width-to-corner) calc(var(--base-sidebar-tab-corner-h) + var(--base-sidebar-tab-border-width)), calc(100% - var(--base-sidebar-tab-border-width)) calc(var(--base-sidebar-tab-border-width) + var(--base-sidebar-tab-border-width)), calc(100% - var(--base-sidebar-tab-border-width)) calc(100% - (var(--base-sidebar-tab-corner-h) + var(--base-sidebar-tab-border-width))), var(--base-sidebar-tab-width-to-corner) calc(100% - var(--base-sidebar-tab-border-width)), var(--base-sidebar-tab-border-width) calc(100% - var(--base-sidebar-tab-border-width)), var(--base-sidebar-tab-border-width) calc(var(--base-sidebar-tab-corner-h) + var(--base-sidebar-tab-border-width)), var(--base-sidebar-tab-width-to-corner) calc(var(--base-sidebar-tab-corner-h) + var(--base-sidebar-tab-border-width)), var(--base-sidebar-tab-width-to-corner) var(--base-sidebar-tab-corner-h));
    }

    &_first {
      margin-top: 0;
      clip-path: polygon(0 0, 0 100%, var(--base-sidebar-tab-width-to-corner) 100%, 100% calc(100% - var(--base-sidebar-tab-corner-h)), 100% 0);
      
      &::before {
        clip-path: polygon(0 0, 0 100%, var(--base-sidebar-tab-width-to-corner) 100%, 100% calc(100% - var(--base-sidebar-tab-corner-h)), 100% 0,
        var(--base-sidebar-tab-border-width) 0, var(--base-sidebar-tab-border-width) var(--base-sidebar-tab-border-width), calc(100% - var(--base-sidebar-tab-border-width)) var(--base-sidebar-tab-border-width), calc(100% - var(--base-sidebar-tab-border-width)) calc(100% - (var(--base-sidebar-tab-corner-h) + var(--base-sidebar-tab-border-width))), var(--base-sidebar-tab-width-to-corner) calc(100% - var(--base-sidebar-tab-border-width)), var(--base-sidebar-tab-border-width) calc(100% - var(--base-sidebar-tab-border-width)), var(--base-sidebar-tab-border-width) 0);
        }

      &#{$this}_active {
        &::before {
          clip-path: polygon(0 0, 0 100%, var(--base-sidebar-tab-width-to-corner) 100%, 100% calc(100% - var(--base-sidebar-tab-corner-h)), 100% 0,
          var(--base-sidebar-tab-border-width) 0, var(--base-sidebar-tab-border-width) var(--base-sidebar-tab-border-width), 100% var(--base-sidebar-tab-border-width), 100% calc(100% - (var(--base-sidebar-tab-corner-h) + var(--base-sidebar-tab-border-width))), var(--base-sidebar-tab-width-to-corner) calc(100% - var(--base-sidebar-tab-border-width)), var(--base-sidebar-tab-border-width) calc(100% - var(--base-sidebar-tab-border-width)), var(--base-sidebar-tab-border-width) 0) !important;
        }
      }
    }

    &-text {
      font-family: Hexagon, Helvetica, sans-serif;
      font-size: var(--base-sidebar-tab-text-size);
      font-weight: 700;
      line-height: var(--base-sidebar-tab-text-line-height);
      text-align: center;
      white-space: nowrap;


      color: white;
      text-transform: uppercase;
      writing-mode: vertical-rl;
      text-orientation: mixed;
      transform: rotate(180deg);

    }
  }
}
