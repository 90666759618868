@import 'app/assets/scss/_variables.scss';
@import 'app/assets/scss/_mixins.scss';

.auth-form {
    color: $brandWhite;
    line-height: 1.5;
    height: auto;
    max-height: calc(100% - (1.875rem + 2.75rem - 2rem));



    .bottom-button .c-button {
        width: 100%;
        background-color: $brandBlueGreen;
        font-weight: 700;
        color: $brandShadeBlueNotTransparent;
        margin: 0 auto;

        @include extraLd {
            font-size: 2rem;
        }
    }

    .bottom-button .c-button:disabled {
        opacity: 0.5;
        cursor: not-allowed;
        pointer-events: all !important;

        &:hover {
            transform: none;

            .c-button__corner {
                width: 0.85rem;
                height: 0.85rem;
            }
        }
    }

    .buttons-group-vertical.c-margin-top {
        margin-top: 4.375rem;
    }

    .form-link {
        display: block;
        text-align: center;
        color: $brandBlueGreen;
        text-decoration: underline;
        cursor: pointer;

        @include extraLd {
            font-size: 1.75rem;
        }
    }

    // .login-text {
    //     display: block;
    //     text-align: center;
    //     font-size: 0.875rem;
    //     color: $brandGray;
    //     padding-top: 1.5rem;

    //     @include extraLd {
    //         font-size: 1.75rem;
    //         padding-top: 3rem;
    //     }

    //     span {
    //         color: $brandWhite;
    //         text-transform: uppercase;
    //         text-decoration: underline;
    //         margin-left: 0.5rem;
    //         cursor: pointer;

    //         @include extraLd {
    //             margin-left: 1rem;
    //         }
    //     }
    // }

    .bottom-button {
        margin-top: 1.8125rem;
        margin-bottom: 1.8125rem;

        @include extraLd {
            margin-top: 3.625rem;
            margin-bottom: 3.625rem;
        }
    }
}

.auth-title {
    font-size: 1.375rem;
    text-transform: uppercase;
    font-weight: 700;
    color: $brandWhite;

    @include extraLd {
        font-size: 2.75rem;
    }
}

.auth-subtitle {
    line-height: 1;
    font-size: 0.625rem;
    font-weight: 400;
    // padding-top: 0.25rem;
    text-transform: uppercase;

    @include md {
        font-size: 0.685rem;
    }

    @include extraLd {
        font-size: 1.375rem;
    }
}

.form-describe {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0.75rem;
    padding-top: 0.5rem;
    color: $brandWhite;
    font-size: 0.875rem;

    @include md {
        padding-top: 1rem;
    }

    @include extraLd {
        gap: 2rem;
        font-size: 1.5rem;
        // padding-top: 2rem;

        svg {
            width: 3rem;
            height: 3rem;
        }
    }

    .text-describe {
        flex: 1 1 0;
        line-height: 1.5;

        @include extraLd {
            flex: 0 1 70%;
            line-height: 3.5rem;
        }

        span {
            color: rgba(194, 255, 120, 1);
        }
    }

    .icon-button {
        cursor: pointer;

        @include extraLd {
            width: 3.75rem;
            height: 3.75rem;
        }
    }

    .icon-round {
        width: 1.5rem;
        height: 1.5rem;
        align-self: flex-start;

        @include extraLd {
            width: 3rem;
            height: 3rem;
        }
    }
}

.form-body {
    display: flex;
    flex-direction: column;
    row-gap: 0.9375rem;
    margin-top: 0.75rem;
    height: 100%;
    min-height: 0;

    @include extraLd {
        margin-top: 1.5rem;
    }

    &.two-columns {
        display: grid;
        grid-template-columns: repeat(2, 1fr);

        .field:nth-child(even) {
            margin-left: 1rem;
        }
    }

    &.full-screen .field .text-input {
        width: 19rem;

        @include extraLd {
            width: 38rem;
        }
    }

    &.full-screen .field .error-field {
        width: 19rem;

        @include extraLd {
            width: 38rem;
        }
    }

    .error-field {
        display: block;
        text-align: left;
        font-size: 0.625rem;
        color: $brandRed;
        padding-top: 0.375em;

        @include extraLd {
            font-size: 1.25rem;
            padding-top: 0.75em;
        }

        span {
            color: $brandBlueGreen;
            text-decoration: underline;
            margin-left: 0.5rem;

            @include extraLd {
                margin-left: 1rem;
            }
        }
    }

    .with-scroll {
        flex-grow: 1;
        overflow-y: auto;


        &::-webkit-scrollbar-track {
            border-radius: 0.5rem;

            @include extraLd {
                border-radius: 1rem;
            }
        }

        &::-webkit-scrollbar {
            width: 0.5rem;
            -webkit-appearance: none;

            @include extraLd {
                width: 1rem;
            }
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 0.5rem;
            background-color: rgba(193, 193, 193, 0.2);

            @include extraLd {
                border-radius: 1rem;
            }
        }

        &::-webkit-scrollbar-thumb:hover {
            background: rgba(193, 193, 193, 0.4);
        }

        &::-webkit-scrollbar-thumb:window-inactive {
            background: rgba(193, 193, 193, 0.5);
        }
    }

    .field {


        &--first {
            padding-top: 0;
        }



        @include extraLd {
            padding-top: 3.5rem;
        }

        label.input-label {
            margin-bottom: 0.25rem;
            font-size: 0.625rem;
            color: $brandBlueGreen;
            display: block;

            @include extraLd {
                font-size: 1.25rem;
                margin-bottom: 0.5rem;
            }
        }

        select.text-input,
        textarea.text-input,
        input.text-input {
            font-size: 0.875rem;
            padding: 0.75rem 1rem;
            color: $brandWhite;
            width: 100%;
            min-height: 2.75rem;
            background: $brandShadeBlueNotTransparent;
            border: 0.0625rem solid rgba(255, 255, 255, 0.2);
            outline: none;

            @include extraLd {
                font-size: 1.5rem;
                padding: 1.5rem 2rem;
                line-height: 1.5;
            }

            ::placeholder {
                /* Chrome, Firefox, Opera, Safari 10.1+ */
                color: $brandGray;
                opacity: 1;
                /* Firefox */
            }
        }
    }
}

.emplyee-login {
    position: relative;
    text-align: center;
    bottom: 0;
    color: $brandBlueGreen;
    text-decoration: underline;
    font-size: 0.875rem;
    transition: all 0.2s ease-out;

    @include extraLd {
        font-size: 1.75rem;
    }

    &:hover {
        text-decoration: none;
        cursor: pointer;
    }
}

.c-gdpr {
    display: flex;
    align-items: flex-start;



    &--description {
        overflow-y: auto;
    }


    p {
        margin: 0 0 0.5rem 0;
        font-size: 0.625rem;
        line-height: 1.5;
        font-weight: 400;
        color: $brandGray;

        @include ld {
            font-size: 0.75rem;
        }

        @include extraLd {
            font-size: 1.5rem;
        }


        a {
            color: $brandBlueGreen;
            text-transform: none;

            &:hover {
                text-decoration: none;
            }
        }
    }
}