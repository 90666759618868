// ---------------------------------------------------------- //
// Here's all the sass
// ---------------------------------------------------------- //

/* stylelint-disable */

// TODOS for Nick

@import './_variables.scss';
@import './_mixins.scss';
// @import url('../icons/bullet.svg') as "Bullet"; 

// Fonts and stuff
// ---------------------------------------------------------- //

// Light

@font-face {
  font-family: 'Hexagon';
  font-weight: 300;
  src: url('../fonts/Hexagon Akkurat Web Light.eot');
  src: url('../fonts/Hexagon Akkurat Web Light.woff') format('woff'),
    url('../fonts/Hexagon Akkurat Web Light.woff2') format('woff2');
}

// Regular

@font-face {
  font-family: 'Hexagon';
  font-weight: 400;
  src: url('../fonts/Hexagon Akkurat Web.eot');
  src: url('../fonts/Hexagon Akkurat Web.woff') format('woff'),
    url('../fonts/Hexagon Akkurat Web.woff2') format('woff2');
}

// Bold

@font-face {
  font-family: 'Hexagon';
  font-weight: 700;
  src: url('../fonts/Hexagon Akkurat Web Bold.eot');
  src: url('../fonts/Hexagon Akkurat Web Bold.woff') format('woff'),
    url('../fonts/Hexagon Akkurat Web Bold.woff2') format('woff2');
}

// Black

@font-face {
  font-family: 'Hexagon';
  font-weight: 900;
  src: url('../fonts/Hexagon Akkurat Web Black.eot');
  src: url('../fonts/Hexagon Akkurat Web Black.woff') format('woff'),
    url('../fonts/Hexagon Akkurat Web Black.woff2') format('woff2');
}

// Normalize
// ---------------------------------------------------------- //

html,
body,
#lomix {
  height: 100%;
  -webkit-text-size-adjust: none;
  font-size: 16px;
}

@media (-webkit-min-device-pixel-ratio: 1.25) {

  html,
  body,
  #lomix {
    font-size: 14px;
  }
}

@media (-webkit-min-device-pixel-ratio: 1.5) {

  html,
  body,
  #lomix {
    font-size: 12px;
  }
}

@media (-webkit-min-device-pixel-ratio: 1.75) {

  html,
  body,
  #lomix {
    font-size: 10px;
  }
}

@media (-webkit-device-pixel-ratio: 2) {

  html,
  body,
  #lomix {
    font-size: 16px;
  }
}

body {
  touch-action: none;
  user-select: none;
  font-family: Hexagon, Helvetica, sans-serif;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  margin: 0;
  overflow: hidden;

  --base-menu-width: 5%;
  --base-menu-min-width: 14.69rem;
  --base-menu-max-width: 28rem;

  --base-sidebar-width: 2.2906rem;
  --base-sidebar-position-top: 11rem; 
  // --base-sidebar-position-top: 11.2188rem; !!!!!
  --wayfinder-height: 2.375rem;

  @include md {
    --base-menu-min-width: 17.4rem;

    --wayfinder-height: 2.8125rem;

    --base-sidebar-position-top: 12.3rem; 
  }

  @include ld {
    --base-sidebar-width: 4.25rem;
    --base-sidebar-position-top: 17.4375rem;
    --wayfinder-height: 4.5rem;
    --base-menu-min-width: 27.4rem;
  }

  @include extraLd {
    --base-sidebar-width: 8.5313rem;
    --base-sidebar-position-top: 35rem;
    --wayfinder-height: 8.9375rem;
    --base-menu-min-width: 54.4rem;
    --base-menu-max-width: 55rem;
  }
}

body * {
  box-sizing: border-box;
}

#root {
  position: fixed;
  width: 100vw;
  height: 100vh;
}

ul.reset,
ul.reset li,
ul.reset ul li {
  margin: 0;
  padding: 0;
  text-indent: 0;
  list-style-type: none;
}


// Header
// ---------------------------------------------------------- //

.header {
  $block: &;

  $xPadding: 1.25rem;
  $yPadding: 0rem;
  $logoMargin: 0.75rem;
  $seekerMargin: 0.1875rem;

  $inDuration: 0.75s;

  pointer-events: none;
  position: relative;
  z-index: 14;
  top: 0;
  left: 0;
  width: 100%;
  transform: translate(0, -100%);

  transition: transform $inDuration ease-out;


  &.is-visible {
    pointer-events: auto;
    transform: none;
  }

  @at-root {
    &__main {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: $brandDarkBlueTransparent;
      padding: $yPadding $xPadding;
      color: $brandWhite;
      border-bottom: $brandStrokeWidth solid $brandWhite;
      height: $mainNavHeight;
      z-index: 2;

      @include extraLd {
        padding: $yPadding $xPadding * 2;
        height: $mainNavHeight * 2;
        border-bottom: $brandStrokeWidth * 2 solid $brandWhite;
        transform: translate(0, 0);

        transition: transform $inDuration ease-out;
      }
    }

    &__brand {
      display: flex;
      align-items: center;
    }

    &__buttons {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      height: 100%;

      .download {
        margin-right: 0.75rem;

        button {
          @include reset-button();
          padding: 0.5rem;
          border: 0.0625rem solid $brandWhite;
        }
      }
    }

    &__logo-container {
      width: 8.375rem;
      margin-right: $logoMargin;

      @include extraLd {
        margin-right: $logoMargin * 2;
        width: 8.375 * 2rem;
      }

      &:hover {
        cursor: pointer;
      }

      &.no-link:hover {
        cursor: auto;
      }
    }

    &__logo {
      display: block;
      margin-top: 0.125rem;
    }

    &__title {
      @include littleAnnoyingDividerLine();

      padding-left: $logoMargin;
      font-size: 1.125rem;
      line-height: 1;
      font-weight: 400;
      text-transform: uppercase;
      overflow: hidden;
      margin: 0;
      padding-top: 0.175rem;

      @include md {
        font-size: 1.125rem;
      }

      @include extraLd {
        font-size: 2.25rem;
        padding-left: $logoMargin * 2;
      }

      &::before {
        content: '';
        position: absolute;
        top: 0.125rem;
        bottom: 0.155rem;
        left: 0;
        border-left: 0.0625rem solid $brandWhite;

        @include extraLd {
          border-left: 0.125rem solid $brandWhite;
        }
      }

      &-inner {
        display: block;
        opacity: 0;
        transform: translate(-0.5rem, 0);

        transition: opacity 0.2s $inDuration, transform 0.2s $inDuration;

        #{$block}.is-visible & {
          opacity: 1;
          transform: translate(0, 0);
        }
      }
    }

    &__contact {
      display: block;
    }

    // The secondary header
    // This is where the markers and wayfinders are

    &__secondary {
      pointer-events: none;
      display: flex;
      justify-content: space-between;

      &.page-overview {
        display: inline-block;
        position: absolute;
      }

      &.is-visible {
        pointer-events: auto;
      }
    }

    &__third {
      pointer-events: none;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: end;
      -webkit-justify-content: flex-end;
      -ms-flex-pack: end;
      justify-content: flex-end;

      &.is-visible {
        pointer-events: auto;
      }

      &_margin {
        margin: 1rem 1rem 0 0;
      }

      &-device-rotate {
        display: inline-block;

        background-color: rgba(5, 30, 47, 0.825);

        padding: 1.7rem 1.95rem 1.513rem 1.7rem;

        border: 0.125rem solid #ffffff;

        clip-path: polygon(0 0,
          0 0,
          0 0,
          100% 0,
          100% calc(100% - #{$bevelOffset}),
          calc(100% - #{$bevelOffset}) 100%,
          0 100%,
          0 0);

        &-corner {
          position: relative;
        }

        svg {
          width: 5rem;
          height: 5rem;
        }

        &-container.fade-in {
          opacity: 0;
          animation-name: fadeIn;
          animation-iteration-count: 1;
          animation-timing-function: ease-in;
          animation-duration: 2s;
          animation-delay: 100ms;
          animation-fill-mode: forwards;
        }

        @keyframes fadeIn {
          0% {
            opacity: 0;
            transform: translateX(calc((1.7rem + 17rem + 5rem + 1rem) + 0.625rem));
          }

          100% {
            opacity: 1;
            transform: translateX(0);
          }
        }
      }

      .corner-line {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 2rem;
        height: 2rem;

        line {
          stroke-dashoffset: 0;
          stroke-dasharray: none;
          fill: none;
          stroke: #ffffff;
          stroke-width: 1.66666666;
        }
      }
    }

    &__seeker {
      opacity: 0;
      transform: translate(0, 0);
      //transform: none;
      transition: transform $inDuration ease-out, opacity 0.2s;
      //transition: opacity 0.2s;
      display: flex;

      #{$block}__wayfinder {
        &-number {
          font-size: 0.875rem;
          font-weight: 900;

          @include extraLd {
            font-size: 2.5rem;
          }
        }

        &-name {
          margin-top: 0.25rem;
          font-size: 0.687rem;
          font-weight: 400;
          white-space: nowrap;

          @include extraLd {
            font-size: 1.75rem;
          }
        }
      }

      #{$block}__wayfinder:not(:last-child) {
        margin-right: $seekerMargin;

        @include extraLd {
          margin-right: 0.75rem;
        }
      }

      @include extraLd {
        position: fixed;
        right: 0;
        bottom: 0;
      }

      #{$block}__secondary.is-visible & {
        opacity: 1;
      }
    }

    &__marker {
      transform: translate(0, 0);
    }

    &__wayfinder {
      $slantyBoiWidth: 3rem;
      $slantyBoiPos: calc(100% - #{$slantyBoiWidth} / 2);
      $slantyBoiSkew: 45deg;

      display: flex;
      align-items: center;
      position: relative;
      z-index: 0;
      background-color: $brandWhite;
      padding: $yPadding $xPadding;
      color: $brandMidBlue;

      transition: opacity 0.35s, transform 0.35s;
      height: var(--wayfinder-height);
      // height: 3rem;

      @include extraLd {
        padding: $yPadding 4.375rem;

        .c-arrow {
          width: 4rem;
        }

        .c-arrow svg {
          width: 4rem;
          height: auto;
        }
      }

      &.is-disabled {
        cursor: not-allowed;
        opacity: 0.5;

        &>* {
          pointer-events: none;
        }
      }

      // Little slanty bois

      &--left,
      &--right {
        &::after {
          content: '';
          position: absolute;
          z-index: -1;
          top: 0;
          width: 3rem;
          height: 100%;
        }
      }

      &--left {
        background-color: $brandDarkBlueTransparent;
        color: $brandWhite;

        @include ld {
          padding: 0.5rem 0.0625rem 0rem 2rem;
        }

        @include extraLd {
          height: 5.625rem;
          height: var(--wayfinder-height);
          // padding: $yPadding $xPadding * 2;
          padding: 0.6875rem 0 0 3.8125rem;

          #{$block}__wayfinder-number {
            font-size: 1.375rem;
            font-weight: normal;
          }

          #{$block}__wayfinder-name {
            font-size: 1.75rem;
            font-weight: 900;
          }
        }

        &::after {
          width: 0;
          height: 0;
          border-width: var(--wayfinder-height) var(--wayfinder-height) 0 0;
          border-color: $brandDarkBlueTransparent transparent transparent transparent;
          border-style: solid;
          // right: -3rem;
          left: 100%;

          @include extraLd {
            // border-width: 5.625rem 5.625rem 0 0;
            // right: -5.595rem;
            left: 100%;
          }
        }
      }

      &--right {
        padding-left: calc($xPadding / 3);

        @include extraLd {
          padding-left: 4.375rem;

          .c-arrow {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 2.4rem;
          }

          .c-arrow svg {
            width: 1rem;
            height: auto;
          }
        }

        &::after {
          // background-color: $brandWhite;
          width: 0;
          height: 0;
          border-width: 0 var(--wayfinder-height) var(--wayfinder-height) 0;
          border-color: transparent $brandWhite transparent transparent;
          border-style: solid;
          right: 100%;
          // right: $slantyBoiPos;
          // transform: skewX($slantyBoiSkew);

          @include extraLd {
            display: none;
          }
        }
      }

      &-label {
        text-transform: uppercase;
        margin: 0;
      }

      &-number,
      &-name {
        display: block;
      }

      &-number {
        font-size: 0.6875rem;
        font-weight: 400;

        @include extraLd {
          font-size: 2.5rem;
        }
      }

      &-name {
        // margin-top: 0.25rem;
        font-size: 0.6875rem;
        line-height: 0.825rem;
        font-weight: 900;
        white-space: nowrap;

        @include md {
          font-size: 0.8125rem;
          line-height: 0.975rem;
        }
        @include ld {
          font-size: 1.25rem;
          line-height: 1.5rem;
        }

        @include extraLd {
          font-size: 2.4825rem !important;
          line-height: 2.9794rem;
        }
      }
    }

    &__seeker {}

    &__previous-button,
    &__next-button {
      cursor: pointer;
    }

    &__next-location {
      display: flex;
      align-items: center;

      #{$block}__wayfinder-label {
        margin-right: 0.75rem;

        @include extraLd {
          margin-right: 2.75rem;
        }
      }
    }
  }

  .langSelect-enter {
    opacity: 0;
    // transform: scale(0.95);
  }

  .langSelect-enter-done {
    opacity: 1;
    // transform: scale(1);
    transition: all 300ms;
  }

  .langSelect-exit {
    opacity: 1;
  }

  .langSelect-exit-done {
    opacity: 0;
    transition: opacity 300ms;
  }

  .language-select {
    margin-left: 0.565rem;
    border: 0.0625rem solid rgba($brandWhite, 0.5);
    position: relative;
    width: 4.4375rem;

    &:hover {
      cursor: pointer;
    }

    @include extraLd {
      border-width: 0.125rem;
      margin-left: 1.5rem;
      width: 8.875rem;
    }

    .arrow {
      width: 1.5rem;
      height: 1.5rem;

      @include extraLd {
        width: 3rem;
        height: 3rem;
      }

      path {
        fill: $brandWhite;
      }

      &.up {
        transform: rotate(180deg);
      }

      &.down {}
    }



    &.is-open {
      align-self: flex-end;
      border-bottom-color: transparent;
    }

    .dropdown-wrapper {
      height: calc(2.375rem - 0.125rem);
      padding: 0 0.5rem;

      @include extraLd {
        height: calc(4.75rem - 0.125rem * 2);
        padding: 0 1rem;
      }

      &.is-open {
        height: calc(3.25rem - 0.125rem - 0.0625rem);

        @include extraLd {
          height: calc(6.37rem - 0.125rem * 2);
        }
      }
    }

    .dropdown-toggle {
      // width: 4.4375rem;
      font-size: 0.75rem;
      font-weight: 700;
      padding: 0.625rem 0;
      text-align: center;
      height: 2.375rem;

      display: flex;
      align-items: center;
      justify-content: center;

      @include extraLd {
        height: 4.75rem;
        // width: 8.875rem;
        font-size: 1.5rem;
        padding: 0.99rem 0;
      }

      span {
        position: relative;
        top: 0.0625rem;
      }

      &.is-open {
        border-bottom: 0.0625rem solid rgba($brandWhite, 0.5);

        @include extraLd {
          border-bottom-width: 0.125rem;
        }
      }


    }


    .dropdown-menu {
      position: absolute;
      width: 4.4375rem;
      border: 0.0625rem solid rgba($brandWhite, 0.5);
      margin-left: -0.0625rem;
      background: rgba($brandDarkBlue, 0.82);
      border-top: none;
      padding-top: 0.8125rem;

      @include extraLd {
        border-width: 0.125rem;
        margin-left: -0.125rem;
        width: 8.875rem;
      }



      .dropdown-item {
        padding: 0.625rem 0.8125rem 0.625rem 0.9375rem;
        font-size: 0.75rem;
        text-align: center;


        @include extraLd {
          padding: 1rem 1.625rem 1rem 1.875rem;
          font-size: 1.5rem;
        }



        &:not(.is-active) {
          cursor: pointer;

          &:hover {
            background: rgba($brandWhite, 0.1);
          }
        }


      }
    }

  }
}

// Arrow buttons

.c-arrow {
  $block: &;

  $clickableOffset: 0.5rem;

  @include reset-button();

  cursor: pointer;
  position: relative;
  width: 2rem;

  &::after {
    content: '';
    display: block;
    position: absolute;
    inset: -$clickableOffset;
  }

  svg {
    display: block;
    width: 100%;
    overflow: visible;

    @include extraLd {
      width: 1rem;
      height: auto;
    }
  }

  line {
    stroke-width: 2;
    stroke: currentColor;
    vector-effect: non-scaling-stroke;
  }
}

// The stage
// ---------------------------------------------------------- //

// Some variables are shared

$centerBlur: 0.0625rem;

// ...as are mixins are shared

@mixin stageSVGStroke($stroke: $brandWhite) {
  fill: none;
  stroke: $stroke;
  stroke-width: $brandSVGStrokeWidth;
  vector-effect: non-scaling-stroke;

  @include extraLd {
    stroke-width: $brandSVGStrokeWidth * 2.4;
  }
}

@mixin stageForce3D() {
  backface-visibility: hidden;
  perspective: 1000;
}

.stage {
  $block: &;

  //@include fullScreenFlexContainer();

  @at-root {
    &__dynamics {
      @include fullScreen169();
    }

    &__cards {
      //@include fullScreenFlexContainer();
      display: inline-block;
      //text-align: center;
    }
  }
}

// Dancing ants
.c-dancing-ants {
  $block: &;

  visibility: hidden;
  position: absolute;
  inset: 0;
  pointer-events: none;

  @at-root {
    &__svg {
      width: 100%;
      height: 100%;
    }

    &__ant {
      $totalOffset: -10;
      $duration: 1s;

      @keyframes antDance {
        0% {
          stroke-dashoffset: 0;
        }

        100% {
          stroke-dashoffset: $totalOffset;
        }
      }

      fill: none;
      vector-effect: non-scaling-stroke;

      stroke-linejoin: round;
      stroke-linecap: round;
      stroke-dashoffset: 0;

      stroke-opacity: 0.6;
      stroke-width: 3;
      // opacity: 0.75;

      &.is-dancing {
        // animation: antDance $duration linear infinite;
      }
    }
  }
}

// Tiny bois

.c-tiny-boi {
  $block: &;

  visibility: hidden;
  position: absolute;
  width: 0.5%;

  &__center {
    display: block;
    background-color: $brandWhite;
    border-radius: 50%;
    padding-top: 100%;
    filter: blur($centerBlur);
  }
}

// Hotspots

.c-hotspot {
  $block: &;

  visibility: hidden;
  position: absolute;
  width: 8%;

  @at-root {
    &__circle {
      @keyframes hotspotPulse {
        6% {
          opacity: 1;
          transform: scale(1);
        }

        10%,
        100% {
          opacity: 0;
          transform: scale(1);
        }
      }

      @keyframes hotspotCircles {
        100% {
          transform: rotate(360deg);
        }
      }

      width: 100%;
      padding-top: 100%;
      transform-origin: center;

      &-svg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        overflow: visible;
      }

      &-target,
      &-secondary,
      &-primary,
      &-outer {
        fill: none;
        stroke: $brandWhite;
        vector-effect: non-scaling-stroke;
        transform-origin: center;
      }

      &-target,
      &-outer {
        stroke-width: 2;
      }

      &-background {
        fill: $brandWhiteTransparent;
        transition: fill 0.5s;

        #{$block}.is-active & {
          fill: $brandWhiteTransparent;
        }
      }

      &-pulse {
        fill: rgba($brandWhite, 0.125);
        opacity: 0;
        transform: scale(0);
        transform-origin: center;

        #{$block}.is-active & {
          //animation: hotspotPulse 10s 1s infinite;
        }
      }

      &-center {
        fill: $brandWhite;
        filter: blur($centerBlur);
      }

      &-secondary,
      &-primary {
        stroke-width: 4;
        transform-origin: center;
        //animation-name: hotspotCircles;
        //animation-timing-function: linear;
        //animation-iteration-count: infinite;
      }

      &-secondary {
        //animation-duration: 55s;
      }

      &-primary {
        //animation-duration: 50s;
        //animation-direction: reverse;
      }
    }
  }
}

// The content box
// You've probably seen this before

.c-box {
  $block: &;

  $collapsedWidthProp: --collapsed-width;
  $expandedWidthProp: --expanded-width;

  $width: 16.25rem;
  // $outerOffset: 0.875rem;
  // $outerSmallOffset: 0.625rem;
  // $outerBigOffset: 1.75rem;
  $bevelOffset: 2rem;
  $paddingMain: 1.5rem;
  $paddingBevel: 2.75rem;

  $backdropBrightness: 72.5%;

  $contentStdMargin: calc($paddingMain / 2);
  $contentSmMargin: 0.75rem;
  $contentStdSmMargin: 0.625rem;

  display: inline-block;
  visibility: hidden;
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  width: $width;
  #{$collapsedWidthProp}: $width;
  #{$expandedWidthProp}: 32.25rem;

  @include md {
    $width: 15.93rem;
    width: $width;
    #{$collapsedWidthProp}: $width;
  }

  @include sm {
    $width: 13.125rem;
    width: $width;
    #{$collapsedWidthProp}: $width;
  }

  @include extraLd {
    $width: 32.5rem;
    width: $width;
    #{$collapsedWidthProp}: $width;
    #{$expandedWidthProp}: 64.5rem;
  }

  // All SVGs and SVG lines
  // vector-effect forces the stroke to be in pixel space

  svg:not(:root) {
    pointer-events: none;
    overflow: visible;
  }

  line {
    @include stageSVGStroke();
  }

  @at-root {
    // States

    &.is-expanded {
      z-index: 11;
    }

    // Theming

    &--size-sm {
      $width: 16.25rem;
      width: $width;
      #{$collapsedWidthProp}: $width;

      @include md {
        $width: 15.94rem;
        width: $width;
        #{$collapsedWidthProp}: $width;
      }

      @include sm {
        $width: 13.125em;
        width: $width;
        #{$collapsedWidthProp}: $width;
      }

      @include extraLd {
        $width: 32.5rem;
        width: $width;
        #{$collapsedWidthProp}: $width;
      }
    }

    &--size-lg {

      $width: 25.625rem;
      width: $width;
      #{$collapsedWidthProp}: $width;

      &.xl {
        $width: 35rem;
        width: $width;
        #{$collapsedWidthProp}: $width;

        @include md {
          $width: 28.4375rem;
          width: $width;
          #{$collapsedWidthProp}: $width;
        }

        @include sm {
          $width: 23.125rem;
          width: $width;
          #{$collapsedWidthProp}: $width;
        }

        @include extraLd {
          $width: 68rem;
          width: $width;
          #{$collapsedWidthProp}: $width;
        }
      }

      &.xxl {
        @include md {
          $width: 33.75rem;
          width: $width;
          #{$collapsedWidthProp}: $width;
        }

        @include sm {
          $width: 28.125rem;
          width: $width;
          #{$collapsedWidthProp}: $width;
        }
      }

      @include sm {
        $width: 18.125rem;
        width: $width;
        #{$collapsedWidthProp}: $width;
      }

      @include md {
        $width: 21.875rem;
        width: $width;
        #{$collapsedWidthProp}: $width;
      }

      @include extraLd {
        $width: 51.25rem;
        width: $width;
        #{$collapsedWidthProp}: $width;
      }


    }

    &--size-xl {
      $width: 25.625rem;
      width: $width;
      #{$collapsedWidthProp}: $width;

      @include md {
        $width: 20.875rem;
        width: $width;
        #{$collapsedWidthProp}: $width;
      }
    }

    &.is-expanded {
      #{$block}__background {
        background-color: $brandDarkBlue;
      }
    }

    &__background {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $brandDarkBlueTransparent;
      clip-path: polygon(0 0,
        0 0,
        0 0,
        100% 0,
        100% calc(100% - #{$bevelOffset}),
        calc(100% - #{$bevelOffset}) 100%,
        0 100%,
        0 0);

      #{$block}--color-white & {
        background-color: $brandWhiteGreyTransparent;
        backdrop-filter: brightness($backdropBrightness) blur($brandBGBlur);
      }

      #{$block}--color-black & {
        background-color: $brandBlack;
      }
    }

    &__expand-toggle {
      @include reset-button();

      $offset: $paddingMain * 0.6;
      $size: $paddingBevel;

      cursor: pointer;
      position: absolute;
      z-index: 1;
      top: 0.75rem;
      right: 0.75rem;
      // width: $size;
      // height: $size;
      // padding: $offset;

      width: 1.5rem;
      height: 1.5rem;
      padding: 0.25rem;

      @include extraLd {
        width: 3.5rem;
        height: 3.5rem;
        padding: 0.5rem;
        top: 1.5rem;
        right: 1.5rem;

      }

      line {
        stroke-width: 1.125;


      }

      &-icon {
        line {
          transform-origin: center;

          @include extraLd {
            stroke-width: 3.125;
          }
        }

        #{$block}.is-expanded & {
          line:nth-child(2) {
            transform: translate(-45%, -55%) rotate(90deg);
          }

          line:nth-child(3) {
            transform: translate(-10%, -55%) rotate(90deg);
          }

          line:nth-child(5) {
            transform: translate(-55%, -45%) rotate(-90deg);
          }

          line:nth-child(6) {
            transform: translate(-55%, -10%) rotate(-90deg);
          }
        }
      }
    }

    &__content {
      @include keepChildrenInLine();

      @include sm {
        font-size: 0.75rem;
      }

      @include extraLd {
        font-size: 1.88rem;
      }

      padding: 0;
      font-size: 0.9375rem;
      line-height: 1.5;
      color: $brandWhite;

      #{$block}--size-lg & {
        $paddingSmall: 1.355rem;
        color: $brandMidBlue;
        padding: $paddingMain $paddingMain $paddingSmall $paddingSmall;
        overflow: hidden;

        @include extraLd {
          padding: $paddingMain * 2 $paddingMain * 2 $paddingSmall * 2 $paddingSmall * 2;
        }
      }

      #{$block}--size-sm & {
        $paddingSmall: 1rem;
        $paddingRight: 1.4rem;
        padding: $paddingSmall $paddingRight $paddingSmall $paddingSmall;

        @include sm {
          $paddingRight: 1.2rem;
          padding: $paddingSmall $paddingRight $paddingSmall $paddingSmall;
        }

        @include extraLd {
          padding: $paddingSmall * 2 $paddingSmall * 5.5 $paddingSmall * 1.85 $paddingSmall * 2;
        }

        h3 {
          font-weight: 700;
        }
      }

      // Headings

      h3 {
        font-size: 0.935rem;
        font-weight: 900;
        line-height: 1.5;
        text-transform: uppercase;

        @include sm {
          font-size: 0.75rem;
        }

        @include extraLd {
          font-size: 1.875rem;
        }
      }

      // Text & elements

      p {

        @include sm {
          margin: $contentStdSmMargin 0 0 0;
        }
      }


      ul,
      ol {
        margin: $contentStdMargin 0;


      }

      ul,
      ol {
        @include keepChildrenInLine();

        padding-left: 0.5rem;
        margin-left: 0;
      }

      li {
        margin-bottom: $contentSmMargin;
      }
    }

    &__header {
      @include keepChildrenInLine();

      margin-bottom: $contentStdMargin;

      #{$block}.is-expandable & {
        padding-right: calc(0.875rem * 2);
        cursor: pointer;
      }

      #{$block}.is-expandable:not(.is-expanded) & {
        margin-bottom: 0;
      }
    }

    &__body {
      $maxAnimateables: 8;
      $animateablesDelay: 0.35s;
      $animateablesStagger: 0.125s;

      @include keepChildrenInLine();

      pointer-events: none;

      #{$block}.is-expandable & {
        pointer-events: auto;
        width: calc(var(#{$expandedWidthProp}) - #{$paddingMain} * 2);
        height: 0;
        overflow: hidden;

        * {
          opacity: 0;
          transform: translate(0, 0.75rem);

          transition: opacity 0.2s 0s, transform 0.2s 0s;
        }
      }

      #{$block}.is-expandable.is-expanded & {
        height: auto;
        overflow: visible;
        margin-top: 1rem;

        * {
          opacity: 1;
          transform: translate(0, 0);
        }



        & ul {
          list-style: none;
          padding: 0;
          display: table;

          li {
            display: table-row;

            &::before {
              content: url("../icons/bullet.png");
              position: relative;
              top: -0.125rem;
              display: table-cell;
              text-align: right;
              padding-right: 0.4753em;
              padding-top: 0.75rem;

              @include extraLd {
                zoom: 2;
                line-height: 0.75;
                padding: 0.375rem 0.335rem 0 0;
              }
            }
          }
        }

        @for $a from 1 through $maxAnimateables {
          $aDelay: calc($animateablesDelay + ($a - 1) * $animateablesStagger);

          > :nth-child(#{$a}) {
            transition-delay: $aDelay;

            //&::marker {
            //  display: inline-block;
            //  vertical-align: center;
            //}

            @for $b from 1 through $maxAnimateables {
              > :nth-child(#{$b}) {
                transition-delay: $aDelay + ($b - 1) * $animateablesStagger;
              }
            }
          }
        }
      }
    }

    &__inner-line,
    &__outer-line,
    &__connector-line {
      position: absolute;
      z-index: 1;
    }

    // The inner line
    // Plain 'ol transforms are used to offset shortcomings of top-left SVG origin

    &__inner-line {
      top: 0;
      width: 100%;
      height: 100%;

      line:nth-child(2) {
        transform: translate(0, -#{$bevelOffset});
      }

      line:nth-child(3) {
        transform: translate(calc(100% - #{$bevelOffset}), calc(100% - #{$bevelOffset}));
      }

      line:nth-child(4) {
        transform: translate(-#{$bevelOffset}, 0);
      }
    }

    // The outer line

    &__outer-line {
      top: 0;
      // left: -$outerOffset;
      width: calc(100% - #{$bevelOffset});
      height: 100%;
      // width: calc(100% + #{$outerOffset} - #{$bevelOffset});
      // height: calc(100% + #{$outerOffset});

      // @include sm {
      // left: -$outerSmallOffset;
      // width: calc(100% - #{$bevelOffset});
      // height: 100%;

      // width: calc(100% + #{$outerSmallOffset} - #{$bevelOffset});
      // height: calc(100% + #{$outerSmallOffset});
      // }

      // @include extraLd {
      // left: -$outerBigOffset;
      // width: calc(100% + #{$outerBigOffset} - #{$bevelOffset});
      // height: calc(100% + #{$outerBigOffset});
      // }

      #{$block}--corner-top-left &,
      #{$block}--corner-top-right & {
        top: 0;
        // top: -$outerOffset;
        width: 100%;
        // width: calc(100% + #{$outerOffset});

        // @include sm {
        //   top: -$outerSmallOffset;
        //   width: calc(100% + #{$outerSmallOffset});
        // }

        // @include extraLd {
        //   // top: -$outerBigOffset;
        //   width: calc(100% + #{$outerBigOffset});
        // }
      }

      #{$block}--corner-top-right & {
        left: 0;
        height: 100%;
        // height: calc(100% - #{$outerOffset});

        // @include sm {
        //   height: calc(100% - #{$outerSmallOffset});
        // }

        // @include extraLd {
        //   height: calc(100% - #{$outerBigOffset});
        // }
      }
    }

    // The connector line
    // Eventual width and height determined by JS

    &__connector-line {
      @include stageForce3D();

      z-index: -1;
      min-width: 0.0625rem;
      width: 0;
      min-height: 0.0625rem;
      height: 0;

      top: 0;

      &-fixed {
        // The default corner
        top: 100%;
        right: 100%;
        // top: calc(100% + #{$outerOffset});
        // right: calc(100% + #{$outerOffset});

        // @include sm {
        // top: calc(100% + #{$outerSmallOffset});
        // right: calc(100% + #{$outerSmallOffset});
        // }

        // @include extraLd {
        //   top: calc(100% + #{$outerBigOffset});
        //   right: calc(100% + #{$outerBigOffset});
        // }

        #{$block}--direction-top-left &,
        #{$block}--direction-top-right & {
          top: auto;
          bottom: 0;
          // bottom: -$outerOffset;

          // @include sm {
          //   bottom: -$outerSmallOffset;
          // }

          // @include extraLd {
          //   bottom: -$outerBigOffset;
          // }
        }

        #{$block}--direction-top-right &,
        #{$block}--direction-bottom-right & {
          right: auto;
          left: 0;
          // left: -$outerOffset;

          // @include sm {
          // left: -$outerSmallOffset;
          // }

          // @include extraLd {
          // left: -$outerBigOffset;
          // }
        }

        #{$block}--corner-top-left#{$block}--direction-top-left &,
        #{$block}--corner-top-left#{$block}--direction-top-right & {
          top: auto;
          bottom: 100%;
          // bottom: calc(100% + #{$outerOffset});

          // @include sm {
          //   bottom: calc(100% + #{$outerSmallOffset});
          // }

          // @include extraLd {
          //   bottom: calc(100% + #{$outerBigOffset});
          // }
        }

        #{$block}--corner-top-right#{$block}--direction-top-left &,
        #{$block}--corner-top-right#{$block}--direction-top-right & {
          top: auto;
          bottom: 100%;
          // bottom: calc(100% + #{$outerOffset});

          // @include sm {
          //   bottom: calc(100% + #{$outerSmallOffset});
          // }

          // @include extraLd {
          //   bottom: calc(100% + #{$outerBigOffset});
          // }
        }

        #{$block}--corner-top-right#{$block}--direction-top-left & {
          // right: -$outerOffset;
          right: 0;

          // @include extraLd {
          //   right: -$outerBigOffset;
          // }

          // @include sm {
          //   right: -$outerSmallOffset;
          // }
        }

        #{$block}--corner-top-right#{$block}--direction-top-right &,
        #{$block}--corner-top-right#{$block}--direction-bottom-right & {
          right: auto;
          left: 100%;
          // left: calc(100% + #{$outerOffset});

          // @include sm {
          //   left: calc(100% + #{$outerSmallOffset});
          // }

          // @include extraLd {
          //   left: calc(100% + #{$outerBigOffset});
          // }
        }

        #{$block}--corner-bottom-right#{$block}--direction-top-right &,
        #{$block}--corner-bottom-right#{$block}--direction-bottom-right & {
          right: auto;
          left: calc(100% - #{$bevelOffset});
        }

        // Top left corner
        #{$block}--corner-top-left & {
          //   top: -$outerOffset;
          top: 0;

          //   @include sm {
          //     top: -$outerSmallOffset;
          //   }

          //   @include extraLd {
          //     top: -$outerBigOffset;
          //   }
        }

        // Top right corner
        #{$block}--corner-top-right & {

          top: 0;
          right: 0;
          // top: -$outerOffset;
          // right: -$outerOffset;

          // @include sm {
          // top: -$outerSmallOffset;
          // right: -$outerSmallOffset;
          // }

          // @include extraLd {
          // top: -$outerBigOffset;
          // right: -$outerBigOffset;
          // }
        }

        // Bottom right corner
        #{$block}--corner-bottom-right & {
          right: $bevelOffset;
        }
      }
    }
  }
}

// Location markers
// These are the fancy bois that show off the location

.c-location-marker {
  $block: &;

  display: flex;


  @at-root {
    &__marker-container {
      display: flex;
      flex-direction: column;
      flex-shrink: 0;
      -webkit-flex-shrink: 0;
      /* Safari */
      padding: 0.025rem 0 0.27rem 0;
      margin-right: 0.4rem;

      @include extraLd {
        margin-right: 1rem;

        svg {
          width: 3.125rem;
          height: auto;
        }
      }

      &::before,
      &::after {
        content: '';
        display: block;
        -webkit-flex-grow: 1;
        /* Safari */
        flex-grow: 1;
        background-color: currentColor;
      }

      &::before {
        margin-bottom: -0.0625rem;

        @include extraLd {
          margin-bottom: -0.1875rem;
        }
      }

      &::after {
        margin-top: -0.0625rem;

        @include extraLd {
          margin-top: -0.1875rem;
        }
      }
    }

    &__marker {
      width: 100%;

      path {
        fill: currentColor;
      }
    }

    &__location {
      flex-grow: 1;
      line-height: 1;
      text-transform: uppercase;
      margin: 0;

      &-label,
      &-name {
        display: block;
      }

      &-label {
        font-size: 0.75rem;
        font-weight: 700;
        margin-bottom: 0.3rem;

        @include sm {
          font-size: 0.75rem;
        }

        @include extraLd {
          font-size: 1.5rem;
        }
      }

      &-name {
        font-size: 1.75rem;
        font-weight: 900;
        line-height: 0.875;
        padding-right: 0.5rem;

        @include sm {
          font-size: 1.25rem;
          line-height: 1;
        }

        @include extraLd {
          font-size: 3.5rem;
          line-height: 1;
          padding-right: 1rem;

        }
      }
    }
  }
}

// Video launchers
// These launch videos

.c-video-launcher__container {
  visibility: hidden;
}

.c-video-launcher {
  $block: &;

  $padding: 1rem;

  cursor: pointer;
  position: relative;
  width: 100%;
  padding: 0.75rem 0.75rem 0.75rem 1rem;
  margin: 0;
  display: flex;
  flex-direction: column;

  clip-path: polygon(0 0,
    0 0,
    0 0,
    100% 0,
    100% calc(100% - #{$bevelOffset}),
    calc(100% - #{$bevelOffset}) 100%,
    0 100%,
    0 0);

  height: 100%;

  @include extraLd {
    padding: 1.5rem 1.5rem 1.25rem 2rem;
  }


  &__info {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
  }


  @at-root {

    &__img {
      position: absolute;

    }

    &__img {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    &__play-button {
      // $size: 4rem;

      &-icon {
        // $size: 1.5rem;
        position: relative;


        & svg {
          @include extraLd {
            width: 4rem;
            height: 4rem;
          }
        }



        path {
          transform-origin: center;
          transition: transform 0.2s;

          #{$block}:hover & {
            transform: scale(0.9);
          }
        }
      }
    }

    &__title,
    h3#{&}__title {
      font-family: Hexagon, Helvetica, sans-serif;
      top: $padding;
      left: $padding;
      right: $padding;
      font-size: 0.9375rem;
      margin: 0.25rem 1rem 0 0;
      font-weight: 700;
      line-height: 1.5;
      z-index: 2;

      @include sm {
        $padding: 0.875rem;
        top: $padding;
        left: $padding;
        right: $padding;
        font-size: 0.75rem;
      }

      @include extraLd {
        top: $padding * 2.4;
        left: $padding * 2.4;
        right: $padding * 2.6;
        font-size: 1.875rem;
      }
    }
  }
}

// UI
// ---------------------------------------------------------- //

// Some props are shared between UI elements

$uiCardPadding: 1.5rem;
$uiCardMarginTop: -$uiCardPadding;

// Any by extension, some mixins

@mixin uiCard($marginTop: $uiCardMarginTop) {
  min-width: 20rem;
  max-width: 30rem;
  margin-top: $marginTop;
}

// Makes something look like a UI card title
@mixin uiCardTitle($border: true, $margin: $uiCardPadding * 0.75) {
  line-height: 1;
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: $margin;

  @if $border {
    padding-bottom: calc($uiCardPadding / 2);
    border-bottom: 0.0625rem solid $brandWhite;
  }
}

// Makes the button group float outside of the UI card
@mixin uiCardButtonGroup($offset: $uiCardPadding * 2.5, $padding: $uiCardPadding) {
  position: absolute;
  top: calc(100% + #{$offset});
  width: calc(100% - #{$padding} * 2);
}

// General things

// Mostly all links look the same
a {
  font-size: inherit;
  font-weight: 400;
  text-transform: uppercase;
  color: inherit;
}

// Buttons
// These are gonne be fancy af

.c-button-group {
  display: flex;
  width: 100%;
  justify-content: center;
  flex-wrap: wrap;
}

.c-button {
  $block: &;
  $paddingX: 5.5rem;
  $paddingY: 1rem;
  $animationDuration: 0.2s;

  @include reset-button();
  // @include brandBGBlur();

  cursor: pointer;
  position: relative;
  z-index: 0;
  background-color: $brandDarkBlueTransparent;
  font-family: Hexagon, Helvetica, sans-serif;
  font-size: 1rem;
  font-weight: 900;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  color: $brandWhite;
  transform: none;

  transition: transform $animationDuration;

  @include extraLd {
    font-size: 2rem;
  }

  &:hover {
    transform: scale(1.0125) translate(0, -0.125rem);
  }

  &:active {
    transform: scale(1) translate(0, 0);
  }

  &.c-button--with-border {
    border: 1px solid rgba(255, 255, 255, 0.5);

    .c-button__text {
      font-family: Arial, serif;
      font-style: normal;
      font-weight: 700;
      font-size: 0.75rem;
      line-height: 150%;
      text-transform: uppercase;
      color: #FFFFFF;
    }
  }

  &.c-button--link {
    .c-button__text {
      font-family: Hexagon, Helvetica, sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      text-transform: none;
      text-decoration-line: underline;
      color: #89C0D1;
    }
  }

  svg {
    display: block;
    position: relative;
    z-index: 0;
    overflow: visible;

    &::after {
      content: '';
      display: block;
      position: absolute;
      inset: 0;
    }
  }

  line,
  polyline,
  polygon,
  circle,
  ellipse,
  path {
    @include stageSVGStroke(currentColor);
  }

  @at-root {
    &__text {
      position: relative;
      padding: $paddingY $paddingX;
      overflow: hidden;

      @include extraLd {
        padding: $paddingY * 2 13.125rem;
      }

      &::before,
      &::after {
        content: '';
        display: block;
        position: absolute;
        z-index: -1;
        top: 50%;
        left: 0;
        width: 100%;
        padding-top: 100%;
        background-color: rgba($brandWhite, 0.05);
        border-radius: 50%;
        opacity: 0;
        transform: scale(0) translate(0, -50%);

        transition: border-radius $animationDuration, opacity $animationDuration,
          transform $animationDuration;
      }

      #{$block}--size-sm & {
        padding: $paddingY;
      }

      #{$block}:hover &::before,
      #{$block}:active &::after {
        border-radius: 0;
        opacity: 1;
        transform: scale(1) translate(0, -50%);
      }
    }

    &__corner {
      $size: 0.85rem;
      $cornerOffset: 0.55rem;
      $cornerDuration: $animationDuration * 2;

      position: absolute;
      width: $size;
      height: $size;
      border-width: $brandStrokeWidth;
      border-color: $brandWhite;

      transition: width $cornerDuration, height $cornerDuration, transform $cornerDuration;

      @include extraLd {
        $size: 1.625rem;
        width: $size;
        height: $size;
        border-width: $brandStrokeWidth * 2;
      }

      #{$block}:hover & {
        $hoverSize: calc(50% + #{$size});

        width: $hoverSize;
        height: $hoverSize;
      }

      &:nth-child(2) {
        top: 0;
        left: 0;
        border-top-style: solid;
        border-left-style: solid;
        transform: translate(-$cornerOffset, -$cornerOffset);

        @include extraLd {
          transform: translate(-$cornerOffset * 2, -$cornerOffset * 2);
        }
      }

      &:nth-child(3) {
        top: 0;
        right: 0;
        border-top-style: solid;
        border-right-style: solid;
        transform: translate($cornerOffset, -$cornerOffset);

        @include extraLd {
          transform: translate($cornerOffset * 2, -$cornerOffset * 2);
        }
      }

      &:nth-child(4) {
        right: 0;
        bottom: 0;
        border-right-style: solid;
        border-bottom-style: solid;
        transform: translate($cornerOffset, $cornerOffset);

        @include extraLd {
          transform: translate($cornerOffset * 2, $cornerOffset * 2);
        }
      }

      &:nth-child(5) {
        bottom: 0;
        left: 0;
        border-bottom-style: solid;
        border-left-style: solid;
        transform: translate(-$cornerOffset, $cornerOffset);

        @include extraLd {
          transform: translate(-$cornerOffset * 2, $cornerOffset * 2);
        }
      }
    }
  }
}

.stage-card__button-cancel {
  display: flex;
  flex: 0 0 100%;
  color: $brandBlueGreen;
  justify-content: center;
  align-items: center;
  margin-top: 1.5625rem;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

// Title card
// This will eventually be inside of a nice content box

.title-card {
  $block: &;

  $paddingX: $uiCardPadding * 1.5;
  $paddingY: $uiCardPadding * 2.5;

  @include uiCard($paddingY * 2);

  //display: block;
  position: absolute;
  width: 60%;
  max-width: 30rem;
  top: 0 !important;
  left: 50% !important;
  transform: translateX(-50%);

  @at-root {
    &__content {
      @include keepChildrenInLine($last: 2);

      padding: 2.5rem 3.725rem;
    }

    &__logo-container {
      margin-bottom: $uiCardPadding;
    }

    &__logo {
      display: block;
      width: 15.8rem;
    }

    &__title {
      @include uiCardTitle(false, calc($uiCardPadding / 2));

      font-size: 4.25rem;
      text-transform: uppercase;

      &_margin {
        margin-top: 1.875rem;
      }

      &__thicc,
      &__thin {
        display: block;
      }

      &-thicc {
        font-style: normal;
        font-weight: bold;
        font-size: 3.125rem;
        line-height: 3.125rem;
      }

      &-thin {
        font-style: normal;
        font-weight: normal;
        font-size: 3.125rem;
        line-height: 3.125rem;

        &_margin {
          margin-top: 0.75rem;
        }
      }
    }

    &__tagline {
      font-style: normal;
      font-weight: normal;
      font-size: 1.125rem;
      line-height: 1.5;
    }

    &__buttons {
      position: relative;
      width: 100%;

      &_margin {
        margin-top: 1.875rem;
      }

      .c-button {
        background-color: #89c0d1;
        color: #002c40;
        width: 100%;

        &__text {
          width: 100%;
          padding: 1rem 0;
        }
      }
    }

    .c-box__content p {
      margin-top: 0.625rem;
      margin-bottom: 0;


    }
  }
}

// Pick your path
// This will eventually be inside of a nice content box

.paths {
  $block: &;

  $offset: 3rem;
  $paddingBottom: 3.25rem;
  $tabWidth: 3rem;
  $openDuration: 0.3s;

  @include uiCard(0);

  display: block;
  position: fixed;
  top: 50% !important;
  left: 0;
  width: 16.66666666%;
  transform: translateX(calc(-100% + #{$tabWidth})) translateY(calc(-50% + 3.75rem));
  transition: transform $openDuration ease-out;

  @include extraLd {
    transform: translateX(calc(-100% + #{$tabWidth * 2})) translateY(calc(-50% + 3.75rem));
    width: 42.1875rem;
    max-width: 42.1875rem;
  }

  @at-root {
    // When open, we add this class

    &.is-open {
      transform: translateX($offset) translateY(calc(-50% + 3.75rem));

    }


    &__content {
      padding: $uiCardPadding;

      @include extraLd {
        padding: $uiCardPadding * 2;
      }
    }

    &__tab {
      @include reset-button();
      font-family: Arial, serif;

      cursor: pointer;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 0;
      right: 0;
      width: $tabWidth;
      height: 100%;
      font-size: 0.875rem;
      font-weight: 700;
      text-transform: uppercase;
      transform: translate(0, 0);

      @include extraLd {
        width: $tabWidth * 2;
        font-size: 1.75rem;
      }

      &-title {
        transform: rotate(-90deg);
      }
    }

    &__header,
    &__nav {
      position: relative;
      left: -$tabWidth;
      transition: left 0.5s;

      margin-bottom: 1rem;

      @include extraLd {
        margin-bottom: 2rem;
      }

      #{$block}.is-open & {
        left: 0;
      }
    }

    &__header {
      margin-bottom: $uiCardPadding;

      @include extraLd {
        margin-bottom: 2rem;
      }

      :last-child {
        margin-bottom: 0;
      }

      .c-arrow {
        svg {
          path {
            fill: #ffffff;
          }
        }
      }
    }

    &__title {
      @include uiCardTitle();

      display: flex;
      justify-content: space-between;
      align-items: center;

      @include extraLd {
        padding-bottom: 1.625rem;
      }

      &-name {
        margin: 0 $uiCardPadding 0 0;
      }

      &-arrow {
        margin-top: -0.25rem;
      }
    }

    &__nav {
      $nav: &;

      $linkFontSize: 1rem;
      $linkLineHeight: 1;

      $linkPadding: 0.5rem;
      $numberMargin: 0.5rem;

      &-link {
        display: flex;
        min-height: calc((#{$linkFontSize} * #{$linkLineHeight}) + (#{$linkPadding} * 2));
        align-items: center;
        margin-bottom: 1rem;

        @include extraLd {
          margin-bottom: 2rem;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }

      &-item {
        display: flex;
        align-items: center;
        padding: $linkPadding $linkPadding $linkPadding 0;
        font-size: $linkFontSize;
        line-height: $linkLineHeight;
        text-decoration: none;

        transition: font-size 0.2s $openDuration;

        @include extraLd {
          font-size: $linkFontSize * 2;
          padding: $linkPadding * 2 $linkPadding * 2 $linkPadding * 2 0;
        }

        &.disabled-link {
          cursor: not-allowed;
          pointer-events: all !important;
          color: $brandWhiteMiddleTransparent;
        }

        &.router-link-active {
          font-size: 1.66666666rem;
          line-height: $linkLineHeight;


          &.path-2d-active {
            font-size: $linkFontSize;
            line-height: $linkLineHeight;
            letter-spacing: 0.035em;
            color: $brandLightBlue;

            &:last-child {
              padding: $linkPadding $linkPadding $linkPadding 0;
            }

            @include extraLd {
              font-size: $linkFontSize * 2;
              padding: $linkPadding * 2 $linkPadding * 2 $linkPadding * 2 0;
            }
          }

          @include extraLd {
            font-size: $linkFontSize * 2.5;
          }

          &:last-child {
            padding-bottom: 0;
          }
        }
      }

      &-number {
        font-weight: 400;
        width: 2ch;
        margin-right: $numberMargin;
      }

      &-name {
        @include littleAnnoyingDividerLine();

        font-weight: 700;
        text-transform: uppercase;
        padding-left: $numberMargin;

        transition: font-weight 0.2s $openDuration, letter-spacing 0.2s, color 0.2s;

        #{$nav}-item:hover &,
        #{$nav}-item:focus & {
          letter-spacing: 0.035em;
          color: $brandLightBlue;
        }

        #{$nav}-item.router-link-active {
          font-weight: 900;
        }
      }
    }

    &__footer {
      //position: absolute;
      //bottom: 1rem;
      display: flex;
      justify-content: center;
      gap: 3.25rem;

      @include extraLd {
        .icon-left svg {
          width: 3rem;
          height: 3rem;
        }
      }

      .site-map {
        &_link {
          font-family: Hexagon, Helvetica, sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 0.875rem;
          line-height: 1.5;
          color: #89c0d1;

          @include extraLd {
            font-size: 1.75rem;
          }

          &__margin {
            margin-left: 0.813rem;
          }
        }
      }

      a {
        text-transform: none;
      }
    }
  }
}
// Pick your path
// This will eventually be inside of a nice content box

.paths-new {
  $block: &;

  $offset: 3rem;
  $paddingBottom: 3.25rem;
  $tabWidth: 3rem;
  $openDuration: 0.3s;

  @include uiCard(0);
  min-width: var(--base-menu-min-width);
  max-width: var(--base-menu-max-width);
  width: var(--base-menu-width);

  // --paths-new-position-left: calc(var(--base-sidebar-width) - 0.25rem);

  display: none;
  position: fixed;
  // top: calc(50% - calc(10.51rem - 1px)) !important;
  top: calc(var(--base-sidebar-position-top) + 0.0625rem) !important;
  // left: calc(2.75rem + 2px) !important;
  left: calc(var(--base-sidebar-width) - 0.0625rem) !important;
  transition: transform $openDuration ease-out;

  @include extraLd {
    width: 42.1875rem;
    max-width: 42.1875rem;
    // left: calc(5.5rem + 2px) !important;
  }

  .c-box__background {
    background: rgba(2, 73, 105, 0.92);
  }

  @at-root {
    // When open, we add this class

    &.is-open {
      display: block;
      // transform: translateX($offset) translateY(calc(-50% + 3.75rem));

    }


    &__content {
      padding: 0.8125rem $uiCardPadding;

      @include md {
        padding: 1.0625rem 1.9rem;
      }

      @include ld {
        padding: 1.625rem 2.6rem;
      }

      @include extraLd {
        padding: 3.4688rem 5rem;
      }
    }

    &__tab {
      @include reset-button();
      font-family: Arial, serif;

      cursor: pointer;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 0;
      right: 0;
      width: $tabWidth;
      height: 100%;
      font-size: 0.875rem;
      font-weight: 700;
      text-transform: uppercase;
      transform: translate(0, 0);

      @include extraLd {
        width: $tabWidth * 2;
        font-size: 1.75rem;
      }

      &-title {
        transform: rotate(-90deg);
      }
    }

    &__header,
    &__nav {
      position: relative;
      left: -$tabWidth;
      transition: left 0.5s;

      margin-bottom: 1rem;

      @include extraLd {
        margin-bottom: 2rem;
      }

      #{$block}.is-open & {
        left: 0;
      }
    }

    &__header {
      margin-bottom: 0.875rem;
      padding-bottom: 0.75rem;
      border-bottom: 1px solid #fff;

      @include md {
        padding-bottom: 1rem;
      }

      @include ld {
        padding-bottom: 1.5625rem;
      }

      @include extraLd {
        margin-bottom: 2rem;
      }

      :last-child {
        margin-bottom: 0;
      }

      .c-arrow {
        svg {
          path {
            fill: #ffffff;
          }

          @include ld {
            width: 1.125rem;
            height: 1.125rem;
          }

          @include extraLd {
            width: 1.75rem;
            height: 1.75rem;
          }
        }
      }
    }

    &__title {
      @include uiCardTitle(false);

      display: flex;
      justify-content: space-between;
      align-items: center;

      @include extraLd {
        padding-bottom: 1.625rem;
      }

      &-name {
        margin: 0 $uiCardPadding 0 0;
      }

      &-arrow {
        margin-top: -0.25rem;
      }
    }

    &__nav {
      $nav: &;

      // $linkFontSize: 12px;
      --pah-new-nav-link-font-size: 0.75rem;
      $linkLineHeight: 1;

      // $linkPadding: 0.5rem;
      --pah-new-nav-link-padding: 0.5rem;
      --pah-new-nav-number-margin: 0.5rem;
      // $numberMargin: 0.5rem;

      margin-bottom: 0;

      @include md {
        --pah-new-nav-link-font-size: 0.9375rem;
        --pah-new-nav-number-margin: 0.5rem;
        --pah-new-nav-link-padding: 0.5625rem;
      }

      @include ld {
        --pah-new-nav-link-font-size: 1.375rem;
        --pah-new-nav-number-margin: 0.8562rem;
        --pah-new-nav-link-padding: 1.3125rem;
      }

      @include extraLd {
        --pah-new-nav-link-font-size: 2.625rem;
        --pah-new-nav-link-padding: 2.25rem;
        --pah-new-nav-number-margin: 1.7725rem;
      }

      &-link {
        display: flex;
        // min-height: calc((#{$linkFontSize} * #{$linkLineHeight}) + (#{$linkPadding} * 2));
        min-height: calc(var(--pah-new-nav-link-font-size) + calc(var(--pah-new-nav-link-padding) + var(--pah-new-nav-link-padding)));
        align-items: center;
        margin-bottom: 0.6563rem;

        @include extraLd {
          margin-bottom: 2rem;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }

      &-item {
        display: flex;
        align-items: center;
        padding: var(--pah-new-nav-link-padding) var(--pah-new-nav-link-padding) var(--pah-new-nav-link-padding) 0;
        font-size: var(--pah-new-nav-link-font-size);
        line-height: $linkLineHeight;
        text-decoration: none;

        transition: font-size 0.2s $openDuration;

        @include extraLd {
          // font-size: $linkFontSize * 2;
          // font-size: var(--pah-new-nav-link-font-size);
          // padding: $linkPadding * 2 $linkPadding * 2 $linkPadding * 2 0;
        }

        &.disabled-link {
          cursor: not-allowed;
          pointer-events: all !important;
          color: $brandWhiteMiddleTransparent;
        }

        &.router-link-active {
          font-size: 1rem;
          line-height: $linkLineHeight;
          padding-top: 0;


          &.path-2d-active {
            font-size: var(--pah-new-nav-link-font-size);
            line-height: $linkLineHeight;
            letter-spacing: 0.035em;
            color: $brandLightBlue;

            &:last-child {
              padding: var(--pah-new-nav-link-padding) var(--pah-new-nav-link-padding) var(--pah-new-nav-link-padding) 0;
            }

            @include extraLd {
              // font-size: $linkFontSize * 2;
              // padding: $linkPadding * 2 $linkPadding * 2 $linkPadding * 2 0;
            }
          }

          @include md {
            font-size: 1.1875rem;
            line-height: 1.2656rem;
          }

          @include ld {
            font-size: 1.875rem;
            line-height: 1.9875rem;
          }

          @include extraLd {
            font-size: 3.75rem;
            line-height: 3.9688rem;
            // --pah-new-nav-link-font-size: $linkFontSize * 2;
            // font-size: $linkFontSize * 2.5;
          }

          &:last-child {
            padding-bottom: 0;
          }
        }
      }

      &-number {
        font-weight: 400;
        width: 2ch;
        margin-right: var(--pah-new-nav-number-margin);
      }

      &-name {
        @include littleAnnoyingDividerLine();

        font-weight: 700;
        text-transform: uppercase;
        padding-left: var(--pah-new-nav-number-margin);

        transition: font-weight 0.2s $openDuration, letter-spacing 0.2s, color 0.2s;

        #{$nav}-item:hover &,
        #{$nav}-item:focus & {
          letter-spacing: 0.035em;
          color: $brandLightBlue;
        }

        #{$nav}-item.router-link-active {
          font-weight: 900;
        }
      }
    }

    &__footer {
      //position: absolute;
      //bottom: 1rem;
      display: flex;
      justify-content: space-between;
      gap: 1rem;
      margin-top: 0.5625rem;

      @include md {
        margin-top: 0.875rem;
      }

      @include ld {
        margin-top: 1rem;
      }

      @include extraLd {
        margin-top: 1rem;
      }

      .icon-left svg{
        width: 1.125rem;
        height: 1.125rem;

        @include ld {
          width: 2rem;
          height: 2rem;
        }

        @include extraLd {
          width: 4.375rem;
          height: 4.375rem;
        }
      }

      .site-map {
        &_link {
          font-family: Hexagon, Helvetica, sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 0.75rem;
          line-height: 0.9rem;
          color: #89c0d1;

          @include md {
            font-size: 0.9375rem;
            line-height: 1.125rem;
          }

          @include ld {
            font-size: 1.375rem;
            line-height: 1.65rem;
            margin-left: 1.25rem !important;
          }

          @include extraLd {
            font-size: 2.625rem;
            line-height: 3.15rem;
            margin-left: 2.5625rem !important;
          }

          &__margin {
            margin-left: 0.813rem;
          }
        }
      }

      a {
        text-transform: none;
      }
    }
  }
}

// The stage card
// This, too, will eventually be inside of a content box

.stage-card {
  $block: &;

  $paddingX: $uiCardPadding;
  $paddingY: $uiCardPadding * 2;

  @include uiCard(0);

  //display: block;
  position: absolute;
  width: 25%;
  max-width: 25rem;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%);

  @include extraLd {
    max-width: 45.3125rem;
  }

  @at-root {
    &__content {
      @include keepChildrenInLine($last: 2);

      padding: 2rem;

      @include extraLd {
        padding: 4rem;
      }
    }

    &__title {
      $numberMargin: 0.75rem;

      @include uiCardTitle();

      border-bottom: 0;
      margin-bottom: 0;
      padding-bottom: 0;

      &-block {
        display: flex;
        justify-content: space-between;
      }

      &-border {
        margin-top: 1.5rem;
        background: #ffffff;
        opacity: 0.5;
        height: 0.0625rem;

        @include extraLd {
          height: 0.125rem;
          margin-top: 3rem;
        }
      }

      &-path-number,
      &-name {
        display: block;
      }

      &-path-number {
        font-style: normal;
        font-weight: normal;
        font-size: 0.75rem;
        line-height: 0.75rem;

        @include extraLd {
          font-size: 1.5rem;
          line-height: 1.5rem;
        }
      }

      &-name {
        position: relative;

        font-style: normal;
        font-weight: 900;
        font-size: 1.375rem;
        line-height: 1.375rem;

        @include extraLd {
          font-size: 2.75rem;
          line-height: 2.75rem;
        }
      }

      &_margin {
        margin-top: 0.5rem;

        @include extraLd {
          margin-top: 1rem;
        }
      }

      &-locations {
        font-style: normal;
        color: #89c0d1;

        &-title {
          font-weight: normal;
          font-size: 0.75rem;
          line-height: 0.75rem;
          text-align: right;

          @include extraLd {
            font-size: 1.5rem;
            line-height: 1.5rem;
          }
        }

        &-number {
          display: flex;
          justify-content: flex-end;

          font-style: normal;
          font-weight: 900;
          font-size: 1.375rem;
          line-height: 1.375rem;

          @include extraLd {
            font-size: 2.75rem;
            line-height: 2.75rem;

            svg {
              width: 3rem;
              height: 3rem;
            }
          }

          &_margin {
            margin-left: 0.5rem;
          }
        }
      }
    }

    &__buttons {
      position: relative;

      &_margin {
        margin-top: 3.8125em;

        @include extraLd {
          margin-top: 4.625em;
        }
      }
    }
  }

  .c-box__content p {
    margin-top: 1.25rem;
    font-style: normal;
    font-weight: normal;
    font-size: 0.938rem;
    line-height: 1.5;

    @include extraLd {
      margin-top: 2.5rem;
      font-size: 1.875rem;
      line-height: 2.8125rem;
    }
  }

  .c-button {
    background-color: #89c0d1;
    color: #002c40;
    font-weight: 700;

    @include extraLd {
      font-size: 2rem;
    }
  }
}

// Modals

.c-video-modal {
  $block: &;

  $padding: $uiCardPadding * 1.5;

  display: flex;
  pointer-events: none;
  position: absolute;
  z-index: 20;
  inset: 0;
  align-items: center;
  justify-content: center;
  max-height: 100vh;

  &.is-open {
    pointer-events: auto;

    &::before {
      opacity: 1;
    }
  }

  &::before {
    @include brandBGBlur();

    content: '';
    display: block;
    position: absolute;
    z-index: -1;
    inset: 0;
    opacity: 0;

    transition: opacity 0.5s;
  }

  @at-root {
    &__container {
      width: 100%;
      background: $brandWhite;
      margin: 0 $padding;
      opacity: 0;
      transform: scale(0.95) translate(0, 2rem);

      transition: opacity 0.5s, transform 0.5s;
      max-height: 100%;
      height: 100%;

      #{$block}.is-open & {
        opacity: 1;
        transform: scale(1) translate(0, 0);
      }

      img,
      video {
        display: block;
        max-width: 100%;
        max-height: 100%;
        padding: $padding;
      }

      video {
        flex: 0 0 100%;
      }
    }

    &__content {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__close-button {
      $offset: $padding * 0.75;

      position: absolute;
      z-index: 1;
      top: $offset;
      right: $offset;

      &-svg {
        width: 1.25rem;

        @include extraLd {
          width: 2.5rem;
        }
      }

      &.close-top {
        top: -0.75rem;
        right: -0.75rem;
      }
    }
  }
}

.page-transition__container {
  position: absolute;
  inset: 0;
  overflow: hidden;
  pointer-events: none;

  .page-transition__layer {
    position: absolute;
    width: 100%;
    height: 100%;
    top: auto;
    left: -100%;
    bottom: auto;
    right: auto;
    transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);

    z-index: 100;

    &.active {
      left: 100%;
    }
  }

  .page-transition__layer-1 {
    background: $brandDarkishBlue;

    // Going In
    &.active {
      transition-delay: 0.14s;
      left: 0;
    }

    // going out
    transition-delay: 0.1s;
  }

  .page-transition__layer-2 {
    background: $brandLightishBlue;

    // Going In
    &.active {
      transition-delay: 0.14s;
    }

    // going out
    transition-delay: 0.1s;
  }

  .page-transition__layer-3 {
    background: $brandLightBlue;

    // Going In
    &.active {
      transition-delay: 0.07s;
    }

    // going out
    transition-delay: 0.17s;
  }

  .page-transition__layer-4 {
    background: $brandGreen;

    // Going In
    &.active {
      transition-delay: 0s;
    }

    // going out
    transition-delay: 0.19s;
  }
}

.device-rotate__blur {
  -webkit-filter: blur(0.25rem);
  -moz-filter: blur(0.25rem);
  -ms-filter: blur(0.25rem);
  filter: blur(0.25rem);
}

.splash {
  position: absolute;
  opacity: 0;
  z-index: 2;
}

.audio-player {
  display: none;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-center {
  text-align: center;
}

/* stylelint-enable */

.reset-view {
  position: relative;
  height: 100%;
  padding: 0.5rem 0;

  .c-button {
    background-color: transparent;
    box-sizing: border-box;
    border-left: 0.0625rem solid $brandWhite;
    margin-left: 1rem;
    display: flex;
    align-items: center;
    padding: 0 1rem;
    height: 100%;

    &:hover,
    &:active {
      transform: none;
    }

    @include extraLd {
      svg {
        width: 2rem;
        height: 2rem;
      }
    }

    svg path {
      fill: $brandWhite;
      stroke: none;
    }

    .c-button__text {
      padding: 0;
      margin-left: 0.5rem;
      margin-top: 0.1875rem;
      font-style: normal;
      font-size: 0.685rem;
      text-transform: uppercase;

      @include extraLd {
        font-size: 1.5rem;
        line-height: 2.25rem;
      }

      &::before {
        background-color: transparent;
      }
    }
  }

  .c-button--size-sm .c-button__text {
    padding: 0;
  }
}

.c-announce {
  margin-top: -0.125rem;

  p {
    text-transform: uppercase;
    margin: 0;
    padding: 0 0.375rem 0 0;
    text-align: right;
    font-size: 0.75rem;
    font-weight: 700;

    @include extraLd {
      font-size: 1.5rem;
    }
  }
}

.tooltip-white {
  .MuiTooltip-tooltip {
    background: #FFFEF7 !important;
    color: #002C40 !important;
    font-family: Hexagon, Helvetica, sans-serif !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 0.75rem !important;
    line-height: 0.75rem !important;
    padding: 0.75rem !important;
    margin-bottom: 0.375rem !important;
    border-radius: 0 !important;

    @include extraLd {
      font-size: 1.5rem !important;
      line-height: 1.5rem !important;
      padding: 1.5rem !important;
      max-width: 36.25rem;
    }

    ul {
      margin-block-start: 0;
      padding-inline-start: 20px;
    }

    .MuiTypography-root {
      font-family: Hexagon, Helvetica, sans-serif !important;
      color: #002C40 !important;
      font-weight: 700 !important;
      font-size: 0.75rem !important;
      line-height: 0.75rem !important;

      @include extraLd {
        font-size: 1.5rem !important;
        line-height: 1.5rem !important;
      }
    }

    .MuiTooltip-arrow {
      // width: 16px !important;
      // height: 16px !important;

      &::before {
        background-color: #FFFEF7 !important;
      }
    }
  }
}


.MuiDialog-root {
  &.c-dialog {

    &--100 {
      .c-dialog-actions {
        padding-left: 2rem;
        padding-right: 2rem;
      }

      .c-dialog-title {
        padding-left: 2rem;
        padding-right: 2rem;
      }

      .c-dialog-actions {
        padding-left: 2rem;
        padding-right: 2rem;
      }

      // .MuiPaper-root {
      //   padding: 32px;
      // }
      @include extraLd {
        .c-dialog-actions {
          padding-left: 4rem;
          padding-right: 4rem;
        }

        .c-dialog-title {
          padding-left: 4rem;
          padding-right: 4rem;
        }

        .c-dialog-actions {
          padding-left: 4rem;
          padding-right: 4rem;
        }
      }
    }

    &--50 {
      .MuiPaper-root {
        min-width: 50%;
      }
    }

    &--with-main-header {
      top: $mainNavHeight + $brandStrokeWidth !important;

      @include extraLd {
        top: $mainNavHeight * 2 + $brandStrokeWidth !important;
      }
    }

    .MuiPaper-root {
      color: #FFFFFF;
      background: #051e2f;
    }

    .c-dialog-content {
      padding: 0 2.5rem;
    }

    // .MuiDialogContent-root {
    //   border: none !important;
    // }
    .c-dialog-actions {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      gap: 1rem;
      padding-bottom: 2.25rem !important;

      @include extraLd {
        gap: 2rem;
        padding-bottom: 4.5rem !important;
      }

      &--standard {
        justify-content: flex-end;
        padding: 2.5rem 1.875rem;

        @include extraLd {
          padding: 5rem 3.75rem;
        }
      }


    }

    .c-dialog-title {
      font-family: Hexagon, Helvetica, sans-serif !important;
      display: flex;
      justify-content: space-between;
      align-items: center;

      @include extraLd {
        & .c-button svg {
          height: 2.5rem;
          width: 2.5rem;

          path {
            stroke-width: 1px;
            fill: #fff;
          }
        }
      }

      &--big {
        padding: 2.625rem 1.875rem 1.875rem 2.625rem !important;
        font-style: normal !important;
        font-weight: 900 !important;
        font-size: 1rem !important;
        line-height: 1rem !important;
        text-transform: uppercase;

        @include extraLd {
          font-size: 2rem !important;
          line-height: 2rem !important;
        }
      }

      &--normal {
        padding: 2rem 2rem 1.5rem 2.5rem !important;
        font-style: normal !important;
        font-weight: 700 !important;
        font-size: 1.375rem !important;
        line-height: 150% !important;
        text-transform: uppercase;
        align-items: flex-start;

        &>span {
          margin-top: 0.5rem;
        }


        @include extraLd {
          font-size: 2.75rem !important;
          padding: 4rem 4rem 3rem 5rem !important;

          &>span {
            margin-top: 1rem;
          }
        }
      }
    }
  }
}

.margin-right-5 {
  margin-right: 0.565rem;

  @include extraLd {
    margin-right: 0.313rem;
  }
}
.margin-left-6 {
  margin-left: 0.565rem;

  @include extraLd {
    margin-left: 1.5625rem;
  }
}


.Toastify {
  --toastify-toast-angle: 0.9375rem;
  --toastify-toast-custom-bg-color: rgba(132, 243, 150, 0.8);

  @include extraLd {
    --toastify-toast-angle: 1.875rem;
  }

  &__toast-container {
    padding: 0 !important;
    --toastify-toast-width: 27.1875rem;

    @include ld {
      --toastify-toast-width: 34.0625rem;
    }

    @include extraLd {
      --toastify-toast-width: 68.125rem;
    }

    &--top-center,
    &--top-left,
    &--top-right {
      top: $mainNavHeight + $brandStrokeWidth !important;

      @include extraLd {
        top: $mainNavHeight * 2 + $brandStrokeWidth !important;
      }
    }
  }

  &__toast {
    font-family: Hexagon, Helvetica, sans-serif !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 0.875rem !important;
    line-height: 150% !important;
    color: #FFFFFF !important;
    padding: 0 !important;
    background: none !important;
    border-radius: 0 !important;
    backdrop-filter: blur(0.25rem);
    padding-right: var(--toastify-toast-angle) !important;

    @include extraLd {
      font-size: 1.75rem !important;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      border-bottom: var(--toastify-toast-angle) solid transparent;
      border-left: var(--toastify-toast-angle) solid var(--toastify-toast-custom-bg-color);
      width: 0;
      backdrop-filter: blur(0.25rem);
    }

    &--error {
      --toastify-toast-custom-bg-color: rgba(255, 97, 97, 0.8);
    }
  }

  &__toast-body {
    padding: 0.5rem 1.5rem !important;
    margin: 0 !important;
    justify-content: flex-start !important;
    align-items: center !important;
    background: var(--toastify-toast-custom-bg-color) !important;

    @include extraLd {
      padding: 1rem 3rem !important;
    }
  }
}