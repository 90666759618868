@import 'app/assets/scss/_variables.scss';
@import 'app/assets/scss/_mixins.scss';

.journey-list-item {
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 2px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 1.875rem 0.75rem 1.563rem;
  gap: 2.625rem;
  box-sizing: border-box;
  margin-bottom: 0.75rem;

  @include extraLd {
    padding: 1.5rem 3.75rem 1.5rem 3.6875rem;
    margin-bottom: 2rem;
  }

  &__name {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0;
    gap: 0.625rem;
    width: 39%;

    @include extraLd {
      gap: 1.75rem;
    }
  }
  &__name-icon {
    svg {
      @include extraLd {
        height: 2.5rem;
        width: 2.5rem;
      }
    }
  }

  &__name-text {
    font-style: normal;
    font-weight: 700;
    font-size: 0.75rem;
    line-height: 150%;
    color: #FFFFFF;

    @include extraLd {
      font-size: 1.75rem;
    }
  }

  &__location {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 150%;
    width: 3.5rem;

    @include extraLd {
      font-size: 2rem;
      width: 7rem;

      svg {
        height: 2.3675rem;
        width: auto;
      }
      
    }
  }

  &__date {
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 150%;

    @include extraLd {
      font-size: 1.75rem;
    }
  }

  &__play-journey {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 2.25rem;
    min-height: 2.25rem;
    width: 2.25rem;
    height: 2.25rem;
    border-radius: 50%;
    background: rgba(1, 73, 105, 0.6);
    backdrop-filter: blur(0.5px);
    cursor: pointer;
    outline: none;
    border: none;

    @include extraLd {
      font-size: 1.75rem;
      min-width: 4.5rem;
      min-height: 4.5rem;
      width: 4.5rem;
      height: 4.5rem;

      svg {
        height: 1.875rem;
        width: auto;
      }
    }
  }

  &__select {
    line-height: 0;
    cursor: pointer;
    border: none;
    background: transparent;

    svg {
      height: 3rem;
      width: 3rem;
    }
  }

}