@import 'app/assets/scss/_variables.scss';
@import 'app/assets/scss/_mixins.scss';

.checkbox-label {
  position: relative;
  cursor: pointer;
  color: $brandWhite;
  font-size: 0.875rem;
  display: grid;
  grid-template-columns: 1.25rem auto;
  gap: 1rem;
  user-select: none;
  //align-items: center;

  @include extraLd {
    font-size: 1.75rem;
    grid-template-columns: 2.5rem auto;
    gap: 2rem;
  }

  $label: &;

  .check-icon {
    opacity: 0;
    position: absolute;
    width: 1.25rem;
    height: 1.25rem;

    @include extraLd {
      width: 2.5rem;
      height: 2.5rem;
    }
  }

  input[type='checkbox'] {
    width: 1.25rem;
    height: 1.25rem;
    color: $brandBlueGreen;
    background-color: $brandWhiteShadowTransparent;
    margin: 0;
    appearance: none;
    outline: 0;
    cursor: pointer;
    transition: background 175ms cubic-bezier(0.1, 0.1, 0.25, 1);
    opacity: 1;

    @include extraLd {
      width: 2.5rem;
      height: 2.5rem;
    }
    &:checked {
      border-color: $brandBlueGreen;
      background-color: $brandBlueGreen;
      opacity: 0;
      &:checked ~ .check-icon {
        opacity: 1;
      }
    }
  }

  &__text {
    line-height: 1.5;
  }

  &__description {
    font-size: 0.75rem;
    color: rgba($brandWhite, 0.7);
    margin-top: 0.25rem;
    line-height: 1.5;
    @include extraLd {
      font-size: 1.5rem;
    }
  }
}
