@import './src/app/assets/scss/_variables.scss';
@import '/src/app/assets/scss/_mixins.scss';

$uiCardPadding: 1.5rem;
$uiCardMarginTop: -$uiCardPadding;

.c-device-rotate-modal {
  $block: &;

  $padding: $uiCardPadding * 1.5;

  display: flex;
  pointer-events: none;
  position: absolute;
  z-index: 30;
  inset: 0;
  align-items: center;
  justify-content: center;

  margin-top: 8rem;

  &.is-open {
    pointer-events: auto;

    &::before {
      opacity: 1;
    }
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    z-index: -1;
    inset: 0;
    opacity: 0;
    transition: opacity 0.5s;
  }

  @at-root {
    &__container {
      position: relative;
      width: 100vw;
      height: 100vh;

      background: rgba(14, 36, 53, 0.92);

      opacity: 0;

      transform: scale(0.95) translate(0, 2rem);
      transition: opacity 0.5s, transform 0.5s;

      #{$block}.is-open & {
        opacity: 1;
        transform: scale(1) translate(0, 0);
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
      position: relative;
      overflow: hidden;
      width: 100%;
      height: 100%;

      font-style: normal;
      font-weight: bold;
      font-size: 1.25rem;
      line-height: 1.5;
      text-align: center;
      color: #ffffff;

      &_margin {
        margin-top: 13.313rem;
      }

      &-description_margin {
        margin-top: 0.5rem;
      }

      &-hint {
        font-weight: normal;
        font-size: 0.875rem;
        line-height: 1.5;
        text-align: center;

        &_margin {
          margin-top: 0.375rem;
        }
      }

      svg {
        width: 10.5rem;
        height: 13.25rem;
      }

      &-box {
        position: relative;

        &-text {
          position: absolute;
          bottom: 2rem;
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: 100%;

          &.fade-in {
            opacity: 0;
            animation-name: opacityIn;
            animation-iteration-count: 1;
            animation-timing-function: ease-in;
            animation-duration: 1s;
            animation-delay: 1200ms;
            animation-fill-mode: forwards;
          }

          @keyframes opacityIn {
            0% {
              opacity: 0;
            }
            100% {
              opacity: 1;
            }
          }
        }
      }
    }
  }
}
