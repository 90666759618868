@import 'app/assets/scss/_variables.scss';
@import 'app/assets/scss/_mixins.scss';

.journey-copy-to-clipboard{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: stretch;

  &_info {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    flex-grow: 1;
    gap: 0.625rem;

    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 150%;
    text-decoration-line: underline;
    color: #FFFFFF;
    background: #4E5C65;
    padding-left: 1.25rem;
    padding-right: 1.25rem;

    @include extraLd {
      gap: 1.25rem;
      font-size: 1.75rem;
      padding-left: 2.5rem;

      svg {
        height: 2.5rem;
        width: 2.5rem;
      }
    }
  }
  &_action {
    flex-grow: 0;
  }
  &_copy-action {

    &:disabled {
      transition: none !important;
      transform: none !important;

      .c-button__text {
        background: #4E5C65;
      }
    }

    .c-button__text {
      font-weight: 700;
      font-size: 0.75rem;
      line-height: 150%;
      color: #002C40;
      background: #89C0D1;
      padding: 0.625rem 1.25rem !important;

      @include extraLd {
        font-size: 1.5rem;
        padding: 1.25rem 2.5rem !important;
      }
    }
  }
}
