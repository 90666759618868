@import './src/app/assets/scss/_variables.scss';
@import '/src/app/assets/scss/_mixins.scss';

$uiCardPadding: 1.5rem;
$uiCardMarginTop: -$uiCardPadding;

.c-contact-us {
  .c-button {
    position: relative;
    background-color: transparent;
    border: 0.0625rem solid rgba($brandWhite, 0.5);
    box-sizing: border-box;
    height: 2.375rem;

    @include extraLd {
      border: 0.125rem solid $brandWhite;
      height: 4.75rem;
    }

    &:hover,
    &:active {
      background-color: $brandWhiteHardTransparent;
      transform: none;
    }

    .c-button__text {
      padding: 0.565rem 1.186rem;

      font-family: Hexagon, serif;
      font-style: normal;
      font-weight: bold;
      font-size: 0.75rem;
      line-height: 1.5;
      text-transform: uppercase;

      @include md {
        padding: 0.565rem 0.996rem;
        font-size: 0.75rem;
        line-height: 1;
      }

      @include ld {
        padding: 0.565rem 1.08rem;
        font-size: 0.875rem;
        line-height: 1;
      }

      @include extraLd {
        padding: 1.25rem 2.5rem;
        font-size: 1.75rem;
        line-height: 2.25rem;
      }

      &::before {
        background-color: transparent;
      }
    }
  }

  .c-button--size-sm .c-button__text {
    padding: 0;
  }
}

/*
  
  */