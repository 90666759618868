@import 'app/assets/scss/_variables.scss';
@import 'app/assets/scss/_mixins.scss';

.product-detail {
  position: fixed;
  padding: 0.75rem 2rem 0.75rem 2.5rem;
  background-color: rgba($brandDarkBlue, 0.82);
  text-transform: uppercase;
  color: $brandWhite;
  font-size: 0.75rem;
  font-weight: bold;
  width: 35rem;
  cursor: pointer;
  line-height: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 32px, calc(100% - 32px) 0);

  &.bottom {
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  @include sm {
    width: 28.125rem;
  }

  @include md {
    width: 33.75rem;
  }

  @include extraLd {
    width: 70rem;
    padding: 1.5rem 4rem 1.5rem 5rem;
    font-size: 1.5rem;
  }

  svg {
    @include extraLd {
      width: 3rem;
      height: 3rem;
    }
  }
}

$topContentOffset: 6rem;

.c-product-info-modal {
  // display: none;
  z-index: 27;
  margin-top: $mainNavHeight;
  padding: 0 2.75rem 1.5rem 0;
  color: $brandWhite;
  margin-left: calc(var(--base-sidebar-width) - 0.01rem);

  // todo: this is for sidebar
  @include extraLd {
    padding: 0 5.5rem 3rem 0;
    // margin-left: 5.5rem;
  }

  &.is-open {
    display: flex;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .files-window {
    background-color: rgba(2, 73, 105, 0.92);
    width: 100%;
    height: 100%;
    line-height: 1.5;
    position: relative;

    .close-container {
      position: absolute;
      right: 2rem;
      top: 2rem;
      cursor: pointer;
      color: white;
      background-color: transparent;
      width: 17px;
      height: 17px;
      min-height: 17px;
      min-width: 17px;
      display: flex;
      align-items: center;
      justify-content: center;

      @include extraLd {
        right: 4rem;
        top: 4rem;
        width: 17px;
        height: 17px;
        min-height: 17px;
        min-width: 17px;
      }

      svg {
        // transform: rotate(180deg);

        path {
          fill: white !important;
        }

        @include extraLd {
          width: 3rem;
          height: 3rem;
        }
      }
    }

    .files-wrapper {
      display: flex;
      height: 100%;


      .files-names {
        width: 30%;
        min-width: 24.125rem;
        padding: 0 2.25rem 2.5rem 2.5rem;
        display: flex;
        flex-direction: column;

        @include sm {
          padding: 0 1rem 2.5rem 1.875rem;
        }

        @include extraLd {
          min-width: 48.25rem;
          padding: 0 4.5rem 5rem 5rem;
        }

        .title {
          font-size: 1rem;
          text-transform: uppercase;
          padding-top: 2.25rem;
          padding-bottom: 1rem;
          font-weight: bold;
          height: $topContentOffset;

          @include extraLd {
            font-size: 2rem;
            padding-top: 4.5rem;
            padding-bottom: 2rem;
            height: $topContentOffset * 2;
          }
        }

        .file-list {
          overflow: auto;

          .file {
            padding: 0.625rem 1.25rem;
            display: flex;
            align-items: center;
            font-size: 0.9375rem;
            cursor: pointer;
            margin-bottom: 0.625rem;
            background-color: #4e5c65;

            @include extraLd {
              font-size: 1.875rem;
              padding: 1.25rem 2.5rem;
            }

            &.active {
              background-color: #023c57;
            }

            &:hover {
              // text-decoration: none;
            }

            .icon {
              margin-right: 1rem;

              @include extraLd {
                margin-right: 2rem;
              }
            }

            .tablet-link {
              display: flex;
            }

            svg {
              width: 1.5rem;
              height: 1.5rem;

              @include extraLd {
                width: 3rem;
                height: 3rem;
              }

            }
          }
        }

        .product-link {
          margin-top: auto;

          a {
            display: inline-block;
            text-transform: none;
            font-size: 0.75rem;
            line-height: 1;
            color: $brandBlueGreen;
            text-decoration: none;

            @include md {
              font-size: 0.8125rem;
            }
            @include ld {
              font-size: 1.25rem;
            }
            @include extraLd {
              font-size: 2.5rem;
            }

            &::after {
              content: '';
              opacity: 1;
              height: 1px;
              display: block;
              background: $brandBlueGreen;
              transition: 300ms;
            }

            &:hover::after {
              opacity: 0;
            }
          }
        }
      }

      .files-content {
        flex: 1 0;
        overflow: hidden;
        -webkit-overflow-scrolling: touch;
        margin-top: $topContentOffset;
        padding: 0 1.25rem 0 1.25rem;
        margin-bottom: 2.5rem;
        border-left: 1px solid rgba($brandWhite, 0.5);
        position: relative;

        @include extraLd {
          padding: 0 2.5rem 0 2.5rem;
          margin-bottom: 5rem;
        }

        &::-webkit-scrollbar-track {
          border-radius: 0.5rem;

          @include extraLd {
            border-radius: 1rem;
          }
        }

        &::-webkit-scrollbar {
          width: 0.5rem;
          -webkit-appearance: none;

          @include extraLd {
            width: 1rem;
          }
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 0.5rem;
          background-color: rgba(193, 193, 193, 0.2);

          @include extraLd {
            border-radius: 1rem;
          }
        }

        &::-webkit-scrollbar-thumb:hover {
          background: rgba(193, 193, 193, 0.4);
        }

        &::-webkit-scrollbar-thumb:window-inactive {
          background: rgba(193, 193, 193, 0.5);
        }

        @include sm {
          padding: 0 0.875rem;
        }



        .empty-pdf {
          color: rgba($brandWhite, 0.5);
          font-size: 0.75rem;
          padding: 9.75rem;
          text-align: center;

          @include md {
            font-size: 0.8125rem;
          }
          @include ld {
            font-size: 1.25rem;
          }
          @include extraLd {
            font-size: 2.5rem;
            padding: 20rem;
          }
        }

        .scroll-container {
          overflow: scroll;
          height: 100%;
          width: 100%;
        }

        .react-pdf__message--loading {
          @include extraLd {
            font-size: 1.75rem;
          }
        }

        .file-controls {
          display: flex;
          justify-content: flex-end;

          .zoom {
            display: flex;
          }

          svg {
            width: 1.25rem;
            height: 1.25rem;
            margin-left: 2rem;
            cursor: pointer;
          }

        }
      }
    }
  }
}



.info-visible-enter {
  opacity: 0;
  // transform: scale(0.95);
}

.info-visible-enter-done {
  opacity: 1;
  // transform: scale(1);
  transition: all 300ms;
}

.info-visible-exit {
  opacity: 1;
}

.info-visible-exit-done {
  opacity: 0;
  transition: opacity 300ms;
}

@at-root {

  // Zoom Action
  .info-visible-enter {
    &.info-visible-enter-active {
      .modal-window {
        opacity: 1;
        transform: scale(1);
        transition: opacity 200ms ease-in, transform 500ms cubic-bezier(0.68, -0.55, 0.27, 1.55);
      }
    }

    .modal-window {
      opacity: 0;
      transform: scale(0.7);
    }
  }

  .info-visible-exit {
    &.info-visible-exit-active {
      .modal-window {
        opacity: 0;
        transform: scale(0.1);
        transition: opacity 500ms ease-out, transform 500ms cubic-bezier(0.68, -0.55, 0.27, 1.55);
      }
    }

    .modal-window {
      opacity: 1;
      transform: scale(1);
    }
  }

}