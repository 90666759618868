@import 'app/assets/scss/_variables.scss';
@import 'app/assets/scss/_mixins.scss';

// The journey itself
// ---------------------------------------------------------- //

.journey {
  $block: &;
  width: 100%;
  height: 100vh;
  background: $brandDarkBlue;
  overflow: hidden;
  position: relative;

  min-height: -webkit-fill-available;

  $offset: 1.25rem;
  $tabWidth: 3rem;

  &.workflows-overview-2d {
    .paths {
      top: 0 !important;
      transform: translateX(calc(-100% + $tabWidth)) translateY(12.375rem);

      @include md {
        transform: translateX(calc(-100% + $tabWidth)) translateY(13.68rem);
      }

      @include ld {
        transform: translateX(calc(-100% + $tabWidth)) translateY(18rem);
      }


      @include extraLd {
        transform: translateX(calc(-100% + ($tabWidth * 2))) translateY(36rem);
        width: 40.25rem;

      }

      &.is-open {
        transform: translateX($offset) translateY(12.375rem);

        @include md {
          transform: translateX($offset) translateY(13.68rem);
        }

        @include ld {
          transform: translateX($offset) translateY(18rem);
        }

        @include extraLd {
          transform: translateX(2.5rem) translateY(36rem);
        }
      }
    }
  }




  @at-root {
    &__preload {
      display: none;
    }

    &__background,
    &__stage {
      @include fullScreenFlexContainer();

      transition: opacity 1s;

      &.fadeout {
        opacity: 0;
      }
    }

    &__background {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;

      &-image,
      &-video {
        @include fullScreen169(true);
      }
    }

    &__sidebar {
      top: 7.5rem;
      z-index: 40;
      left: 0;
    }
  }
}

// NEW
.sidebar-container {
  height: 100vh;
  overflow-y: hidden;
  position: relative;
}

.sidebar-right-wrapper {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  height: 100%;
  position: fixed;
  bottom: 0;
  right: 0;
  transform: translateX(100%);
  transition: transform 0.2s ease-in;
  z-index: 13;

  &.active {
    // transform: translateX(calc(100% - 25.9rem));
    transform: translateX(calc(100% - 26.8375rem));

    @include extraLd {
      transform: translateX(calc(100% - 51.9rem));
    }
  }

  &.visible {
    transform: translateX(0);
  }

  .scene-badge-button-container {
    height: 5.625rem;
    width: 2.5rem;
    margin-top: 26.175rem;

    margin-right: 30px;

    @include extraLd {
      margin-right: 60px;
    }

    @include extraLd {
      height: 11.125rem;
      width: 5rem;
      margin-top: 52rem;
    }

    .scene-badge-button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;

      svg {
        width: 16px;
        height: 16px;

        @include extraLd {
          width: 48px;
          height: 48px;
        }
      }
    }

    .close-scene {
      background: $brandWhite; // $brandMidBlue;
      border: 0.0625rem solid $brandWhite;
      border-radius: 50%;
      width: 48px;
      height: 48px;

      @include extraLd {
        width: 126px;
        height: 126px;
      }
    }

    .open-scene {
      background: $brandWhite;
    }

    .arrow {
      display: inline-block;
      padding: 0.1875rem;
      align-self: center;
      border: solid;

      @include extraLd {
        padding: 0.375rem;
      }

      &.right {
        border-color: $brandWhite;
        border-width: 0 0.1875rem 0.1875rem 0;
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
      }

      &.left {
        border-color: $brandMidBlue;
        border-width: 0 0.1875rem 0.1875rem 0;
        transform: rotate(135deg);
        -webkit-transform: rotate(135deg);
      }
    }

    &:hover {
      cursor: pointer;
    }
  }

}

// Zoom Action
.show-lines-enter {
  &.show-lines-enter-active {
    .splash_wrap {
      opacity: 1;
      transform: scale(1);
      transition: opacity 200ms ease-in, transform 500ms cubic-bezier(0.68, -0.55, 0.27, 1.55);
    }
  }

  .splash_wrap {
    opacity: 0;
    transform: scale(0.7);
  }
}

.show-lines-exit {
  &.show-lines-exit-active {
    .splash_wrap {
      opacity: 0;
      transform: scale(0.1);
      transition: opacity 500ms ease-out, transform 500ms cubic-bezier(0.68, -0.55, 0.27, 1.55);
    }
  }

  .splash_wrap {
    opacity: 1;
    transform: scale(1);
  }
}