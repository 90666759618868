@import 'app/assets/scss/_variables.scss';
@import 'app/assets/scss/_mixins.scss';

.location-list-element {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.313rem;
  padding: 0.5rem 1.25rem 0.5rem 0.75rem;
  box-sizing: border-box;
  font-size: 0.875rem;
  line-height: 150%;
  background: #4E5C65;
  // todo: in main component
  margin-bottom: 0.5rem;
  width: 100%;

  @include extraLd {
    font-size: 1.75rem;
    gap: 0.313rem;
    padding: 1rem 1.5rem;
    // todo: in main component
    margin-bottom: 1rem;
  }
  
  &__index {

  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  &__name {

  }

  &__path-name {
    font-size: 0.75rem;
    line-height: 0.875rem;
    color: #89C0D1;

    @include extraLd {
      font-size: 1.5rem;
      line-height: 1.5rem;
    }
  }

}
