@import 'app/assets/scss/_variables.scss';
@import 'app/assets/scss/_mixins.scss';

.slider-wrapper {
  height: calc(6.25rem + 3rem);

  @include extraLd {
    height: calc(12.5rem + 3rem);
  }
}

.slider {
  display: flex;
  //gap: 1.25rem;
  overflow: hidden;
  justify-content: flex-start;
  margin: 1.75rem 0 0 0;
  //padding: 0 1.25rem 1.25rem 1.25rem;
  align-items: center;

  @include extraLd {
    margin: 28rem 0 0 0;
    padding: 0 2.5rem;
    gap: 2.5rem;
  }
  &:hover {
    overflow-x: auto;
  }

  &-item {
    height: 100%;
    align-items: center;
    display: flex;
    padding: 0 0.625rem;

    &:first-child {
      padding-left: 1.25rem;
    }

    &:last-child {
      padding-right: 1.25rem;
    }

    img {
      width: 4.375rem;
      transition: width 0.25s ease-in-out;

      @include extraLd {
        width: 9.25rem;
      }
    }

    &.active {
      img {
        width: 6.25rem;

        @include extraLd {
          width: 12.5rem;
        }
      }
    }
  }
}
