.splash_wrap {
    z-index: 1;
    display: flex;

    svg {
        width: 100%;
        height: 100%;
    }

    .video_random {
        position: absolute;
        width: 100%;
        height: 100%;

    }
}

.splash_visible-enter {
    opacity: 0;
    transform: translateX(-100%);
}

.splash_visible-enter-active {
    opacity: 1;
    transition: opacity 300ms;
}

.splash_visible-exit {
    opacity: 1;
}

.splash_visible-exit-active {
    opacity: 0;
    transition: opacity 300ms;
}