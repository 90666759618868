@import './src/app/assets/scss/_variables.scss';
@import '/src/app/assets/scss/_mixins.scss';

/* Slider */
.slick-loading .slick-list {
  background: #fff url('../icons/ajax-loader.gif') center center no-repeat;
}

/* Arrows */
.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;

  position: absolute;
  top: 50%;

  display: block;

  width: 1.25rem;
  height: 1.25rem;
  padding: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);

  cursor: pointer;

  color: transparent;
  border: none;
  outline: none;
  background: transparent;

  @include extraLd {
    width: 2.5rem;
    height: 2.5rem;
  }
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  color: transparent;
  outline: none;
  background: transparent;
}

.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}

.slick-prev:before,
.slick-next:before {
  font-size: 20px;
  line-height: 1;

  opacity: 0.75;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -1.5625rem;

  svg {
    @include extraLd {
      width: 1rem;
      height: 1.5rem;
    }
  }

  @include extraLd {
    left: -3.125rem;
  }
}

.slick-next {
  right: -1.5625rem;

  svg {
    @include extraLd {
      width: 1rem;
      height: 1.5rem;
    }
  }

  @include extraLd {
    right: -3.125rem;
  }
}

.gesture-container {
  position: absolute;
  bottom: 5rem;
  right: 0;
  width: 13.125rem;
  transition: transform 0.2s ease-in, right 0.2s ease-in;

  &.open {
    right: 5rem;
  }

  @include extraLd {
    width: 26.25rem;
    bottom: 10rem;
    // right: 10rem;

    &.open {
      right: 10rem;
    }
  }

  &.right-offset {
    transform: translateX(-19.5rem);

    &.open {
      transform: translateX(-22.5rem);
    }

    @include extraLd {
      transform: translateX(-45rem);

      &.open {
        transform: translateX(-45rem);
      }
    }
  }

  button {
    @include reset-button();
    display: flex;
    padding: 0.875rem;
    border-radius: 50%;
    background: rgba($brandWhite, 0.1);
    color: $brandWhite;
    align-self: center;

    @include extraLd {
      padding: 1.75rem;
    }
  }

  .slider-container {
    opacity: 0;
    height: 0;
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
    transition: all 0.4s ease-out 0.2s;

    &.visible {
      opacity: 1;
      height: auto;
      margin-bottom: 5.75rem;

      @include extraLd {
        margin-bottom: 11.5rem;
      }

      &.right-offset {
        transform: translateX(-16.5rem);

        @include extraLd {
          transform: translateX(-32rem);
        }
      }
    }
  }

  .toggle-button {
    margin: 0 auto;
    cursor: pointer;

    .buttonSlide-enter {
      &.buttonSlide-enter-active {
        span {
          opacity: 1;
          transform: scale(1);
          transition: opacity 200ms ease-in, transform 500ms cubic-bezier(0.68, -0.55, 0.27, 1.55);
        }
      }

      span {
        opacity: 0;
        transform: scale(0.7);
      }
    }

    .buttonSlide-exit {
      &.buttonSlide-exit-active {
        span {
          opacity: 0;
          transform: scale(0.1);
          transition: opacity 500ms ease-out, transform 500ms cubic-bezier(0.68, -0.55, 0.27, 1.55);
        }
      }

      span {
        opacity: 1;
        transform: scale(1);
      }
    }

    &.with-text {
      padding: 0.875rem 1.375rem;
      text-transform: uppercase;
      font-weight: 700;
      font-size: 0.875rem;
      border-radius: 1.5rem;
      display: flex;
      align-items: center;
      max-width: 70rem;

      transition: transform 0.2s;

      @include extraLd {
        padding: 1.75 2.75rem;
        font-size: 1.75rem;
        border-radius: 3rem;
        max-width: 140rem;
      }

      &.right-offset {
        transform: translateX(-6.5rem);

        @include extraLd {
          transform: translateX(-12rem);
        }
      }

      svg {
        margin-right: 0.625rem;

        @include extraLd {
          margin-right: 1.3rem;
        }
      }
    }
  }

  .nav-icon {
    background: $brandBlueGreen;
    border-radius: 50%;
    display: flex !important;
    align-items: center;
    justify-content: center;
    z-index: 1;
  }

  .slick-next,
  .slick-prev {
    font-size: 0.875rem;
    width: 2.75rem;
    height: 2.75rem;
    top: calc(50% - 1.5rem);

    @include extraLd {
      font-size: 1.75rem;
      width: 5.5rem;
      height: 5.5rem;
      top: calc(50% - 3rem);
    }
  }

  .slick-prev::backdrop,
  .slick-next::before {
    content: '';
  }

  .slick-prev {
    left: -1.375rem;

    @include extraLd {
      left: -2.75rem;
    }
  }

  .slick-next {
    right: -1.375rem;

    @include extraLd {
      right: -2.75rem;
    }
  }

  .slick-initialized .slick-slide {
    width: 13.125rem;

    @include extraLd {
      width: 26.5rem;
    }
  }

  .gest {
    display: flex;
    flex-direction: column;
    align-items: center;

    svg {
      max-width: 100%;
      height: 6.125rem;

      @include extraLd {
        height: 12.25rem;
      }
    }

    .svg-outer {
      border: 1px solid rgba($brandLightGray, 0.5);
      border-radius: 50%;
      padding: 0.75rem;
      width: 100%;
      height: 13.125rem;
      display: flex;
      justify-content: center;
      align-items: center;

      @include extraLd {
        padding: 1.5rem;
        height: 26.5rem;
      }

      .svg-inner {
        background-color: rgba($brandDarkBlue, 0.82);
        border-radius: 50%;
        width: 100%;
        height: 100%;
        padding: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;

        @include extraLd {
          padding: 4rem;
        }
      }
    }

    .title {
      font-size: 1.125rem;
      color: $brandWhite;
      font-weight: 700;
      margin: 1.125rem 0 1rem 0;

      @include extraLd {
        font-size: 2.25rem;
        margin: 2.25rem 0 2rem 0;
      }
    }
  }
}