@import './src/app/assets/scss/_variables.scss';
@import '/src/app/assets/scss/_mixins.scss';

$uiCardPadding: 1.5rem;
$uiCardMarginTop: -$uiCardPadding;

.c-contact-us-modal {
  $block: &;

  $padding: $uiCardPadding * 1.5;

  display: flex;
  pointer-events: none;
  position: absolute;
  z-index: 28;
  inset: 0;
  align-items: center;
  justify-content: center;
  max-height: 100vh;

  &.is-open {
    pointer-events: auto;

    &::before {
      opacity: 1;
    }
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    z-index: -1;
    inset: 0;
    opacity: 0;
    transition: opacity 0.5s;
  }

  @at-root {
    &__container {
      --close-button-color: rgba(5, 30, 47, 0.825);

      position: relative;
      width: 100vw;
      max-width: $screenMedium;
      max-height: 100%;

      opacity: 0;

      transform: scale(0.95) translate(0, 2rem);
      transition: opacity 0.5s, transform 0.5s;

      #{$block}.is-open & {
        opacity: 1;
        transform: scale(1) translate(0, 0);
      }

      &--base-theme { // china version
        --close-button-color: white;
      }

      .c-button {
        background-color: transparent;
        color: var(--close-button-color);
      }
    }

    &__content {
      display: flex;
      flex-direction: row;
      position: relative;
      overflow: hidden;
      width: 100%;
      height: 100%;
    }
  }

  .c-basic-form {
    width: 46.8%;
    background: #ffffff;

    padding: 3rem 2.75rem 3rem 2.75rem;

    display: flex;
    flex-direction: row;

    &__column-right {
      width: 100%;
      margin-left: 1.875rem;
    }

    &__header {
      &-title {
        font-family: Hexagon;
        font-style: normal;
        font-weight: 900;
        font-size: 1.5rem;
        line-height: 1.5;
        text-transform: uppercase;
        color: #014969;
      }

      &-description {
        font-family: Hexagon;
        font-style: normal;
        font-weight: bold;
        font-size: 0.875rem;
        line-height: 1.5;
        color: #014969;
      }

      &-description_margin {
        margin-top: 0.5rem;
      }
    }

    &__body {
      margin-top: 1.75rem;
      padding-right: 2.5rem;
    }

    &__field_margin {
      margin-top: 1.75rem;
    }

    &__field-title {
      font-family: Hexagon;
      font-style: normal;
      font-weight: bold;
      font-size: 0.625rem;
      line-height: 1.5;
      text-transform: uppercase;
      color: #014969;
      opacity: 0.8;

      &-optional {
        font-family: Hexagon;
        font-style: normal;
        font-weight: bold;
        font-size: 0.625rem;
        line-height: 1.5;
        color: #b0b0b0;
        text-transform: none;
      }
    }

    &__field-input {
      width: 100%;

      &_margin {
        margin-top: 0.25rem;
      }

      .MuiOutlinedInput-root {
        border-radius: 0;
      }

      .MuiOutlinedInput-input {
        padding: 0.75rem 1rem 0.688rem 1rem;

        font-family: Hexagon;
        font-style: normal;
        font-weight: normal;
        font-size: 0.875rem;
        line-height: 1.5;
      }

      .MuiInputBase-multiline {
        padding: 0;

        clip-path: polygon(
          0 0,
          0 0,
          0 0,
          100% 0,
          100% calc(100% - #{$bevelOffset}),
          calc(100% - #{$bevelOffset}) 100%,
          0 100%,
          0 0
        );
      }

      &-text {
        position: relative;
      }
    }

    .corner-line {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 2rem;
      height: 2rem;

      line {
        stroke-dashoffset: 0;
        stroke-dasharray: none;
        stroke: rgba(14, 36, 53, 0.2);
        stroke-width: 1.2;
      }
    }
  }

  /*.MuiFormControl-root.MuiTextField-root {}*/

  .c-details-form {
    width: 53.2%;

    @include brandBGBlur();

    padding: 1.875rem;

    &_align-right {
      display: flex;
      justify-content: end;
    }

    .c-button {
      background-color: transparent;

      :hover {
        transform: none;
      }
    }

    .c-button.close-button {
      z-index: 1;

      $offset: $padding * 0.75;

      &-svg {
        width: 1.25rem;
      }
    }

    .c-button__text::before {
      background-color: transparent;
    }

    .c-button--size-sm .c-button__text {
      padding: 0;
    }

    &__content {
      margin-top: 0.875rem;

      padding: 0 0 0 2.325rem;
    }

    &__title {
      display: flex;
      flex-direction: row;

      font-family: Hexagon;
      font-style: normal;
      font-weight: normal;
      font-size: 1.125rem;
      line-height: 1.5;
      color: #ffffff;

      &-description_margin {
        margin-left: 0.625rem;
      }
    }

    &__panel {
      margin-top: 2.313rem;

      &-button.MuiButton-root {
        border-radius: 1.313rem;
        padding: 0.75rem 1.5rem;

        background: rgba(255, 255, 255, 0.15);

        font-family: Hexagon;
        font-style: normal;
        font-weight: bold;
        font-size: 0.75rem;
        line-height: 1.5;
        text-align: center;
        color: #ffffff;

        box-shadow: none;

        text-transform: none;
      }

      &-button_active.MuiButton-root {
        background: #89c0d1;
        color: #002c40;
      }

      &-button {
        &_margin {
          margin-left: 1rem;
        }
      }
    }

    &__info {
      margin-top: 2.5rem;

      &-title {
        font-family: Hexagon;
        font-style: normal;
        font-weight: normal;
        font-size: 0.875rem;
        line-height: 1.5;
        color: #ffffff;
      }

      &-content {
        margin-top: 1.625rem;

        display: flex;
        flex-direction: row;

        &-column-margin {
          margin-left: 4.875rem;
        }

        .MuiTypography-root {
        }
      }
    }

    .MuiPaper-root {
      background-color: transparent;
      box-shadow: none;
    }

    .MuiAccordionSummary-root {
      padding: 0;
    }

    .MuiAccordionSummary-content {
      margin: 0;
      flex-grow: 0;
    }

    .MuiButtonBase-root.MuiAccordionSummary-root {
      justify-content: start;
    }

    .MuiAccordionSummary-expandIconWrapper {
      margin-left: 0.625rem;
    }

    .MuiAccordionSummary-root.Mui-expanded {
      min-height: 0;
    }

    &__time {
      // width: 36%;

      margin-top: 4.125rem;

      &-text {
        font-family: Hexagon;
        font-style: normal;
        font-weight: normal;
        font-size: 1.125rem;
        line-height: 1.5;
        color: #ffffff;
        margin-left: 0.625rem;
      }

      .MuiAutocomplete-root {
        width: 52%;
      }

      .MuiOutlinedInput-root {
        border-radius: 0;

        fieldset.MuiOutlinedInput-notchedOutline {
          background: rgba(255, 255, 255, 0.15);
          border-color: transparent;
        }

        .MuiSvgIcon-root {
          path {
            // fill: #002C40;
          }
        }
      }

      .MuiInputLabel-root {
        font-family: Hexagon;
        font-style: normal;
        font-weight: bold;
        // font-size: 0.75rem;
        line-height: 1.5;
        text-align: center;
        color: #ffffff;
      }

      .MuiButtonBase-root.MuiChip-root {
        background: #89c0d1;
        color: #002c40;
      }

      .MuiAccordionDetails-root {
        padding: 0;
      }

      &_margin {
        margin-top: 1.25rem;
      }
    }
  }
}

.c-contact-us-modal__content-footer {
  position: fixed;
  bottom: 0rem;
  right: 0rem;

  display: flex;
  justify-content: end;
}

.contact-us_iframe {
  @include ld {
    height: 59.375rem;
  }

  @include md {
    min-height: 40.625rem;
    max-height: 50rem;
  }

  @include sm {
    height: 31.25rem;
  }
}
