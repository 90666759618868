// Mixins
// ---------------------------------------------------------- //

@use "sass:math";

// Makes the little annoying text divider line
@mixin littleAnnoyingDividerLine($bottom: 0.2em, $top: 0) {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: $top;
    bottom: $bottom;
    left: 0;
    border-left: 0.0625rem solid currentColor;
  }
}

// Resets button styles
@mixin reset-button() {
  display: block;
  background: none;
  padding: 0;
  color: inherit;
  text-align: inherit;
  text-shadow: none;
  border: none;
  outline: none;
}

// Makes margins of child elements make sense
@mixin keepChildrenInLine($first: 1, $last: 1) {
  > :nth-child(#{$first}) {
    margin-top: 0;
  }

  > :nth-last-child(#{$last}) {
    margin-bottom: 0;
  }
}

// Makes a super nice little blurred blue background
@mixin brandBGBlur() {
  background-color: $brandDarkBlueTransparent;
  backdrop-filter: blur($brandBGBlur);
  color: $brandWhite;
}

// Makes a super nice blurred blue background
@mixin brandBGNotSoBlur() {
  background-color: $brandDarkBlueNotTransparent;
  backdrop-filter: blur($brandBGBlur);
  color: $brandWhite;
}

// Makes something full-screen and ready to center things
@mixin fullScreenAbsoluteContainer() {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}

// Makes something full-screen and ready to center things
@mixin fullScreenFlexContainer() {
  display: flex;
  position: absolute;
  inset: 0;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

// Makes something force a full-screen 16:9 aspect ratio
@mixin fullScreen169($stacked: false) {
  $videoAspectRatio: math.div(16, 9);
  $videoAspectRatioMediaQuery: '16/9';

  $height: 100vh;
  min-height: 100vh;

  flex: auto 0 0;
  width: $videoAspectRatio * 100vh;
  height: $height;

  @if $stacked {
    &:not(:last-child) {
      margin-bottom: -$height;
    }
  }

  @media (min-aspect-ratio: $videoAspectRatioMediaQuery) {
    $height: calc(1 / $videoAspectRatio * 100vw);

    width: 100vw;
    height: $height;
    min-height: $height;

    @if $stacked {
      &:not(:last-child) {
        margin-bottom: -$height;
      }
    }
  }
}

// ExtraLarge devices ~ 2560
@mixin extraLd {
  @media only screen and (min-width: #{$screenExtraBig}) {
    @content;
  }
}

// Large devices ~ 1920
@mixin ld {
  @media only screen and (min-width: #{$screenBig}) {
    @content;
  }
}

// Medium devices ~ 1200 - 1920
@mixin md {
  @media only screen and (min-width: #{$screenMedium}) and (max-width: #{$screenBig - 1}) {
    @content;
  }
}

// Small devices
@mixin sm {
  @media only screen and (max-width: #{$screenMedium - 1}) {
    @content;
  }
}