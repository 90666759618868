@import './src/app/assets/scss/_variables.scss';
@import '/src/app/assets/scss/_mixins.scss';

$uiCardPadding: 1.5rem;
$uiCardMarginTop: -$uiCardPadding;

.c-audio-mute {
  $block: &;

  position: fixed;
  z-index: 25;

  bottom: 2.5rem;
  right: 2.5rem;

  cursor: pointer;

  @include extraLd {
    bottom: 16.25rem;
    right: 5rem;
    svg {
      width: 6rem;
      height: 6rem;
    }
  }
  transform: translateX(0);

  transition: transform 0.2s ease-in;

  &.right-offset {
    transform: translateX(-22.5rem);
    @include extraLd {
      transform: translateX(-45rem);
    }
  }
}

.MuiTooltip-popper .MuiTooltip-tooltip {
  background-color: rgba(5, 30, 47, 0.82);
  border-radius: 0.125rem;

  padding: 0.625rem 0.875rem;
}
