// Vars
// ---------------------------------------------------------- //

@use "sass:math";

$brandBlack: #000000;
$brandDarkBlue: #051e2f;
$brandDarkishBlue: #1e2d35;
$brandMidBlue: #014969;
$brandLightishBlue: #0098ba;
$brandLightBlue: #7ac2d3;
$brandGreen: #bad97a;
$brandOrange: #ff671d;
$brandWhite: #ffffff;
$brandBlueGray: #1e3444;

$brandShadeBlue: #0e2435;
$brandBlueGreen: #89c0d1;
$brandGray: #b0b0b0;
$brandRed: #ff6161;

$newBrandBlue: #002C40;

$brandLightGray: rgba(217, 217, 217, 1);


$brandDarkBlueTransparent: rgba($brandDarkBlue, 0.825);
$brandDarkBlueNotTransparent: rgba($brandDarkBlue, 0.9);
$brandDarkBlueAlmostNotTransparent: rgba($brandDarkBlue, 0.95);
$brandShadeBlueNotTransparent: rgba($brandShadeBlue, 0.98);

$brandMidBlueTransparent: rgba($brandMidBlue, 0.65);

$brandWhiteTransparent: rgba($brandWhite, 0.25);
$brandWhiteGreyTransparent: rgba($brandWhite, 0.9);
$brandWhiteHardTransparent: rgba($brandWhite, 0.1);
$brandWhiteMiddleTransparent: rgba($brandWhite, 0.5);
$brandWhiteMiddleLessTransparent: rgba($brandWhite, 0.4);
$brandWhiteShadowTransparent: rgba($brandWhite, 0.15);
$brandWhiteNotTransparent: rgba($brandWhite, 0.9);

$brandBGBlur: 0.25rem;

$brandSVGStrokeWidth: 1.66666666;
$brandStrokeWidth: ceil($brandSVGStrokeWidth) * 0.0625rem;

// Unscoped Vars
// ---------------------------------------------------------- //

$bevelOffset: 2rem;
$videoAspectRatio: math.div(16, 9);
$videoAspectRatioMediaQuery: '16/9';

//Screen sizes
$screenSmall: 1024px;
$screenMedium: 1200px;
$screenBig: 1920px;
$screenExtraBig: 2560px;

$mainNavHeight: 4.125rem;