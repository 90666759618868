@import 'app/assets/scss/_variables.scss';
@import 'app/assets/scss/_mixins.scss';

.contact-us-form-wrapper {
  --color: #ffffff;
  --bg-color: #002C40;

  width: 100%;
  background: var(--bg-color);
  color: var(--color);
  padding: 10px 45px 45px 45px;
  // background: rgba(5, 30, 47, 0.82);

  // height: 250px;
  overflow-y: auto;
  

  @include ld {
    height: 59.375rem;
  }

  @include md {
    min-height: 40.625rem;
    max-height: 50rem;
  }

  @include sm {
    height: 31.25rem;
  }

  &__title {
    // font-family: Arial !important;
    font-size: 16px;
    // color: #666666;
    color: var(--color);
    line-height: 18px !important;
    padding: 10px 0px;
  }
}