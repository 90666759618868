@import 'app/assets/scss/_variables.scss';
@import 'app/assets/scss/_mixins.scss';

.location-list {
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: 100%;
}

.location-list-head {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @include extraLd {
    margin-bottom: 2rem;
  }

  &__checkbox {
    display: flex;
    flex-direction: row;
    align-items: center;

    svg {
      @include extraLd {
        height: 2.5rem;
        width: 2.5rem;
      }
    }
  }
  .Mui-checked+&__checkbox-label {
    color: #89C0D1;
  }

  &__checkbox-label {
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 150%;

    @include extraLd {
      font-size: 1.75rem;
    }
  }

  &__locations {
    display: flex;
    flex-direction: row;
    align-items: center;

    &--error {
      color: #FF6161;

      path {
        fill: #FF6161;
      }
    }
  }

  &__locations-icon {
    margin-right: 0.391rem;

    @include extraLd {
      margin-right: 0.75rem;

      svg {
        width: 1.466rem;
        height: 2.294rem;
      }
    }
  }

  &__locations-text {
    @include extraLd {
      font-size: 1.75rem;
    }
  }

}

.location-list-content {
  border: 1px solid rgba(255, 255, 255, 0.5);
  padding: 0.5rem 0.75rem 1rem;
  display: flex;
  flex-direction: column;
  overflow: auto;
  flex-grow: 1;

  @include extraLd {
    padding: 3.75rem 3.75rem 1.625rem 3.75rem;
    font-size: 2rem;
  }

  &__info-with-error {
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    padding-top: 1rem;
    padding-bottom: 1.5rem;
    align-items: center;

    
  }
  &__info {
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 150%;
    color: #FFFFFF;
    opacity: 0.5;

    @include extraLd {
      font-size: 1.75rem;
    }
  }
  &__error {
    margin-top: 0.625rem;
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 150%;
    color: #FF6161;

    @include extraLd {
      font-size: 1.75rem;
    }
  }

  &__list-wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: auto;
  }
  &__list {
    flex-grow: 0;
    overflow: auto;
  }
  &__description {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
  &__description-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-top: 2.063rem;
    padding-bottom: 2.563rem;
    color: #FFFFFF;
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 150%;
    opacity: 0.5;
    gap: 0.625rem;

    @include extraLd {
      font-size: 1.75rem;

      svg {
        height: 3rem;
        width: 3rem;
      }
    }
  }


  &__actions {
    flex-grow: 0;
    display: flex;
    justify-content: center;

    .c-button {
      border: 1px solid rgba(255, 255, 255, 0.5);

      &__text {
        font-family: Arial, serif;
        font-style: normal;
        font-weight: 700;
        font-size: 0.75rem;
        line-height: 150%;
        text-transform: uppercase;
        color: #FFFFFF;

        @include extraLd {
          font-size: 1.5rem;
          padding: 1.25rem 5.688rem !important;
        }
      }
    }
  }
}


