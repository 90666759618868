@import 'app/assets/scss/_variables.scss';
@import 'app/assets/scss/_mixins.scss';

.block-wrapper {
  @include fullScreenAbsoluteContainer();
  background: url('/assets/images/backgrounds/journey-0-start-frame.jpg');
  background-size: cover;
  z-index: 9999999;

  .block-screen {
    @include fullScreenFlexContainer();
    justify-content: normal;
    margin: 0.625rem;
    background-color: $brandDarkBlueTransparent;
    padding: 2.25rem;
    flex-direction: column;
  }

  .logo {
    margin-top: 2.875rem;
  }

  .content p {
    font-family: Hexagon, Helvetica, sans-serif;
    margin: 0;
    padding: 0;
    text-align: center;
    color: $brandWhite;
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.687rem;
  }

  .horizontal-line {
    height: 0.0625rem;
    width: 100%;
    background: $brandWhiteMiddleTransparent;
    margin: 1.563rem 0;
  }
}
