@import 'app/assets/scss/_variables.scss';
@import 'app/assets/scss/_mixins.scss';

// The journey itself
// ---------------------------------------------------------- //

.journey {
  $block: &;

  width: 100%;
  height: 100vh;
  height: -webkit-fill-available;
  background: $brandDarkBlue;
  overflow: hidden;

  //@at-root {
  &__preload {
    display: none;
  }

  &__background,
  &__stage {
    @include fullScreenFlexContainer();

    transition: opacity 1s;

    &.fadeout {
      opacity: 0;
    }
  }

  .scene {
  }

  &__background {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    $back: &;

    &.scene {
      #{$back}-image,
      #{$back}-video,
      #{$back}-effects {
        display: block;
        $height: 100vh;
        min-height: 100vh;

        flex: auto 0 0;
        width: 100vw;
        height: $height;
      }
    }

    &-image,
    &-video,
    &-effects {
      //@include fullScreen169(true);
      display: block;
      $height: 100vh;
      min-height: 100vh;

      flex: auto 0 0;
      width: 100vw;
      height: $height;
    }
  }

  &__sidebar {
    //@include fullScreenFlexContainer();
    top: 7.5rem;
    z-index: 40;
    left: 0;
  }
  //}
}

.scene {
  touch-action: none;
  background-image: linear-gradient(to top, #00667d, #167890, #268aa2, #349db6, #42b0c9);
}

.container-progressbar {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.scene-badge-wrapper {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  height: 100%;
  position: fixed;
  bottom: 0;
  right: 0;
  transform: translateX(100%);
  transition: transform 0.2s ease-in;
  z-index: 13;

  &.active {
    transform: translateX(calc(100% - 2.5rem));
    @include extraLd {
      transform: translateX(calc(100% - 5rem));
    }
  }

  &.visible {
    transform: translateX(0);
  }
}

.scene-badge-container {
  height: 100vh;
  overflow-y: hidden;
  opacity: 0;
  transition: opacity 0.4s ease-out;
  position: relative;
}

.scene-badge-container.visible {
  opacity: 1;
}

.scene-badge-button-container {
  height: 5.625rem;
  width: 2.5rem;
  margin-top: 14.175rem;

  @include extraLd {
    height: 11.125rem;
    width: 5rem;
    margin-top: 52rem;
  }
  .scene-badge-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  .close-scene {
    background: $brandMidBlue;
  }

  .open-scene {
    background: $brandWhite;
  }

  .arrow {
    display: inline-block;
    padding: 0.1875rem;
    align-self: center;
    border: solid;

    @include extraLd {
      padding: 0.375rem;
    }
    &.right {
      border-color: $brandWhite;
      border-width: 0 0.1875rem 0.1875rem 0;
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
    }

    &.left {
      border-color: $brandMidBlue;
      border-width: 0 0.1875rem 0.1875rem 0;
      transform: rotate(135deg);
      -webkit-transform: rotate(135deg);
    }
  }

  &:hover {
    cursor: pointer;
  }
}


