@import './src/app/assets/scss/_variables.scss';
@import '/src/app/assets/scss/_mixins.scss';

$uiCardPadding: 1.5rem;
$uiCardMarginTop: -$uiCardPadding;

.c-accept {
  .c-button {
    position: relative;
    border: 0.0625rem solid rgba(255, 255, 255, 0.5);
    box-sizing: border-box;

    &:hover,
    &:active {
      box-shadow: 0 0 0 0.0625rem rgba(255, 255, 255, 0.5);
      background-color: $brandBlueGray;
      transform: none;
    }

    .c-button__text {
      padding: 0.625rem 1.25rem;

      font-style: normal;
      font-weight: bold;
      font-size: 0.8rem;
      line-height: 1.5;
      text-transform: uppercase;

      @include extraLd {
        padding: 2.25rem 7.5rem;
        font-size: 1.6rem;
      }

      &::before {
        background-color: transparent;
      }
    }
  }

  .c-button--size-sm .c-button__text {
    padding: 0;
  }
}

/*
  
  */