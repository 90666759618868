@import 'app/assets/scss/_variables.scss';
@import 'app/assets/scss/_mixins.scss';

.auth-modal {
  position: absolute;
  top: $mainNavHeight;
  z-index: 22;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $brandDarkBlueAlmostNotTransparent;
  height: calc(100% - $mainNavHeight);

  // max-height: 634px;

  @include extraLd {
    top: $mainNavHeight * 2;
  }

  .auth-wrapper {
    width: 19rem;
    height: calc(100% - 2.75rem);
    // padding: 2.75rem 0;
    position: absolute;
    top: 2.75rem;
    bottom: 0;

    @include extraLd {
      // padding: 11rem 3.5rem;
      width: 38rem;
    }
  }

  .form-describe {
    max-width: 400px;

    @include extraLd {
      max-width: none;
    }

    .text-describe {
      @include extraLd {
        flex: 1 1 0;
      }
    }

    .icon-round {
      display: none;
    }
  }

  .form-body.two-columns {
    max-width: 375px;

    @include extraLd {
      max-width: 39.75rem;
    }
  }

  .bottom-button .c-button {
    @include extraLd {
      max-width: 39.75rem;
    }
  }
}