@import 'app/assets/scss/variables.scss';
@import 'app/assets/scss/mixins.scss';

.loading-screen {
  z-index: 10000;
  display: flex;
  position: fixed;
  background-color: $brandDarkBlue;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  font-family: Hexagon, Helvetica, sans-serif;

  $logoMargin: 0.75rem;
  $inDuration: 0.75s;

  .left-part {
    position: relative;
    width: 50%;

    @include sm {
      //max-width: 29rem;
    }

    @include md {
      // max-width: 37.5rem;
    }

    .image-wrapper {
      position: absolute;
    }

    .splash-image {
      object-fit: contain;
    }

    .splashVideo,
    .splash-image {
      z-index: 10;
      width: 132%;

      position: absolute;
      right: 2rem;
      bottom: 0;

      @include md {
        width: 160%;
        height: 100%;
      }
      @include sm {
        top: 3%;
        height: 100%;
      }
      @include extraLd {
        width: 160%;
        max-height: 105%;
      }
    }
  }
  .right-part {
    padding: 3.12rem 6.4375rem 5.25rem 2.215rem;
    width: 50%;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include sm {
      padding: 3.25rem 5rem 3.25rem 2.215rem;
    }


    @include extraLd {
      padding: 6.25rem 18.375rem 15rem 2.215rem;
    }

    .brand {
      display: flex;
      align-items: center;
      //margin-bottom: 8rem;

      @include md {
        //margin-bottom: 3.75rem;
      }
      @include sm {
        //margin-bottom: 3.75rem;
      }
      @include extraLd {
        //margin-bottom: 20rem;
      }

      &__logo-container {
        width: 8.375rem;
        margin-right: $logoMargin;

        @include extraLd {
          margin-right: $logoMargin * 2;
          width: 8.375 * 2rem;
        }
      }

      &__logo {
        display: block;
        margin-top: 0.125rem;
      }

      &__title {
        @include littleAnnoyingDividerLine();

        padding-left: $logoMargin;
        font-size: 1.125rem;
        line-height: 1;
        font-weight: 400;
        text-transform: uppercase;
        overflow: hidden;
        margin: 0;
        padding-top: 0.125rem;

        @include extraLd {
          font-size: 2.2rem;
          padding-left: $logoMargin * 2;
        }

        &::before {
          content: '';
          position: absolute;
          top: 0.125rem;
          bottom: 0.155rem;
          left: 0;
          border-left: 0.0625rem solid $brandWhite;

          @include extraLd {
            border-left: 0.125rem solid $brandWhite;
          }
        }
      }
    }

    .navigation-tips {
      font-size: 1.125rem;
      line-height: 1.5;

      @include md {
        font-size: 0.875rem;
      }
      @include sm {
        font-size: 0.875rem;
      }
      @include extraLd {
        font-size: 2.25rem;
      }

      &_header {
        text-transform: uppercase;
        margin-bottom: 3.75rem;

        @include md {
          margin-bottom: 2.5rem;
        }
        @include sm {
          margin-bottom: 2.5rem;
        }
        @include extraLd {
          margin-bottom: 7.5rem;
        }
      }
      &_wrapper {
        display: flex;
        align-items: center;
        //margin-bottom: 13rem;

        svg {
          width: 100%;
          max-width: 28.75rem;

          @include sm {
            //max-width: 23.4375rem;
          }
          @include md {
            max-width: 23.4375rem;
          }
          @include extraLd {
            max-width: 58.75rem;
          }
        }
        @include md {
          //margin-bottom: 12rem;
        }
        @include sm {
          //margin-bottom: 4rem;
        }
        @include extraLd {
          //margin-bottom: 36rem;
        }
      }
      &_item {
        margin-right: 4rem;
        text-align: center;
        svg {
          width: 5.625rem;
          height: 5.525rem;

          @include extraLd {
            width: 8rem;
            height: 8rem;
          }
        }

        @include extraLd {
          margin-right: 12.5rem;
        }
      }
    }
    .loading {
      font-size: 1.125rem;
      line-height: 1.5;
      @include md {
        font-size: 0.875rem;
      }
      @include sm {
        font-size: 0.875rem;
      }
      @include extraLd {
        font-size: 2.25rem;
      }

      .MuiBox-root {
        width: 100%;
      }
    }
    .text-loading {
      margin-bottom: 1rem;

      @include extraLd {
        margin-bottom: 2rem;
      }
    }
    .text {
      font-size: 2.5rem;
      font-weight: 700;
      line-height: 1.5;
      margin-bottom: 1.875rem;

      @include md {
        font-size: 1.875rem;
        margin-bottom: 1.25rem;
      }

      @include sm {
        font-size: 1.875rem;
         margin-bottom: 1.25rem;
      }

      @include extraLd {
        font-size: 5rem;
        margin-bottom: 3.75rem;
      }
    }
    .best-experienced {
      line-height: 1.5;
      font-size: 1.125rem;
      color: rgba(165, 216, 103, 1);

      @include md {
        font-size: 0.875rem;
      }

      @include sm {
        font-size: 0.875rem;
      }

      @include extraLd {
        font-size: 2.25rem;
      }
    }
  }
}
.MuiTypography-root {
  font-family: Hexagon, Helvetica, sans-serif !important;
  font-size: 1.125rem !important;
  color: white !important;
  line-height: 1.5 !important;
  font-weight: 900 !important;
  margin-bottom: 0.5rem !important;

  @include md {
    font-size: 0.875rem !important;
  }
  @include sm {
    font-size: 0.875rem !important;
  }
  @include extraLd {
    font-size: 2.25rem !important;
    margin-bottom: 1rem !important;
  }
}
.MuiLinearProgress-root {
  width: 100%;
  height: 0.5625rem !important;
  background-color: rgba(255, 255, 255, 0.1) !important;
  margin-bottom: 2rem;

  @include md {
    margin-bottom: 1.5rem;
    width: 80%;
  }
  @include sm {
    margin-bottom: 1.5rem;
    width: 80%;
  }
  @include extraLd {
    margin-bottom: 4rem !important;
    width: 51.875rem;
    height: 1.125rem !important;
  }
}
.MuiLinearProgress-bar {
  background: linear-gradient(to right, #0197ba, #a5d867);
}
