@import 'app/assets/scss/_variables.scss';
@import 'app/assets/scss/_mixins.scss';

.journey-manager-view-page {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: calc(100vh - ($mainNavHeight + $brandStrokeWidth));
  height: calc(100svh - ($mainNavHeight + $brandStrokeWidth));
  overflow: auto;
  padding: 1.313rem 2.938rem;
  color: #fff;
  font-family: Hexagon, Helvetica, sans-serif;

  @include extraLd {
    height: calc(100vh - ($mainNavHeight * 2 + $brandStrokeWidth));
    height: calc(100svh - ($mainNavHeight * 2 + $brandStrokeWidth));
  }
}

.journey-manager-view-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  vertical-align: middle;
  margin-bottom: 1.25rem;

  &__navigation {
    text-transform: uppercase;
    display: flex;
    align-items: center;
    flex-direction: row;
    font-style: normal;
    font-weight: 400;
    font-size: 0.688rem;
    line-height: 0.688rem;
    cursor: pointer;
    color: #fff;
    background: none;
    border: none;

    @include extraLd {
      font-size: 1.375rem;
    }

    svg {
      margin: 0.375rem 0.313rem 0.438rem 0.5rem;
      path {
        fill: #fff;
      }

      @include extraLd {
        height: 1.25rem;
        width: 1.25rem;
        margin-right: 1rem;
      }
    }
  }

  &__title {
    font-style: normal;
    font-weight: 700;
    font-size: 1.375rem;
    line-height: 150%;
    text-transform: uppercase;

    @include extraLd {
      font-size: 2.75rem;
    }
  }

  &__actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2.5rem;
  }

  &__action-duplicate, &__action-edit {
    &.c-button {
      position: relative;
      // background-color: $brandBlueGreen;
      box-sizing: border-box;
      border: none;

      
  
      .c-button__text {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0.3125rem;

        padding: 0.625rem 1.25rem;
        color: #fff;
        font-style: normal;
        font-weight: 700;
        font-size: 0.75rem;
        line-height: 150%;
        text-transform: uppercase;

        svg path {
          stroke: none;
          stroke-width: initial;
          fill: #fff;
          vector-effect: none;
        }
  
        @include extraLd {
          padding: 1.25rem 2.5rem;
          font-size: 1.5rem;
          gap: 0.625rem;

          svg {
            height: 2.5rem;
            width: 2.5rem;
          }
        }
  
      }
  
    }
  }
  
  .c-button--size-sm .c-button__text {
    padding: 0;
  }
}

.journey-manager-view-content {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  overflow: auto;

  // for future
  &__filter {
    margin-right: 1.875rem;
    background: rgba(5, 30, 47, 0.82);
    width: 18.125rem;
  }

  &__list {
    flex-grow: 1;
    overflow: auto;
    display: flex;
    flex-direction: column;
  }
}


.journey-view {
  width: 100%;

  .view-body {
    display: grid;
    grid-template-columns: 4fr 4fr 4fr;
    gap: 1.875rem;
    height: 100%;

    @include ld {
      grid-template-columns: 3fr 3fr 4fr 1fr;
      gap: 2.5rem;
    }

    @include extraLd {
      grid-template-columns: 3fr 3fr 4fr 1fr;
      gap: 5rem;
    }
  }

  .main-info {
    display: flex;
    flex-direction: column;
    gap: 3.75rem;

    @include extraLd {
      gap: 7.5rem;
    }

    &_data {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      gap: 1.875rem;

      @include extraLd {
        gap: 3.75rem;
      }
    }

    
    

  .label {
    color: #89C0D1;
    font-style: normal;
    font-weight: 400;
    font-size: 0.625rem;
    line-height: 150%;

    @include extraLd {
      font-size: 1.25rem;
    }

  }


  .field {
    display: flex;
    flex-direction: column;
    gap: 0.3125rem;

    @include extraLd {
      gap: 0.625rem;
    }
  }

  .text-wrapper {
    width: 100%;
    position: relative;
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 150%;

    @include extraLd {
      font-size: 1.75rem;
    }
  }

  .private-info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    color: #89C0D1;
    line-height: 150%;
    font-style: normal;
    font-weight: 400;
    padding-bottom: 0.75rem;
    border-bottom: solid 1px #D9D9D9;

    &_date {
      font-size: 0.625rem;

      @include extraLd {
        font-size: 1.25rem;
      }
    }

    &_text {
      font-size: 0.75rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 0.5313rem;

      @include extraLd {
        font-size: 1.5rem;
        gap: 1.0625rem;

        svg {
          height: 1.875rem;
          width: auto;
        }
      }
    }
  }

  &_action-secondary {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  &_action-share {
    border: 0.0625rem solid #ffffff;

    .c-button__text {
      padding: 0.625rem 1.25rem;
      font-style: normal;
      font-weight: 700;
      font-size: 0.75rem;
      line-height: 150%;
      text-transform: uppercase;
      color: #FFFFFF;

      @include extraLd {
        padding: 1.25rem 2.5rem;
        font-size: 1.5rem;
      }
    }
  }
  &_action {
    display: flex;
    flex-direction: column;
  }
  &_action-start-journey {
    margin: 0.75rem;
    background: #89C0D1;
    // height: 3.125rem;

    @include extraLd {
      margin: 1.25rem;
    }

    .c-button__text {
      font-size: 1rem;
      line-height: 1.1875rem;

      text-align: center;
      text-transform: uppercase;

      color: #002C40;
      padding: 1.125rem 1.25rem 0.8125rem 1.25rem;

      @include extraLd {
        padding: 2.25rem 2.5rem 2rem 2.5rem;
        font-size: 2rem;
        line-height: 2rem;
      }
    }
  }
}

  .locations {
    min-height: 27.375rem;

    @include md {
      min-height: 34.5rem;
    }
    @include ld {
      min-height: 45.625rem;
    }

    @include extraLd {
      min-height: 91.25rem;
    }
  }
}

.share-journey-modal {

  .MuiBackdrop-root {
    background: rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(0.125rem);
  }

  .MuiPaper-root {
    min-width: 33.75rem;
    max-width: 90%;

    @include extraLd {
      min-width: 67.5rem;
      max-width: 90%;
    }
  }

  &_content-text {
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 150%;
    /* or 21px */
    color: #FFFFFF;
  
    display: flex;
    flex-direction: column;
    padding-bottom: 3.75rem;
    gap: 2rem;

    p {
      margin: 0 !important;
    }
  
    @include extraLd {
      font-size: 1.75rem;
      padding-bottom: 7.5rem;
    }
  }
}