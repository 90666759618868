@import 'app/assets/scss/_variables.scss';
@import 'app/assets/scss/_mixins.scss';

.sidebar-left-content {
  width: 41.14rem;
  height: 100%;
  backdrop-filter: blur(0.375rem);
  background-color: rgba(5, 30, 47, 0.82);
  padding: 15.625rem 1.25rem 0 7.43rem;
  color: #ffffff;
  // border-bottom: 0.125rem solid #ffffff;
  z-index: 2;

  @include md {
    width: 40.83rem;
    padding: 6.24rem 1.25rem 0 2.81rem
  }

  @include sm {
    width: 30.625rem;
    padding: 6.35rem 1.25rem 0 2.81rem;
  }

  @include extraLd {
    width: 82.88rem;
    padding: 31.5rem 2.5rem 0 15rem;
  }

  &__description {
    font-style: normal;
    font-weight: 700;
    font-size: 1.875rem;
    line-height: 150%;
    color: #FFFFFF;
    margin-top: 2.5rem;

    @include ld {
      font-size: 2.5rem;
    }

    @include extraLd {
      font-size: 5rem;
      margin-top: 5rem;
    }
  }

  &__subdescription {
    font-style: normal;
    font-size: 1.275rem;
    color: #FFFFFF;
    margin-top: 2.5rem;
    line-height: 150%;

    @include ld {
      font-size: 1.9rem;
    }

    @include extraLd {
      font-size: 4.4rem;
      margin-top: 5rem;
    }
  }

  &__button {
    position: relative;

    min-width: 20rem;
    max-width: 30rem;
    width: 50%;

    margin: 3.125rem 0 0 0.85rem;

    @include extraLd {
      min-width: 40rem;
      max-width: 60rem;

      margin-top: 9.375rem !important
    }

    .c-button {
      background-color: #89c0d1;
      color: #002c40;
      font-weight: 700;


    }

    .c-button__text {
      padding: 1rem 6.2rem;

      @include extraLd {
        font-size: 2rem;
        padding: 2rem 13.125rem;
      }

    }
  }
}

.sidebar {
  color: $brandMidBlue;
  padding-top: $mainNavHeight;
  overflow: hidden;
  z-index: 1;

  @include extraLd {
    padding-top: $mainNavHeight * 2;
  }

  $block: &;

  &__info {
    padding: 0 1.75rem;

    @include extraLd {
      padding: 0 3.5rem;
    }

    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 1.25rem 0;

      @include extraLd {
        margin: 2.5rem 0;
      }

      h2 {
        font-size: 1.75rem;
        font-weight: 900;
        line-height: 1;
        text-transform: uppercase;
        margin: 0;

        @include extraLd {
          font-size: 3.5rem;
        }
      }

      #{$block}__navigate {
        display: flex;
        gap: 1.125rem;

        @include extraLd {
          gap: 2.25rem;
        }

        svg {
          cursor: pointer;
          pointer-events: all !important;

          @include extraLd {
            width: 4rem;
            height: 2rem;
          }
        }
      }
    }

    .description {
      font-size: 0.9375rem;
      line-height: 1.5;
      border-bottom: 0.0625rem solid #c4c4c4;
      padding-bottom: 1.25rem;

      @include extraLd {
        font-size: 1.875rem;
        border-bottom: 0.125rem solid #c4c4c4;
        padding-bottom: 2.5rem;
      }
    }

    .bottom-button {
      margin-top: 1.25rem;
      padding: 1.25rem 0.825rem;

      @include extraLd {
        margin-top: 2.5rem;
      }

      .c-button {
        background-color: $brandMidBlue;
        width: 100%;
      }

      .c-button__corner {
        border-color: $brandMidBlue;
      }

      .c-button__text {
        font-size: 0.75rem;
        line-height: 1.5;
        font-weight: 700;
        color: white;
        padding: 1rem;

        @include extraLd {
          font-size: 1.5rem;
          padding: 2rem;
        }
      }
    }
  }
}