@import 'app/assets/scss/_variables.scss';
@import 'app/assets/scss/_mixins.scss';

// The journey itself
// ---------------------------------------------------------- //

.journey {
  $block: &;
  width: 100%;
  height: 100vh;
  background: $brandDarkBlue;
  overflow: hidden;
  position: relative;

  min-height: -webkit-fill-available;

  @at-root {
    &__preload {
      display: none;
    }

    &__background,
    &__stage {
      @include fullScreenFlexContainer();

      transition: opacity 1s;

      &.fadeout {
        opacity: 0;
      }
    }

    &__background {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;

      &-image,
      &-video {
        @include fullScreen169(true);
      }
    }

    &__sidebar {
      top: 7.5rem;
      z-index: 40;
      left: 0;
    }
  }
}

// NEW
.sidebar-container {
  height: 100vh;
  overflow-y: hidden;
  position: relative;
}

.sidebar-left-wrapper {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  height: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  opacity: 0;
  /* transform: translateX(calc(-30.625rem));
  @include extraLd {
    transform: translateX(calc(-30.625rem));
  } */

  // transition: transform 0.2s ease-in, opacity 0.4s ease-out;
  z-index: 13;

  /* &.active {
    transform: translateX(calc(0));
    @include extraLd {
      transform: translateX(calc(0));
    }
  } */

  /* &.visible {
    opacity: 1;
  } */
}