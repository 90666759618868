@import 'app/assets/scss/_variables.scss';
@import 'app/assets/scss/_mixins.scss';

.c-pointer__text {
  font-style: normal;
  font-weight: 400;

  font-size: 0.958rem; // 15.33px;
  line-height: 0.958rem; // 15.33px;

  /* identical to box height */
  text-transform: uppercase;

  color: rgba(255, 255, 255, 1);

  z-index: 1;

  // margin-top: 3px;

  @include ld{
    font-size: 1.5rem; // 24px;
    line-height: 1.5rem; // 24px;

    // margin-top:9px;
  }

  @include extraLd {
    font-size: 3rem; // 24px;
    line-height: 3rem; // 24px;

    // margin-top: 21px;
  }
}

.c-pointer__label_margin {
  top: 4px;
}

.pointer-label {
  display: flex;

  left: 3px;
  top: 0;

  // padding: 0.75rem; // 10px;
  padding: 0.7rem 0.75rem 0.5rem 0.75rem;

  background: rgba(5, 30, 47, 0);
  opacity: 0.9;
  backdrop-filter: blur(0px);

  @include ld {
    padding: 1.25rem 1.25rem 1rem 1.25rem; // 20px;
  }

  @include extraLd {
    padding: 2.5rem 2.5rem 2rem 2.5rem; // 20px;
  }

  .container-paths__arrow_right_selected {
    border-color: rgba(255, 255, 255, 1);
    opacity: 0.5;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

    padding: 0.15rem;

    @include ld {
      padding: 0.25rem;
    }

    @include extraLd {
      padding: 0.5rem;
    }
  }

  &__title {
    position: relative;
    margin-left: 0.75rem; // 12px;
    padding-left: 0.75rem; // 12px;
    white-space: nowrap;
  }

  &__arrow_left {
    border: solid rgba(5, 30, 47, 0.8);
    border-width: 0.125rem 0.125rem 0 0;
    display: inline-block;
    padding: 0.25rem;
    transform: rotate(225deg);
    margin-left: 0.3rem;
    background: rgba(5, 30, 47, 0.8);
    opacity: 0.9;
    backdrop-filter: blur(4px);
    margin-left: -1.6rem;

    @include extraLd {
      border-width: 0.25rem 0.25rem 0 0;
      padding: 0.6rem;
      margin-left: 0.75rem;
      margin-right: 0.75rem;
    }
  }
}

.arrow-left {
  width: 0;
  height: 0;

  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent; 
  border-right:5px solid rgba(5, 30, 47, 0.7);

  backdrop-filter: blur(4px);

  @include ld {
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent; 
    border-right:10px solid rgba(5, 30, 47, 0.7);
  }

  @include extraLd {
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent; 
    border-right:20px solid rgba(5, 30, 47, 0.7);
  }
}

.pointer-label__title:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0.2em;
  left: 0;
  border-left: 0.0625rem solid;
}

.pointer-label__arrow-right_margin {
  margin-top: -0.15rem;

  @include extraLd {
    margin-top: -0.25rem;
  }
}