@import 'app/assets/scss/_variables.scss';
@import 'app/assets/scss/_mixins.scss';

.select-locations-modal {

  .MuiDialogContent-root {
    &::-webkit-scrollbar {
      -webkit-appearance: none;
    }

    &::-webkit-scrollbar:vertical {
      width: 0.5rem;
    }

    &::-webkit-scrollbar:horizontal {
      height: 0.5rem;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 0.375rem;
      border: 0.125rem solid #c1c1c1;
      background-color: #c1c1c1;
    }
  }

  .c-dialog__content {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 2rem;

    @include ld {
      justify-content: space-between;
    }

    @include extraLd {
      gap: 6.25rem;

    }

    &::-webkit-scrollbar {
      -webkit-appearance: none;
    }

    &::-webkit-scrollbar:vertical {
      width: 0.5rem;
    }

    &::-webkit-scrollbar:horizontal {
      height: 0.5rem;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 0.375rem;
      border: 0.125rem solid #c1c1c1;
      background-color: #c1c1c1;
    }
  }

  &_action-cancel {

    .c-button__text {
      font-weight: 400 !important;
      font-size: 0.875rem !important;
      line-height: 150% !important;
      padding: 0.5625rem 1.25rem !important;


      @include extraLd {
        padding: 1.1875rem 2.5rem !important;
        font-size: 1.75rem !important;
      }
    }

  }

  &_action-apply {

    .c-button__text {
      font-weight: 700 !important;
      font-size: 0.75rem !important;
      line-height: 150% !important;
      padding: 0.625rem 1.25rem !important;

      @include extraLd {
        padding: 1.25rem 2.5rem !important;
        font-size: 1.5rem !important;
      }
    }

  }
}

.journey-form-group {
  display: flex;
  flex-direction: column;
  color: #FFFFFF;
  word-break: keep-all;
  white-space: nowrap;

  &__title {
    display: flex;
    flex-direction: column;
    margin-bottom: 1.313rem;

    @include extraLd {
      margin-bottom: 2.625rem;
    }
  }

  &__sub-title {
    font-style: normal;
    font-weight: 400;
    font-size: 0.688rem;
    line-height: 0.688rem;
    text-transform: uppercase;

    @include extraLd {
      font-size: 1.376rem;
      line-height: 1.376rem;
    }
  }

  &__title-text {
    font-style: normal;
    font-weight: 900;
    font-size: 1rem;
    line-height: 1rem;
    text-transform: uppercase;

    @include extraLd {
      font-size: 2rem;
      line-height: 2rem;
    }
  }

  &__checkboxes-list {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
  }

  &__checkbox {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;

    svg {
      @include extraLd {
        height: 2.5rem;
        width: 2.5rem;
      }
    }
  }

  &__checkbox-label {
    display: flex;
    flex-direction: column;
  }

  &__checkbox-label-text {
    font-style: normal;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 150%;

    @include extraLd {
      font-size: 1.5rem;
    }
  }

  // selected
  .Mui-checked+&__checkbox-label &__checkbox-label-text {
    color: #89C0D1;
  }

  &__checkbox-label-sub-text {
    font-style: normal;
    font-weight: 400;
    font-size: 0.625rem;
    line-height: 150%;
    opacity: 0.5;

    @include extraLd {
      font-size: 1.25rem;
    }
  }


}