@import 'app/assets/scss/_variables.scss';
@import 'app/assets/scss/_mixins.scss';

.contact-us-progress-wrapper {
  @include fullScreenAbsoluteContainer();
  z-index: 9999999;

  .block-screen {
    @include fullScreenFlexContainer();
    justify-content: normal;
    align-items: flex-start;

    background-color: $brandDarkBlueTransparent;
    padding: 3.875rem;

    &__content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      margin-top: -3.875rem;
    }
  }

  .logo {
    position: absolute;
  }

  .content p {
    font-family: Hexagon, Helvetica, sans-serif;
    margin: 0;
    padding: 0;
    text-align: center;
    color: $brandWhite;
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.687rem;

    @include extraLd {
      font-size: 2.95rem;
      line-height: 3.675rem;
    }
  }

  .horizontal-line {
    height: 0.0625rem;
    width: 100%;
    background: $brandWhiteMiddleTransparent;
    margin: 1.563rem 0;
  }
}

.spinner {
  width: 5.5625rem;
  height: 5.5625rem;
  position: relative;
  background: rgba(255, 255, 255, 0.13);
  animation-duration: 2.5s;
  animation-name: animSpin;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  border-radius: 50%;

  @include extraLd {
    width: 11rem;
    height: 11rem;
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    border: 0.5rem solid transparent;
    border-radius: 50%;
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  }

  &:before {
    width: 75%;
    height: 75%;
    background: rgba(255, 255, 255, 0.13);
    left: 12.5%;
    top: 12.5%;
    border-left: 0.5rem solid rgba(255, 255, 255, 0.34);
    border-bottom: 0.5rem solid rgba(255, 255, 255, 0.34);
  }

  &:after {
    width: 40%;
    height: 40%;
    left: 30%;
    top: 30%;
    border-right: 0.5rem solid rgba(255, 255, 255, 1);
    border-left: 0.5rem solid rgba(255, 255, 255, 1);
    border-bottom: 0.5rem solid rgba(255, 255, 255, 1);
  }

  @keyframes animSpin {
    50% {
      transform: rotateZ(180deg) scale(0.94);
    }
    100% {
      transform: rotateZ(360deg) scale(1);
    }
  }
}

.margin-top-text {
  margin-top: 5.5rem;

  @include extraLd {
    margin-top: 11rem;
  }
}