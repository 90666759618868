@import 'app/assets/scss/_variables.scss';
@import 'app/assets/scss/_mixins.scss';

$HashtagModalPadding: 2.5rem;

@at-root {
  // Zoom Action
  .modalZoom-enter {
    &.modalZoom-enter-active {
      .modal-window {
        opacity: 1;
        transform: scale(1);
        transition: opacity 200ms ease-in, transform 500ms cubic-bezier(0.68, -0.55, 0.27, 1.55);
      }
    }
    .modal-window {
      opacity: 0;
      transform: scale(0.7);
    }
  }
  .modalZoom-exit {
    &.modalZoom-exit-active {
      .modal-window {
        opacity: 0;
        transform: scale(0.1);
        transition: opacity 500ms ease-out, transform 500ms cubic-bezier(0.68, -0.55, 0.27, 1.55);
      }
    }
    .modal-window {
      opacity: 1;
      transform: scale(1);
    }
  }
  .modal-overlay {
    background-color: $brandWhiteMiddleLessTransparent;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 28;
    backdrop-filter: blur(0.125rem);
  }

  .c-interests-modal {
    opacity: 0;
    transition: all 100ms cubic-bezier(0.4, 0, 0.2, 1);
    transition-delay: 200ms;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $brandWhite;
    position: absolute;
    inset: 0;

    & .modal-content {
      transform: translateY(6.25rem);
      transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1);
      opacity: 0;
    }

    &.active {
      transition-duration: 250ms;
      transition-delay: 0ms;
      opacity: 1;

      & .modal-content {
        transform: translateY(0);
        opacity: 1;
        transition-delay: 150ms;
        transition-duration: 350ms;
      }
    }
  }

  .portal-content {
    position: relative;
    width: 33.75rem;
    padding: $HashtagModalPadding;
    background-color: rgba(5, 30, 47, 0.95);
    box-shadow: 0 0.1875rem 0.375rem rgba(0, 0, 0, 0.16), 0 0.1875rem 0.375rem rgba(0, 0, 0, 0.23);
    z-index: 29;

    @include extraLd {
      padding: 5rem;
      width: 67.5rem;
    }
  }

  .c-interests-modal {
    .buttons-group {
      margin-top: 1.8125rem;
      display: flex;
      gap: 0.75rem;
      justify-content: flex-end;

      @include extraLd {
        gap: 1.5rem;
        margin-top: 5rem;
      }
    }

    .c-button {
      font-weight: 700;
      padding: 0.625rem 1.25rem;
      font-size: 0.75rem;
      border: 0.0625rem solid $brandWhiteTransparent;
      background-color: transparent;

      @include extraLd {
        padding: 1.25rem 2.5rem;
        font-size: 1.5rem;
        line-height: 2.25rem;
        border: 0.125rem solid $brandWhiteTransparent;
      }

      &:hover {
        background-color: rgba(255, 255, 255, 0.1);
        -webkit-transform: none;
        transform: none;
        transition: background-color 0.2s linear;
      }

      &:disabled {
        cursor: not-allowed;
        pointer-events: all !important;
      }
    }
  }

  .form-body.form-scroll {
    max-height: 50vh;
    overflow-y: auto;
    margin-right: calc(-#{$HashtagModalPadding} + 0.625rem);
    padding-right: calc(#{$HashtagModalPadding} - 0.625rem);
    overscroll-behavior-y: contain;

    &::-webkit-scrollbar-track {
      border-radius: 0.5rem;

      @include extraLd {
        border-radius: 1rem;
      }
    }

    &::-webkit-scrollbar {
      width: 0.5rem;
      -webkit-appearance: none;

      @include extraLd {
        width: 1rem;
      }
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 0.5rem;
      background-color: rgba(193, 193, 193, 0.2);

      @include extraLd {
        border-radius: 1rem;
      }
    }

    &::-webkit-scrollbar-thumb:hover {
      background: rgba(193, 193, 193, 0.4);
    }

    &::-webkit-scrollbar-thumb:window-inactive {
      background: rgba(193, 193, 193, 0.5);
    }
    .hashtags-close {
      position: absolute;
      top: 1.375rem;
      right: 1.375rem;
      z-index: 10;

      .c-button {
        padding: 0;
        border: none;
      }

      svg {
        width: 1.25rem;
      }
    }
  }
}
