@import './src/app/assets/scss/_variables.scss';
@import '/src/app/assets/scss/_mixins.scss';

$uiCardPadding: 1.5rem;
$uiCardMarginTop: -$uiCardPadding;

.c-lead-modal {
  $block: &;

  $padding: $uiCardPadding * 1.5;

  display: flex;
  pointer-events: none;
  position: absolute;
  z-index: 28;
  inset: 0;
  align-items: center;
  justify-content: center;
  max-height: 100vh;

  &.is-open {
    pointer-events: auto;

    &::before {
      opacity: 1;
    }
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    z-index: -1;
    inset: 0;
    opacity: 0;
    transition: opacity 0.5s;
  }

  @at-root {
    &__container {
      position: relative;
      width: 33.75rem;
      // height: 26.5rem;
      max-width: $screenMedium;
      // max-height: 100%;
      padding: $padding;
      opacity: 0;
      background-color: $brandDarkBlueAlmostNotTransparent;

      transform: scale(0.95) translate(0, 2rem);
      transition: opacity 0.5s, transform 0.5s;

      @include extraLd {
        width: 67.5rem;
        // height: 55rem;
        padding: 5rem 5rem 3.75rem 5rem;
      }

      #{$block}.is-open & {
        opacity: 1;
        transform: scale(1) translate(0, 0);
      }

      #{$block}__container-close {
        position: absolute;
        top: 1.375rem;
        right: 1.375rem;
        z-index: 10;

        @include extraLd {
          top: 2.75rem;
          right: 2.75rem;
        }
      }

      h2 {
        text-transform: uppercase;
        color: $brandWhite;
        margin: 0 0 1.5rem 0;
        font-size: 1.375rem;

        @include extraLd {
          margin: 0 0 3rem 0;
          font-size: 2.75rem;
        }
      }

      .c-button.close-button {
        @include reset-button();
        .c-button__text {
          padding: 0.5rem;
          color: $brandWhite;
        }
      }

      .c-buttons-list {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1 1 0;

        .c-button {
          position: relative;
          background-color: initial;
          border: 0.0625rem solid #fff;
          box-sizing: border-box;
          padding: 0 1.25rem;
          font-size: 0.75rem;
          font-weight: 700;
          display: flex;
          align-items: center;
          height: 2.375rem;

          @include extraLd {
            font-size: 1.5rem;
            border: 0.125rem solid #fff;
            padding: 1.25rem 2.5rem;
            line-height: 1.5;
            height: auto;
          }

          &_expand {
            padding: 0 4.5rem;

            @include extraLd {
              padding: 1rem 8rem;
            }
          }

          svg {
            margin-right: 0.625rem;

            path {
              fill: #ffffff;
              stroke: none;
            }

            @include extraLd {
              width: 2rem;
              height: 2rem;
              margin-right: 1.25rem;
            }
          }

          &:last-child {
            margin-left: 0.75rem;

            @include extraLd {
              margin-left: 1.5rem;
            }
          }
        }
      }
    }

    &__content {
      display: flex;
      // justify-content: center;
      flex-direction: column;
      min-height: 6.25rem;
      height: 100%;
      color: $brandWhite;
      line-height: 1.3125rem;
      font-size: 0.875rem;

      @include extraLd {
        line-height: 2.675rem; // line-height: 1.5rem;
        font-size: 1.75rem;
      }

      .blue-text {
        color: #89c0d1;
      }

      .note-text {
        line-height: 1.125rem;
        font-size: 0.75rem;

        @include extraLd {
          line-height: 2.375rem;
          font-size: 1.5rem;
        }
      }

      .title-text {
        line-height: 1.688rem;
        font-size: 1.125rem;

        @include extraLd {
          line-height: 3.875rem;
          font-size: 2.25rem;
        }
      }

      .padding-top {
        padding-top: 1.5rem;
        @include extraLd {
          padding-top: 3rem;
        }
      }

      .text__line_margin-top {
        margin-top: 0.15rem;

        @include extraLd {
          margin-top: 1.225rem;
        }
      }

      .text-support {
        // margin-top: 0.4rem;

        text-decoration-line: underline;

        @include extraLd {
          // margin-top: 2.475rem;
        }

        &:hover {
          cursor: pointer;
        }
      }

      .row {
        display: flex;
      }

      .margin-left {
        margin-left: 1rem;
        @include extraLd {
          margin-left: 2rem;
        }
      }

      .margin-top {
        margin-top: 1.5rem;
        @include extraLd {
          margin-top: 3rem;
        }
      }

      .margin-top-2 {
        margin-top: 2rem;
        @include extraLd {
          margin-top: 4rem;
        }
      }

      .margin-top-4 {
        margin-top: 4rem;
        @include extraLd {
          margin-top: 8rem;
        }
      }

      .margin-top-7 {
        margin-top: 6.938rem;
        @include extraLd {
          margin-top: 14rem;
        }
      }

      .border {
        // 0.063 border: 0.5rem solid transparent;
        background: #D9D9D9;
        opacity: 0.5;
        height: 1px;
      }

      .button {
        position: relative;

        .c-button {
          background-color: #89c0d1;
          color: #002c40;
          font-weight: 700;
    
          &__corner {
            display: none;
          }
        }
    
        .c-button__text {
          padding: 1rem 2rem;
    
          @include extraLd {
            font-size: 2rem;
            padding: 1rem 4rem;
          }
        }
      }

      .c-video-modal__close-button-svg {
        width: 1rem;
      }
    }

    .spinner {
      width: 5.5625rem;
      height: 5.5625rem;
      position: relative;
      background: rgba(255, 255, 255, 0.13);
      animation-duration: 2.5s;
      animation-name: animSpin;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
      border-radius: 50%;

      @include extraLd {
        width: 11rem;
        height: 11rem;
      }

      &::before,
      &::after {
        content: '';
        position: absolute;
        border: 0.5rem solid transparent;
        border-radius: 50%;
        box-sizing: border-box;
        -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
      }

      &:before {
        width: 75%;
        height: 75%;
        background: rgba(255, 255, 255, 0.13);
        left: 12.5%;
        top: 12.5%;
        border-left: 0.5rem solid rgba(255, 255, 255, 0.34);
        border-bottom: 0.5rem solid rgba(255, 255, 255, 0.34);
      }

      &:after {
        width: 40%;
        height: 40%;
        left: 30%;
        top: 30%;
        border-right: 0.5rem solid rgba(255, 255, 255, 1);
        border-left: 0.5rem solid rgba(255, 255, 255, 1);
        border-bottom: 0.5rem solid rgba(255, 255, 255, 1);
      }

      @keyframes animSpin {
        50% {
          transform: rotateZ(180deg) scale(0.94);
        }
        100% {
          transform: rotateZ(360deg) scale(1);
        }
      }
    }

    .icon-wrap {
      margin-bottom: 2rem;

      @include extraLd {
        margin-bottom: 4rem;

        svg {
          width: 11rem;
          height: 11rem;
        }
      }

      &__error {
        width: 88px;
        height: 88px;
      }
    }

    .icon-wrap-5 {
      @include extraLd {
        svg {
          width: 3rem;
          height: 3rem;
        }
      }
    }
  }
}
