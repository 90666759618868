@import 'app/assets/scss/_variables.scss';
@import 'app/assets/scss/_mixins.scss';

.journey-form {
  width: 100%;

  .form-body {
    display: grid;
    grid-template-columns: 4fr 4fr 4fr;
    gap: 1.875rem;
    height: 100%;

    @include ld {
      grid-template-columns: 3fr 3fr 4fr 1fr;
      gap: 2.5rem;
    }

    @include extraLd {
      grid-template-columns: 3fr 3fr 4fr 1fr;
      gap: 5rem;
    }
  }

  .main-info {
    display: flex;
    flex-direction: column;
    gap: 3.75rem;

    @include extraLd {
      gap: 7.5rem;
    }

    &_data {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      gap: 1.875rem;

      @include extraLd {
        gap: 3.75rem;
      }
    }

    &_action {
      display: flex;
      flex-direction: column;
    }
  }

  .field {
    padding: 0;
  }

  .input-label-wrapper {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }

  .input-label {
    line-height: 150%;
    display: flex !important;
    align-items: flex-end;
    justify-content: space-between;

    &__text--with-error {
      font-style: normal;
      font-weight: 700;
      // font-size: 0.625rem;
      color: #FF6161;
      opacity: 0.8;
    }

    &__info {
      &:hover {
        svg path {
          fill: #FFFEF6;
        }
      }

      @include extraLd {
        svg {
          height: 2.5rem;
          width: 2.5rem;
        }
      }
    }
  }

  .text-input-wrapper {
    width: 100%;
    position: relative;

    &--with-error {
      &::before {
        content: '';
        display: block;
        position: absolute;
        right: 0.313rem;
        top: 0.688rem;
        height: 1.25rem;
        width: 1.25rem;
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM11 15H9V13H11V15ZM11 11H9V5H11V11Z' fill='%23FF6161'/%3e%3c/svg%3e");
      
        @include extraLd {
          right: 0.626rem;
          top: 1.376rem;
          height: 2.5rem;
          width: 2.5rem;
        }
      }
    }
  }

  .text-input {
    width: 100% !important;
    height: 2.75rem;
    font-size: 0.875rem;

    @include extraLd {
      height: 5.5rem;
      font-size: 1.75rem;
    }
  }

  .error-field {
    color: #FF6161;
    opacity: 0.8;
  }

  .locations {
    min-height: 27.375rem;

    @include md {
      min-height: 34.5rem;
    }
    @include ld {
      min-height: 45.625rem;
    }

    @include extraLd {
      min-height: 91.25rem;
    }
  }
}